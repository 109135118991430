import React, { useState, useEffect } from 'react';

import './Schedule.scss';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useFiltersContext, useRequest, useUIContext } from '../../../ContextLib/contextHooks';
import Calendar from "./calendar";
import { Severity } from '../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import Drawer from '@material-ui/core/Drawer';
import BlocksForm from './blocks-form';
import PreviewIcon from '@material-ui/icons/EventNoteTwoTone';
import PreviewSchedule from './Preview-Schedule';

  

const getPeriod = (date) => {
    const d1 = new Date(date.valueOf());
    d1.setMonth(d1.getMonth() - 3);

    const d2 = new Date(date.valueOf());
    d2.setMonth(d2.getMonth() + 3);

    return {
        startDate: new Date(d1.toDateString()),
        endDate: new Date(d2.toDateString())
    };
}

const getDays = (d1, d2) => {
    return (d2.getTime() - d1.getTime())/(1000*60*60*24);
}

const REPLENISH_THRESHOLD = 31; //in days

export default function Schedule() {

    let componentId = "schedule";
    const ui = useUIContext(componentId);
    const Request = useRequest();

    const filtersContext = useFiltersContext(componentId, async (newContext, source) => {
        //On filters changed
        const ready = newContext.coverKey != 0 && newContext.locKey != 0 && newContext.acctKey != 0;
        setReady(ready);
        setAcctKey(newContext.acctKey);
        setcoverKey(newContext.coverKey);

        if (source=="location") {
            filtersContext.ClearProviders();
        }
    });  

    const [ready, setReady] = useState(false);
    const [refresh, setRefresh] = useState(new Date());
    const [acctKey, setAcctKey] = useState(0);

    const [data, setData] = useState([]);
    const [calendarDate, setCalendarDate] = useState(new Date());

    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerMode, setDrawerMode] = useState("");

    const [period, setPeriod] = useState(getPeriod(calendarDate));
    const [eventData, setEventData] = useState();

    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [openDiaglog, setOpen] = React.useState(false);
    const [providerInfo, setProviderInfo] = React.useState({});
    const [locationInfo, setLocationInfo] = React.useState({});
    const [coverKey, setcoverKey] = React.useState();


    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClickClose = () => {
        setOpen(false);
      };  

    const handleCreateAvailability = ()=>{        
        setEventData(null);
        setDrawerMode("create-availability");
        setOpenDrawer(true);
    }

    const handleBlackoutPeriod = ()=> {
        setEventData(null);
        setDrawerMode("create-blackoutperiod");
        setOpenDrawer(true);
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }
    
    const handleBlocksSaved = () => {        
        closeDrawer();
        getData();
    }

    const editBlockGroup = (eventData) => {        
        if (eventData.blkTableType == 2) {
            setDrawerMode("create-blackoutperiod");
        } else {
            setDrawerMode("create-availability");
        }        
        setOpenDrawer(true);                 
        setEventData(eventData);
    }

    const getData = async () => {
        ui.ShowOverlay();
        let res = await Promise.all([
            getAppointmentTypes(),
            getCalendarData()
        ]);

        ui.HideOverlay();
    }

    const getCalendarData = async () => {        
        const p = getPeriod(calendarDate);

        const res = await Request.getCalendarData({
            coverKey: filtersContext.Values.coverKey, 
            locKey: filtersContext.Values.locKey, 
            acctKey: acctKey,
            startDate: p.startDate,
            endDate: p.endDate
        });

        
 
        if (res.data.exception) {
            ui.ShowSnackbar(res.data.exception, Severity.error);
        } else if (res.success) {
            setData(res.data);    
            const providerSelected =  filtersContext.Values.providers.filter(p => p.acctKey == filtersContext.Values.acctKey);  
            if (providerSelected.length > 0)
            {
               var providerInfo = {"Name" : providerSelected[0].firstName + ' ' + providerSelected[0].lastName, "Key" : providerSelected[0].acctKey};
               setProviderInfo(providerInfo); 
            }
            const locationSelected =  filtersContext.Values.locations.filter(p => p.locKey == filtersContext.Values.locKey);  
            if (locationSelected.length > 0)
            {
               var locationInfo = {"Name" : locationSelected[0].orgName , "Key" : locationSelected[0].locKey, "CoverKey" : coverKey };
               setLocationInfo(locationInfo);     
            }  
        } else {
            ui.ShowSnackbar(res.error, Severity.error);
        }
    }

    const getAppointmentTypes = async () => {
        let {coverKey, locations, providers, locKey, acctKey} = filtersContext.Values;
          
            locations  = locations.filter(x => x.coverKey == coverKey); 
            providers = providers.filter(x => x.coverKey == coverKey); 

            let arrLocKeys = [], arrAcctKeys = [];

            if (locKey !== 0) {
                arrLocKeys.push(locKey.toString());
            } else {
                if (locations.length > 0) {
                    locations.map((loc) => {
                        arrLocKeys.push(loc.locKey.toString())
                    });
                }
            }

            if (acctKey !== 0) {
                arrAcctKeys.push(acctKey.toString());
            } else {
                if (providers.length > 0) {
                    providers.map((prov) => {
                        arrAcctKeys.push(prov.acctKey.toString())
                    });
                }
            }

            const params = { "acctKeys": arrAcctKeys, "locKeys": arrLocKeys};
           
            let res = await Request.getAllAppointmentTypes(params);
            if (res.success && res.data?.length > 0) {              
                setAppointmentTypes(res.data);
            } 
    };

    const replenishData = async () => {
        const diffL = getDays(period.startDate, calendarDate);
        const diffR = getDays(calendarDate, period.endDate);

        if (diffL < REPLENISH_THRESHOLD || diffR < REPLENISH_THRESHOLD) {
            ui.ShowOverlay();
            ui.ShowSnackbar("Refreshing calendar events...", Severity.info);

            await getData();
            ui.HideOverlay();
        }
    }    

    const updatePeriod = () => {
        const p = getPeriod(calendarDate);
        setPeriod(p);
        return p;
    }


    useEffect(()=>{
        updatePeriod();
    }, [data]);

    useEffect(()=>{
        if (ready) replenishData();
    }, [calendarDate]);

    useEffect(()=>{
        if (ready) {
            getData();
        }
    }, [ready, acctKey, refresh]);


    if (!ready) return (
        <>
            <div className="reporting-box">
                <Box p={2} bgcolor="background.paper" className="page-header"> 
                    <Typography variant="h5">
                        Schedule
                    </Typography>
                </Box>
            </div>
            <Divider />
            <div className="select-cover-message">
                <h2>Please select location and provider</h2>
            </div>                    
            <hr/>
        </>
    );

    return (
        <div className="div-schedule">
            <Box p={2} bgcolor="background.paper"> 
                <Grid container direction="row" justify="space-between"  alignItems="left" spacing={3}>
                    <Grid item>
                        <Typography variant="h6"> Schedule </Typography>
                    </Grid>

                    <Grid item>
                        <Typography>
                            <IconButton disabled={!ready} onClick={handleCreateAvailability}>
                                <AddCircleOutlineIcon/>
                            </IconButton>
                           Create Availability
                        </Typography>
                        <Typography>
                            <IconButton disabled={!ready} onClick={handleBlackoutPeriod}>
                                <AddCircleIcon/>  
                            </IconButton>
                            Blackout Period
                        </Typography>
                        <Typography>
                            <IconButton disabled={!ready}  onClick={handleClickOpen}>
                                <PreviewIcon/>  
                            </IconButton>
                            Preview
                        </Typography>                                            
                    </Grid>
                </Grid>  
            </Box>

            <Divider />

            <Box p={2} bgcolor="background.paper"> 
                <Calendar data={data} setCalendarDate={setCalendarDate} setRefresh={setRefresh} editBlockGroup={editBlockGroup} />
            </Box>

            <Drawer anchor="right" open={openDrawer} onClose={closeDrawer} >
                <Box p={2} bgcolor="background.paper" style={{width: 350}}>
                    {drawerMode == "create-availability" && <BlocksForm isAvailability={true} appointmentTypesData={appointmentTypes} onSaved={handleBlocksSaved} loadData={eventData} />}
                    {drawerMode == "create-blackoutperiod" && <BlocksForm isAvailability={false} appointmentTypesData={appointmentTypes} onSaved={handleBlocksSaved} loadData={eventData} />}
                </Box>
            </Drawer>

            {openDiaglog && <PreviewSchedule isOpen={openDiaglog} handleClose={handleClickClose} providerInfo={providerInfo} locationInfo={locationInfo}/>}
          
       </div>
    )
}
  