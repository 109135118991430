import jwtDecode from 'jwt-decode';
import { Base64 } from 'js-base64';
import momentLocaleWrapper from './momentLocaleWrapper';
import Constants from './constants';

/**
 * Represents a collection of utility methods.
 */
class Utils {
  /**
   * Base 64 encodes the provided string.
   * @param {String} str The string to encode.
   */
  static encodeBase64(str) {
    return Base64.encode(str);
  }

  /**
   * Base 64 decodes the provided string.
   * @param {String} str The string to decode.
   */
  static decodeBase64(str) {
    return Base64.decode(str);
  }

  /**
   * Decodes the provided json web token.
   * @param {String} jwt The json web token.
   */
  static decodeJwt(jwt) {
    let decodedJwt = null;

    if (jwt) {
      decodedJwt = jwtDecode(jwt);
    }

    return decodedJwt;
  }

  /**
   * Debounces a function.
   * @param {Function} func The function to debounce.
   * @param {Number} wait The amount in milliseconds to wait to call the function.
   * @param {Boolean} isImmediate A value that determines whether to call the function immediately.
   * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's debounce function.
   */
  static debounce(func, wait, isImmediate) {
    let timeout = null;

    return function () {
      const that = this;
      const args = arguments;
      const callLater = () => {
        timeout = null;

        if (!isImmediate) {
          func.apply(that, args);
        }
      };
      const callNow = isImmediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(callLater, wait);

      if (callNow) {
        func.apply(that, args);
      }
    };
  }

  static convertUrlToLocale() {
    // TODO: This should use getMarketConfigByUrl. Refactor after refactoring data calls.
    // Main issue is that call hasn't been made yet, and high risk to refactor now. 
    const url = window.location.hostname;
    let locale = 'en-US';
    if (url.endsWith('citaconmisojos.com')) {
      locale = 'es';
    } else if (url.endsWith('agendaotica.com')) {
      locale = 'pt';
    } else if (url.endsWith('eyebooknow.in')) {
      locale = 'en-IN';
    }

    return locale;
  }

  /**
   * Formats the provided appointment for use in the app.
   * @param {Object} appt The appointment.
   */
  static formatAppointment(appt) {
    appt.isVisible = true;
    appt.id = appt.appointmentId;
    appt.notes = appt.notes || '';
    appt.created = appt.created ? momentLocaleWrapper.utc(appt.created, '(YYYY, MM, DD, HH, mm)').toDate() : '';
    appt.startDate = appt.startTime ? momentLocaleWrapper(appt.startTime, "(YYYY, MM, DD, HH, mm)").toDate() : '';
    appt.endDate = appt.endTime ? momentLocaleWrapper(appt.endTime, '(YYYY, MM, DD, HH, mm)').toDate() : '';
    appt.title = `${appt.patient.firstName} ${appt.patient.lastName}`;
    appt.resourceId = appt.resource.resourceId;
  }

  /**
   * Formats the provided appointment types for use in the app.
   * @param {Array} appts The appointments.
   */
  static formatAppointments(appts) {
    if (appts?.length > 0) {
      for (let index = 0; index < appts.length; ++index) {
        const appt = appts[index];
        appt.isVisible = true;
        appt.id = appt.appointmentId;
        appt.notes = appt.notes || '';
        appt.created = appt.created ? momentLocaleWrapper.utc(appt.created, '(YYYY, MM, DD, HH, mm)').toDate() : '';
        appt.startDate = appt.startTime ? momentLocaleWrapper(appt.startTime, "(YYYY, MM, DD, HH, mm)").toDate() : '';
        appt.endDate = appt.endTime ? momentLocaleWrapper(appt.endTime, '(YYYY, MM, DD, HH, mm)').toDate() : '';
        appt.title = `${appt.patient.firstName} ${appt.patient.lastName}`;
        appt.resourceId = appt.resource.resourceId;
      }
    }
  }

  /**
   * Formats the appointment types for the location info.
   * @param {Object} locationInfo The location info.
   */
  static formatLocationInfoApptTypes(locationInfo) {
    if (locationInfo) {
      // Assign temp ids to appointment types in order to differeniate between them.
      locationInfo.appointmentTypes = locationInfo.appointmentTypes.map((apptType, index) => {
        apptType.id = index;
        apptType.isChecked = true;
        return apptType;
      });
    }
  }

  static formatAppointmentTypes(apptTypes) {
    if (apptTypes?.length > 0) {
      // Assign temp ids to appointment types in order to differeniate between them.
      for (let index = 0; index < apptTypes.length; ++index) {
        const appt = apptTypes[index];
        appt.id = index;
        appt.isChecked = true;
      }
    }
  }

  /**
   * Formats the provided resources for use in the app.
   * @param {Array} resources The resources.
   */
  static formatResources(resources) {
    // A lot of new locations will not have a default
    // resource, so we'll create a default one.
    if (resources?.length === 0) {
      resources.push({
        id: 0,
        displayName: Constants.defaultResourceDisplayName,
        hasHours: true,
        htmlColor: '#00acea',
        isChecked: true,
        isDefault: true,
        isEditing: false,
        publicView: true,
        sortOrder: 0,
        numConcurrentAppointments: 0,
        availableHours: this.getDefaultFormattedResourceHours()
      });
    }
    else {
      for (let index = 0; index < resources.length; ++index) {
        const resource = resources[index];
        resource.id = index;
        resource.isChecked = true;
        resource.isEditing = false;
        resource.isSelected = resource.isDefault;
        resource.hasHours = true;

        const {
          availableHours
        } = resource;

        if (!availableHours && availableHours.length === 0) {
          resource.availableHours = this.getDefaultFormattedResourceHours();
        }
      }
    }
  }

  /**
   * Masks the provided phone number (e.g. (###) ###-####)).
   * @param {Any} phone The phone to mask.
   */
  static maskPhone(phone) {
    let maskedPhone = phone;

    if (phone) {
      const matcher = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      maskedPhone = `${!matcher[2] ? matcher[1] : `(${matcher[1]}) ${matcher[2]}${matcher[3] ? `-${matcher[3]}` : ''}`}`;
    }

    return maskedPhone;
  }

  /**
   * Formats the resources for the location info.
   * @param {Object} locationInfo The location info.
   */
  static formatLocationInfoResources(locationInfo) {
    if (locationInfo) {
      const {
        resources
      } = locationInfo;

      // A lot of new locations will not have a default
      // resource, so we'll create a default one.
      if (resources.length === 0) {
        resources.push({
          id: 0,
          displayName: Constants.defaultResourceDisplayName,
          hasHours: true,
          htmlColor: '#00acea',
          isDefault: true,
          isEditing: false,
          publicView: true,
          sortOrder: 0,
          numConcurrentAppointments: 0,
          availableHours: this.getDefaultFormattedResourceHours()
        });
      }
      else {
        for (let index = 0; index < resources.length; ++index) {
          const resource = resources[index];
          resource.id = index;
          resource.isEditing = false;
          resource.hasHours = true;

          const {
            availableHours
          } = resource;
  
          if (!availableHours && availableHours.length === 0) {
            resource.availableHours = this.getDefaultFormattedResourceHours();
          }
        }
      }
    }
  }

  /**
   * Returns a shaded color that is meant to represent future appointments.
   * @param {String} color The base appointment color.
   */
  static getFutureShadedAppointmentColor(color) {
    let shadedColor = '';

    switch (color) {
      case '#D50000':
        shadedColor = '#EE9999';
        break;
      case '#F5511F':
        shadedColor = '#FBB9A5';
        break;
      case '#D8A822':
        shadedColor = '#EFDCA6';
        break;
      case '#0C8044':
        shadedColor = '#9DCCB4';
        break;
      case '#049CE5':
        shadedColor = '#9AD7F5';
        break;
      case '#4051B6':
        shadedColor = '#B3B9E2';
        break;
      case '#8E24AA':
        shadedColor = '#D2A7DD';
        break;
      case '#616161':
        shadedColor = '#C0C0C0';
        break;
      case '#F015FF':
        shadedColor = '#F999FF';
        break;
      case '#81B129':
        shadedColor = '#CCDFB0';
        break;
      default:
        shadedColor = color;
    }


    return shadedColor;
  }

  /**
   * Returns a shaded color that is meant to represent past appointments.
   * @param {String} color The base appointment color.
   */
  static getPastShadedAppointmentColor(color) {
    let shadedColor = '';

    switch (color) {
      case '#D50000':
        shadedColor = '#F7CCCC';
        break;
      case '#F5511F':
        shadedColor = '#FDDCD2';
        break;
      case '#D8A822':
        shadedColor = '#F7EED2';
        break;
      case '#0C8044':
        shadedColor = '#CEE6DA';
        break;
      case '#049CE5':
        shadedColor = '#CDEBFA';
        break;
      case '#4051B6':
        shadedColor = '#D9DCF1';
        break;
      case '#8E24AA':
        shadedColor = '#E9D3EE';
        break;
      case '#616161':
        shadedColor = '#E0E0E0';
        break;
      case '#F015FF':
        shadedColor = '#FCCCFF';
        break;
      case '#81B129':
        shadedColor = '#E6F0D8';
        break;
      default:
        shadedColor = color;
    }


    return shadedColor;
  }

  /**
   * Returns the day of the week based on its id.
   * @param {Number} dayOfWeekId The day of the week id.
   */
  static getDayOfWeek(dayOfWeekId) {
    let dayOfWeek = '';

    // TODO: Need to pull static localized days based on locale.
    switch (dayOfWeekId) {
      case 1:
        dayOfWeek = 'Sunday';
        break;
      case 2:
        dayOfWeek = 'Monday';
        break;
      case 3:
        dayOfWeek = 'Tuesday';
        break;
      case 4:
        dayOfWeek = 'Wednesday';
        break;
      case 5:
        dayOfWeek = 'Thursday';
        break;
      case 6:
        dayOfWeek = 'Friday';
        break;
      case 7:
        dayOfWeek = 'Saturday';
        break;
      default:
        dayOfWeek = 'Sunday';
        break;
    }

    return dayOfWeek;
  }

  /**
   * Returns the default formatted resource hours.
   */
  static getDefaultFormattedResourceHours() {
    return [
      {
        dayOfWeekId: 1,
        startTime: 144,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 204
      },
      {
        dayOfWeekId: 2,
        startTime: 108,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 264
      },
      {
        dayOfWeekId: 3,
        startTime: 108,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 264
      },
      {
        dayOfWeekId: 4,
        startTime: 108,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 264
      },
      {
        dayOfWeekId: 5,
        startTime: 108,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 264
      },
      {
        dayOfWeekId: 6,
        startTime: 108,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 264
      },
      {
        dayOfWeekId: 7,
        startTime: 108,
        breakOutTime: -1,
        breakInTime: -1,
        endTime: 264
      }
    ];
  }

  /**
   * Returns the default formatted store hours.
   */
  static getDefaultFormattedStoreHours() {
    return [
      {
        dayOfWeekId: 1,
        startTime: 144,
        endTime: 204
      },
      {
        dayOfWeekId: 2,
        startTime: 108,
        endTime: 264
      },
      {
        dayOfWeekId: 3,
        startTime: 108,
        endTime: 264
      },
      {
        dayOfWeekId: 4,
        startTime: 108,
        endTime: 264
      },
      {
        dayOfWeekId: 5,
        startTime: 108,
        endTime: 264
      },
      {
        dayOfWeekId: 6,
        startTime: 108,
        endTime: 264
      },
      {
        dayOfWeekId: 7,
        startTime: 108,
        endTime: 264
      }
    ];
  }

  /** 
   * Returns a url/query string parameter.
   * @param {String} name The name of the url/query string parameter.
   * @retuns String
   */
  static getUrlParam(name) {
    let value = '';

    if (typeof URLSearchParams !== 'undefined') {
      value = new URLSearchParams(window.location.search.toLowerCase()).get(name.toLowerCase());
    }
    else {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const params = new RegExp(`[\\?&]${name}=([^&#]*)`, 'i').exec(window.location.search);
      value = params === null ? '' : decodeURIComponent(params[1].replace(/\+/g, ' '));
    }

    value = value === null ? '' : value;

    return value;
  }

  /**
   * Returns a value that determines if the provided language tag is for Turkey.
   * @param {String} languageTag The language tag to check.
   */
  static isTurkeyMarket(languageTag) {
    return languageTag === Constants.turkeyLanguageTag;
  }

  /**
   * Returns a value that determines whether the provided phone number has the phone country code.
   * @param {String} phone The phone number to check.
   * @param {String} phoneCountryCode The phone country code (including +).
   */
  static hasCountryCodeInPhone(phone, phoneCountryCode) {
    let hasCode = false;
    const phoneCountryCodeDigit = phoneCountryCode.slice(1, phoneCountryCode.length);

    if (phone.slice(0, phoneCountryCodeDigit.length) === phoneCountryCodeDigit) {
      hasCode = true;
    }

    return hasCode;
  }

  /**
   * Returns a value that determines if the provided language tag is for the Latin America market.
   * @param {String} languageTag The language tag to check.
   */
  static isLatamMarket(languageTag) {
    return languageTag === Constants.spanishLanguageTag || languageTag === Constants.portugueseLanguageTag;
  }

  /**
   * Returns a value that determines whether the current url is for onboarding.
   */
  static isOnboardingUrl() {
    const hrefLowerCase = window.location.href.toLowerCase();

    return hrefLowerCase.includes(Constants.Routes.onboardingEnglish.toLowerCase()) ||
           hrefLowerCase.includes(Constants.Routes.onboardingSpanish.toLowerCase()) ||
           hrefLowerCase.includes(Constants.Routes.onboardingPortuguese.toLowerCase()) ||
           hrefLowerCase.includes(Constants.Routes.onboardingTurkish.toLowerCase());
  }

  /**
   * Updates a property of an object.
   * @param {Object} obj The object to update (object/array).
   * @param {Array} path The path of the property to set.
   * @param {Any} value The value. 
   */
  static update(obj, path, value) {
    if (obj && path) {
      if (path.length === 1) {
        obj[path] = value;
      }
      else {
        this.update(obj[path[0]], path.slice(1), value);
      }
    }
  }

  /** 
   * Returns the s3 link for terms of use based on locale
   * @param {String} locale specified locale for the terms of use.
   * @retuns String
   */
  static getTermsOfUseLink(locale) {
    return `https://s3-sa-east-1.amazonaws.com/eyebooknow.content/${process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT}/localizations/${locale}/html/admin.termsconditions.html`;
  }

  static getLogoUrl() {
    //const siteConfig = Storage.getItem(Constants.siteConfig);
    const siteId = 1;
    const url = `https://s3-sa-east-1.amazonaws.com/eyebooknow.content/${process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT}/sites/${siteId}/media/website-logo.png`;
    return url;
  }

  static getTaglineLogoUrl() {
    let url = '';
      url = `https://4patientcare.com/images/4patient_logo-trans.png`;
    return url;
  }
  static getSmall4PCLogoUrl()
  {
    let url = '';
    url = ` https://4patientcare.com/wp-content/uploads/2020/12/4PC_Logo-White.png`;
  return url;  
  }

  /** 
   * Returns the resource object for a resource schedule "swimlane" 
   * @param {Object} resources retreived from location info API
   * @retuns {Object} value of resourceId
   */
  static getResourceData(resources) {
    return resources.find((r) => r.fieldName === 'resourceId');
  }

  /** 
   * Returns the resource object for a resource schedule "swimlane" 
   * @param {Object} resources retreived from location info API
   * @retuns {Object} value of resourceId
   */
  static getDefaultResource(resources) {
    let defaultResource = null;

    if (resources && resources.length > 0) {
      defaultResource = resources.find((r) => r.isDefault);
    }

    return defaultResource;
  }

  /**
   * Lightens or darkens a color.
   * @param {String} color The color.
   * @param {Number} amount The amount. A positive value lightens. A negative value darkens.
   */
  static lightenDarkenColor(color, amount) {
    let usePound = false;
  
    if (color[0] === '#') {
        color = color.slice(1);
        usePound = true;
    }
 
    const num = parseInt(color, 16);
    let r = (num >> 16) + amount;
 
    if (r > 255) {
      r = 255;
    }
    else if (r < 0) {
      r = 0;
    }
 
    let b = ((num >> 8) & 0x00FF) + amount;
 
    if (b > 255) {
      b = 255;
    }
    else if (b < 0) {
      b = 0;
    }
 
    let g = (num & 0x0000FF) + amount;
 
    if (g > 255) {
      g = 255;
    }
    else if (g < 0) {
      g = 0;
    }
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  }
}

// Lock object to prevent modification (true static).
Object.freeze(Utils);

export default Utils;
