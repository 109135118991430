import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Utils from '../../utils';
import './LocationDetails.scss';
import LocationEditDeleteForm from './LocationEditDeleteForm';
import Overlay from '../Overlay/Overlay';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  item: {
    display: "none"
  }
}));

export const getFullAddress = (r) => {
  let ret = r.addressLine1;
  if (r.addressLine2) ret = `${ret}, ${r.addressLine2}`;

  return `${ret}, ${r.city}, ${r.state} ${r.zip}`;
}


export default function LocationDetails(props) {
  const classes = useStyles();
  const {collapseLocation, setCollapseLocation} = props;
  const [searchLocation, setLocationSearch] = useState('');
  const {showEditDelete, setEditDelete} = props;
  const {validate, setValidate,setData, onboardingCode, isSmartBook } = props;
  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');

  const [fixedDataSource, setFixedDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const _handleCollapseLocation = () => {
    setCollapseLocation(!collapseLocation);
  };

  const _handleEditDeleteLoc = () => {
    setEditDelete(true);
    setCollapseLocation(true);
  };

  const _handleViewLoc = () => {
    setEditDelete(false);
    setCollapseLocation(true);
  };

  const _setOnboardingData = (obData) =>
  {
    setData({
      data:obData,
      onboardingCode: onboardingCode
    });

    setLocationsData(obData.locations);
  }
  
  const _handleCallBack = async (childData, action) => {
    if (childData) {
      setshowLoadingOverlay(true);            
      try {
        const url = props.isSmartBook ? `${process.env.REACT_APP_ONBOARDINGDATA_API}/save/${props.onboardingCode}` :  `${process.env.REACT_APP_NSBONBOARDINGDATA_API}/save/${props.onboardingCode}` ;            
        const res = await axios.post(url, childData);
  
        if (res && res?.status === 200) {
          setLocationsData(childData.locations);

          let message = (action === 'update' ? 'Location details successfully saved.' : 'Location details successfully deleted');
          setErrorSeverity("success");
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        } 
        else {
          let message = (action === 'update' ? 'Error in saving location details.' : 'Error in deleting location details.');
          setErrorSeverity("error");
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        }
      } catch {
        let message = 'Unexpected error in saving location details.';
        setErrorSeverity("error");
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
      setshowLoadingOverlay(false);
    } else {
      let message = 'Invalid onboarding data.';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }

  }

  const setLocationsData = (ds) => {
    let data;

    if (searchLocation !== '') { 
      data = ds.filter(d => d.name.toLowerCase().indexOf(searchLocation.toLocaleLowerCase()) >= 0);
    } else {
      data = ds;
    }
    
    for (var i=0; i < data.length; i++) {      
      let fullAddress = getFullAddress(data[i]);

      data[i] = {...data[i], fullAddress: fullAddress};
    }

    setFixedDataSource(ds);
    setFilteredDataSource(data);
  };

  const filterLocations = (searchString) => {
    setLocationSearch(searchString);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
   };  

   useEffect(() => {
    setLocationsData(props.data);
  }, [props.data, searchLocation]);

  return (
    <>
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      p={0}
    >
      <ListItem>
        <ListItemText onClick={_handleCollapseLocation}>
          <span className="listHeader"><b>{props.headerName}</b></span>
        </ListItemText>

        { 
          //***** Show Search UI if on view mode *************          
          !showEditDelete && 
            <div className="search" style={{display: collapseLocation ? 'block' : 'none' }}>
              <FormControl variant="outlined">
                <TextField
                  value={searchLocation}
                  onChange={e => filterLocations(e.target.value)}
                  placeholder="Search"
                  className="form-input"
                  InputProps={{
                    startAdornment: (
                    <InputAdornment>
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>                      
                    </InputAdornment>
                    ),
                  }}
                size="small"
                />
              </FormControl>
            </div>
        }

        {collapseLocation ? <ExpandLess onClick={_handleCollapseLocation} /> : <ExpandMore onClick={_handleCollapseLocation}/>}
      </ListItem>      
    </List>
    <Divider />
    <Collapse in={collapseLocation} timeout="auto">
        <List component="div" disablePadding>
        { 
          showEditDelete ?
            fixedDataSource.map(loc => 
               <LocationEditDeleteForm
                setOnboardingData={_setOnboardingData}
                validate={validate}
                setValidate={setValidate} 
                data={loc} 
                parentCallback={_handleCallBack} 
                key={loc.practiceId}
                completeData={props.completeData}/>
            )
          :
            filteredDataSource.map((loc, i) => 
              <ListItem key={loc.practiceId} button className={classes.nested}>
                <ListItemText>
                  <div className="row-location">
                      <div className="column-location">
                        <div><b>{props.subHeaderName} {(i+1)}: </b> {loc.name}</div>
                        <div><b>Location Address: </b> {loc.fullAddress}</div>
                        <div><b>Location Phone Number </b> {'+1' + Utils.maskPhone(loc.phoneNumber)}</div>
                      </div>
                      <div className="column-location">
                        <div><b>Location Email: </b> {loc.email}</div>
                        <div><b>Location Website: </b> {loc.websiteUrl}</div>
                        <div><b>Location NPI: </b> {loc.npiNumber}</div>
                      </div>
                  </div>
                </ListItemText>                
              </ListItem>
            )
        }

        { showEditDelete ? 
            <div className="clsButton">
              <Box p={2} bgcolor="background.paper">
                <Button onClick={_handleViewLoc} fontSize={16} variant="outlined">VIEW LOCATION DETAILS</Button>
              </Box>
            </div>
          :
            <div className="clsButton">
              <Box p={2} bgcolor="background.paper">
                <Button onClick={_handleEditDeleteLoc} fontSize={16} variant="outlined">EDIT LOCATION DETAILS</Button>
              </Box>
          </div>
        }
      </List>
    </Collapse>
    <Overlay show={showLoadingOverlay}><i className="spinner-eclipse"></i></Overlay>    
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
      <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
      {SnackbarMessage} 
      </Alert>
    </Snackbar>
    </>
  );
}