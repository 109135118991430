import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment/moment';
import MaterialTable from "material-table";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import LocationDetails from '../../Components/LocationDetails/LocationDetails';
import PracticeDetails from '../../Components/PracticeDetails/PracticeDetails';
import ProviderDetails from '../../Components/ProviderDetails/ProviderDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Overlay from '../../Components/Overlay/Overlay';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './ReviewData.scss';

const columns = [
  { field: 'practiceName', title: 'Practice Name', width: 200 , editable: 'never'},
  { field: 'agreementSigned', title: 'Agreement Signed Date', width: 200 , editable: 'never' , align: "center"},
  { field: 'onboardingFinished', title: 'Onboarding Finished', width: 200  , editable: 'never' , align: "center"}
]; 

const columns_nsb = [
  { field: 'practiceName', title: 'Practice Name', width: 200 , editable: 'never'},
  { field: 'onboardingStarted', title: 'Onboarding Started', width: 200 , editable: 'never' , align: "center"},
  { field: 'onboardingFinished', title: 'Onboarding Finished', width: 200  , editable: 'never' , align: "center"}
]; 

const bookingType = {
  booking: 1,
  booking_crm: 2,
  non_smartbook: 3
};

const timeZones = [ //Timezone based on Communication APIs
  { id: 4, timeZone: 'Eastern'},
  { id: 5, timeZone: 'Central'},
  { id: 6, timeZone: 'Mountain'},
  { id: 7, timeZone: 'Pacific'},
  { id: 8, timeZone: 'Alaska'},
  { id: 10, timeZone: 'Hawaii'}
];

let srcData = null;

export default function ReviewData () {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("success");
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [withError, setWithError] = useState(false);
  const [collapseLocation, setCollapseLocation] = useState(false);
  const [showLocationEditDelete, setLocationEditDelete] = useState(false);
  const [showListView, setShowListView] = useState(true);
  const [bookingState, setBookingState] = useState(bookingType.booking);
  const [workingData, setWorkingData] = useState([]);
  const [itemData, setItemData] = useState(null);
  const [collapseProvider, setCollapseProvider] = useState(false);
  const [showProviderEditDelete, setProviderEditDelete] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isSmartBook, setIsSmartBook] = useState(true);

  const [itemSubmitted, setItemSubmitted] = useState(false);
  
  //#region Data Access 

  const _postItemData = async (data, onboardingCode) => {
    let ret = false;

    if (data.locations?.length <= 0) {
      _itemSubmitDialog(false, "No location added", true, false);
      return false;
    }

    try {
      setValidate(false);
      const url = `${process.env.REACT_APP_J45_API}/practices/onboard`;
      const j45Data =
      {
        parentId: data.parentId,
        IsSmartBook: isSmartBook,
        practice: {
          address1: data.locations[0].addressLine1,
          city: data.locations[0].city,
          state: data.locations[0].state,
          email: data.locations[0].email,
          englishURL: data.locations[0].websiteUrl,
          firstName: data.primaryContact.firstName,
          lastName: data.primaryContact.lastName,
          orgName: data.practiceName,
          zip: data.locations[0].zip,
          frontLine: data.locations[0].phoneNumber?.replace(/\D/g,''),
        },
        locations: [],
        providers: [],
        schedules: []
      };

      data.locations.forEach(loc => {

        const tz = timeZones.filter((tz) => {
          return tz.timeZone === loc.timeZone;
        });

        var locationData = 
        {
          practiceId : loc.practiceId,
          locData: 
          {
            orgName: loc.legalBusinessName,
            firstName: data.primaryContact.firstName,
            lastName: data.primaryContact.lastName,
            address1: loc.addressLine1,
            address2: loc.addressLine2,
            city: loc.city,
            state: loc.state,
            zip: loc.zip,
            frontLine: loc.phoneNumber?.replace(/\D/g,''),
            email: loc.email,
            webURL: loc.websiteUrl,
            timeZone: tz[0].id
          }
          
        };

        j45Data.locations.push(locationData)
      });

      data.providers.forEach(prov => {
        var provData = 
        {
          onboardingProviderId: prov.onboardingProviderId,
          acctData: 
          {
            title: prov.title,
            firstName: prov.firstName,
            lastName: prov.lastName,
            frontLine: prov.phone?.replace(/\D/g,''),
            email: prov.email
          }
          
        };
        j45Data.providers.push(provData)
      });

      data.providerSchedules.forEach(s => {
        var sched = 
        {
          onboardingProviderId: s.onboardingProviderId,
          practiceId: s.practiceId
        };

        var provLoc = false;
        if (s.schedule["Sunday"][0].startTimeBlock !== -1 || s.schedule["Sunday"][0].endTimeBlock !== -1 || 
            s.schedule["Sunday"][1].startTimeBlock !== -1 || s.schedule["Sunday"][1].endTimeBlock !== -1) {
            provLoc = true;
        } else if (s.schedule["Monday"][0].startTimeBlock !== -1 || s.schedule["Monday"][0].endTimeBlock !== -1 || 
        s.schedule["Monday"][1].startTimeBlock !== -1 || s.schedule["Monday"][1].endTimeBlock !== -1) {
            provLoc = true;
        } else if (s.schedule["Tuesday"][0].startTimeBlock !== -1 || s.schedule["Tuesday"][0].endTimeBlock !== -1 || 
        s.schedule["Tuesday"][1].startTimeBlock !== -1 || s.schedule["Tuesday"][1].endTimeBlock !== -1) {
            provLoc = true;
        } else if (s.schedule["Wednesday"][0].startTimeBlock !== -1 || s.schedule["Wednesday"][0].endTimeBlock !== -1 || 
        s.schedule["Wednesday"][1].startTimeBlock !== -1 || s.schedule["Wednesday"][1].endTimeBlock !== -1) {
            provLoc = true;
        } else if (s.schedule["Thursday"][0].startTimeBlock !== -1 || s.schedule["Thursday"][0].endTimeBlock !== -1 || 
        s.schedule["Thursday"][1].startTimeBlock !== -1 || s.schedule["Thursday"][1].endTimeBlock !== -1) {
            provLoc = true;
        } else if (s.schedule["Friday"][0].startTimeBlock !== -1 || s.schedule["Friday"][0].endTimeBlock !== -1 || 
        s.schedule["Friday"][1].startTimeBlock !== -1 || s.schedule["Friday"][1].endTimeBlock !== -1) {
            provLoc = true;
        } else if (s.schedule["Saturday"][0].startTimeBlock !== -1 || s.schedule["Saturday"][0].endTimeBlock !== -1 || 
        s.schedule["Saturday"][1].startTimeBlock !== -1 || s.schedule["Saturday"][1].endTimeBlock !== -1) {
            provLoc = true;
        }
        if(provLoc)
          j45Data.schedules.push(sched);
      });

      const res = await axios.post(url, j45Data);

      if (res && (res?.status === 200 || res?.status === 201)) {
        try //update mapping info and onboarding data
        {
          var saveOnboardingDataUrl = isSmartBook ? `${process.env.REACT_APP_ONBOARDINGDATA_API}/save/${onboardingCode}?hubspot=true` : `${process.env.REACT_APP_NSBONBOARDINGDATA_API}/save/${onboardingCode}` ;
          var mappingInfoUrl = `${process.env.REACT_APP_ONBOARDINGDATA_API}/mappingInfo`;
          var providerUrl = `${process.env.REACT_APP_ONBOARDINGDATA_API}/updateProvider`;
         
          data.locations.forEach(async (location,i) => {

            // var existingMappingInfo = location.mappingInfo;
            var mappingInfo = 
            {
              addressLine1: location.addressLine1,
              practiceId: location.practiceId,
              useBrandManager: location.useBrandManager,
              googleMyBusinessEnabled: location.googleMyBusinessEnabled,
              coverKey: res.data.coverKey,
              lmid: location.lmid,
              locKey: `${res.data.locKeys[i]}`,
              locName: location.name,
              macolaId: location.macolaId,
              parentId: data.parentId,
              practiceName: data.practiceName
            }
            location.locKey = res.data.locKeys[i];
            location.mappingInfo.coverKey = res.data.coverKey;
            location.mappingInfo.locKey = res.data.locKeys[i];
            location.mappingInfo.locName = location.name;
            if (isSmartBook) {
              try 
              {
                var mappingResponse = await axios.post(mappingInfoUrl,mappingInfo);
                console.log(mappingResponse);
              } 
              catch (error) 
              {
                _itemSubmitDialog(true, error, null, error);              
              }
            }
            
          });

          
          data.providers.forEach(async (provider,i) => {

            provider.acctKey = res.data.acctKeys[i];
            var provUrl = `${providerUrl}/?providerIndex=${i}`;
            if (isSmartBook) {
              try 
              {
                var providerResponse = await axios.post(provUrl, data);
                console.log(providerResponse);
              } 
              catch (error) 
              {
                _itemSubmitDialog(true, error, null, error);
              }
            }
            
          });
        

          data.coverKey = res.data.coverKey;
          try 
          {
            await axios.post(saveOnboardingDataUrl,data);

          } 
          catch (error) 
          {
            _itemSubmitDialog(true, error, null, error);
          }
          
          //if we get here, that means everything went well, display keys and note succesful mapping info save

          if (!withError)
          {
            const message = "Cover " + data.coverKey + " has been successfully created."
            _itemSubmitDialog(false, message, true, null);
          }
          else
          {
            const message  = "Cover " + data.coverKey + " has been successfully created. Error saving mapping info to Locator DB, please use onboarding form to enter manually."
            _itemSubmitDialog(false, message, true, null);
          }
        }
        catch(e)
        {
          const message  = "Cover " + data.coverKey + " has been successfully created. Error saving mapping info to Locator DB, please use onboarding form to enter manually."
          _itemSubmitDialog(false, message, true, e);
          //error saving mapping info, display created keys from j45 response but note error saving mapping info to onboarding
          
        }

        ret = true;
      }
    }
    catch(e)
    {
      if(e.response?.status === 422)
      {
        _ExpandAndValidateForm();
        const message = "Key generation was unsuccessful.\nPlease check all required fields.\n";
        _itemSubmitDialog(true, message, true, null);
        
        //error returned saving j45 data, no keys to display 
      }
      else
      {
        const message  = "Unknown error occured, Key generation was unsuccessful."
        _itemSubmitDialog(true, message, true, e);
        //unknown error before calling j45 api, no keys to 
      }
    } 
    return ret;
  };

  const _ExpandAndValidateForm = () => {
    setCollapseLocation(true);
    setLocationEditDelete(true);
    setCollapseProvider(true);
    setProviderEditDelete(true);
    setValidate(true);
  };

  const _getItemData = async (onboardingCode, isSmartBook) => {
    let ret = null;

    try {
      if (isSmartBook) {
        const url = `${process.env.REACT_APP_ONBOARDINGDATA_API}/${onboardingCode}?fi=true&fillInfo=true`;
        const res = await axios.get(url);
    
        if(res && res.data) {
          ret = res.data;
        } 
      } else {
        
        const urlnsb = `${process.env.REACT_APP_NSBONBOARDING_API}/OnboardingData/${onboardingCode}?fi=true&fillInfo=true`;
        const resnsb = await axios.get(urlnsb);

        if(resnsb && resnsb.data) {
          ret = resnsb.data;
        } 
      }
    } catch {
      _dataError();
    }
    
    return ret;
  };

  const _getData = async () => {
    let ret = [];

    try {
      let url = `${process.env.REACT_APP_ONBOARDING_API}/statusRecordsForApproval`;
      let res = await axios.get(url);    
  
      if(res?.data.length > 0) {
        //map data
        res.data.forEach((data) => {
          let onboardingFinished = (data.onboardingFinishedStr !== '-' ? moment(data.onboardingFinished).format('MM/DD/YYYY') : data.onboardingFinishedStr);
          let agreementSigned = (data.agreementSignedStr !== '-' ? moment(data.agreementSigned).format('MM/DD/YYYY') : data.agreementSignedStr);
          
          ret.push(Object.assign({'parentId': data.parentId, 'smartBook': true,
                                          'practiceName': data.practiceName, 
                                          'agreementSigned': agreementSigned,
                                          'onboardingFinished': onboardingFinished,
                                          'crmOptIn': data.crmOptIn,
                                          'onboardingCode': data.onboardingCode}));
        })
      }

      let urlnsb = `${process.env.REACT_APP_NSBONBOARDING_API}/Admin/statusRecordsForApprovalKeyCreation`;
      let resnsb = await axios.get(urlnsb);    
      if(resnsb?.data.length > 0) {
        //map data
        resnsb.data.forEach((data) => {
          let onboardingFinished = (data.onboardingFinishedStr !== '-' ? moment(data.onboardingFinished).format('MM/DD/YYYY') : data.onboardingFinishedStr);
          let onboardingStarted = (data.onboardingStartedStr !== '-' ? moment(data.onboardingStarted).format('MM/DD/YYYY') : data.onboardingStartedStr);
          
          ret.push(Object.assign({'hubSpotDealId': data.hubSpotDealId, 'smartBook': false,
                                          'practiceName': data.practiceName, 
                                          'onboardingStarted': onboardingStarted,
                                          'onboardingFinished': onboardingFinished,
                                          'onboardingCode': data.onboardingCode}));
        })
      }

    } catch {
      _dataError();
    }    

    return ret;
  };

  const _getBookingData = (booking) => {
    setBookingState(booking);

    if (booking === 3 ) {
      return srcData.filter((p) => p !== undefined && !p.smartBook)
    } else {
      let crmOptIn = booking === bookingType.booking ? 'false' : 'true';
      return srcData.filter((p) => p !== undefined && (p.smartBook && (!p.crmOptIn || p.crmOptIn === crmOptIn)))
    }
  };

  const _dataError = () => {
    setErrorSeverity("error");
    setOpenSnackBar(true);
    setSnackbarMessage("Unexpected error in getting Onboarding Practice Data.");
  };

  const _itemSubmitDialog = (_withError, _dialogMessage, _showDialog, _log) => {
    if (_withError != null) setWithError(_withError);
    if (_dialogMessage != null) setDialogMessage(_dialogMessage);
    if (_showDialog != null) setShowDialog(_showDialog);
    if (_log != null) console.log(_log);
  };
  //#endregion

  //#region Methods
  const loadListUI = async (booking, forceReload) => {
    setShowLoadingOverlay(true);
    if (booking === 3) {
      setIsSmartBook(false);
    } else {
      setIsSmartBook(true);
    }

    if (srcData == null || forceReload) {
      srcData = await _getData();
    }

    setWorkingData(_getBookingData(booking));

    setShowLoadingOverlay(false);
  };

  const loadItemUI = async (onboardingCode, isSmartBook) => {
    setShowLoadingOverlay(true);
    setShowListView(false);

    let d = await _getItemData(onboardingCode, isSmartBook);
    if (d != null) {
      setItemData({
        data: d,
        onboardingCode: onboardingCode
      });
    }
    
    setShowLoadingOverlay(false);
  };

  const itemUISubmit = async (data, onboardingCode) => {
    setShowLoadingOverlay(true);

    if (await _postItemData(data, onboardingCode)) {
      setItemSubmitted(true);
    }

    setShowLoadingOverlay(false);
  };

  const changeBookingType = (booking) => {
    loadListUI(booking);
  };
  
  const goBack = () => {
    setShowListView(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
    if(!withError)
      setShowListView(true);
  };

  const reloadList = (e) => {
    e.preventDefault();

    loadListUI(bookingState, true);
  };

  //#endregion

  useEffect(() => {
    if (itemSubmitted) {  
      (async () => {      
        await loadListUI(bookingState, true);
        setItemSubmitted(false);
      })();
    }
  }, [itemSubmitted]);

  useEffect(() => {
    loadListUI(bookingType.booking, true);
  }, []);

  return (
    <div>
      <Box p={2} bgcolor="background.paper">
        <Grid container justify="space-between" alignItems="center" spacing={2} className="onboarding-header">
          <Grid item xs={8} sm={8}>
          <Typography variant="h5" >  
            Review Data
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem/>

          <Typography variant="h6">  
            Review Practice, Location & Provider Data
          </Typography>
          </Grid>

          {showListView ? 
            <Grid item>
              <Select
                  id="viewType"
                  value={bookingState}
                  onChange={e => changeBookingType(e.target.value)}    
                  className="form-input"          
                >
                <MenuItem value={1} selected>Booking</MenuItem>
                <MenuItem value={2}>Booking + CRM</MenuItem>
                <MenuItem value={3}>Install/Add Service</MenuItem>
              </Select>
            </Grid>
            :
            <div className="grdIco">
                <Button onClick={goBack} className="icon-flipped">
                  <Tooltip title="Back">
                    <Icon>send</Icon>
                  </Tooltip>
                  <Typography variant="h6">  
                    &nbsp;&nbsp;BACK
                  </Typography>
                </Button>
              </div>
          }         
        </Grid>
      </Box>
      <div className="page-cont">        
        <div className={showListView ? "showme" : ""} id="div-listview">   
          <Tooltip title="Reload List"> 
          <IconButton className="reload-btn" onClick={e => reloadList(e)}>
            <RefreshIcon/>
          </IconButton>
          </Tooltip>
          <Box p={2} bgcolor="background.paper" className="onboarding-body">
            { !isSmartBook ? 
                <MaterialTable
                height="200"
                columns={columns_nsb}
                data={workingData}
                title="Search By:"
                localization={{ toolbar: { searchPlaceholder: 'Search by Practice Name' } }}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 15, 20],
                  toolbar: true,
                  paging: true,
                  draggable: false
                }}        
                actions={[
                  {
                    icon: () => <VisibilityIcon fontSize="small" />,
                    tooltip: 'View Detail',
                    onClick: (event, rowData) => loadItemUI(rowData.onboardingCode, rowData.smartBook)
                  }
                ]}
                /> 
              :
                <MaterialTable
                height="200"
                columns={columns}
                data={workingData}
                title="Search By:"
                localization={{ toolbar: { searchPlaceholder: 'Search by Practice Name' } }}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 15, 20],
                  toolbar: true,
                  paging: true,
                  draggable: false
                }}        
                actions={[
                  {
                    icon: () => <VisibilityIcon fontSize="small" />,
                    tooltip: 'View Detail',
                    onClick: (event, rowData) => loadItemUI(rowData.onboardingCode, rowData.smartBook)
                  }
                ]}
                /> 
            }            
            
          </Box>
        </div>
        <div className={!showListView ? "showme" : ""} id="div-itemview">
         {itemData && !showListView ? 
             <ItemUI
              validate={validate}
              setValidate={setValidate}
              collapseProvider={collapseProvider}
              setCollapseProvider={setCollapseProvider}
              showProviderEditDelete={showProviderEditDelete}
              setProviderEditDelete={setProviderEditDelete}
              showLocationEditDelete={showLocationEditDelete}
              setLocationEditDelete={setLocationEditDelete} 
              collapseLocation={collapseLocation} 
              setCollapseLocation={setCollapseLocation} 
              data={itemData.data}
              setData={setItemData} 
              onboardingCode={itemData.onboardingCode} 
              isSmartBook={isSmartBook}
              itemUISubmit={itemUISubmit} />
             : 
             null
          }
        </div>
      </div>
      <Overlay show={showLoadingOverlay}><i className="spinner-eclipse"></i></Overlay> 
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} 
        onClose={() => setOpenSnackBar(false) }>
        <Alert onClose={() => { 
          setOpenSnackBar(false); 
          setErrorSeverity(errorSeverity);
        }}>
        {snackbarMessage} 
        </Alert>
      </Snackbar>  
      <Dialog
        open={showDialog}
        onClose={() => closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>   
    </div>    
  );
}

//#region Other Components

function ItemUI (props) {
  //const [data, setData] = useState(null);
  const [onboardingCode, setOnboardingCode] = useState(""); 
  const {
    data,
    setData,
    validate,
    setValidate,
    collapseLocation, 
    setCollapseLocation, 
    showLocationEditDelete, 
    setLocationEditDelete, 
    showProviderEditDelete, 
    setProviderEditDelete, 
    collapseProvider, 
    setCollapseProvider,
    isSmartBook} = props;
  useEffect(() => {
    //setData(props.data);
    setOnboardingCode(props.onboardingCode);
  });

  return (
        <Box p={2} bgcolor="background.paper" className="onboarding-body">
          <div className="div-onboarding-body">
          
              <Box p={0}  bgcolor="background.paper">
                { (data && data?.primaryContact) ?
                    <PracticeDetails data={data} headerName ="Practice Details"/>
                : null }
              </Box>
              <Box p={0} bgcolor="background.paper">
                { (data && data?.locations?.length > 0) ?
                    <LocationDetails
                      validate={validate}
                      setValidate={setValidate}
                      showEditDelete = {showLocationEditDelete}
                      setEditDelete = {setLocationEditDelete} 
                      collapseLocation = {collapseLocation} 
                      setCollapseLocation={setCollapseLocation} 
                      data={data.locations} completeData={data}
                      setData={setData} 
                      onboardingCode={onboardingCode} 
                      isSmartBook={isSmartBook}
                      headerName ="Location Details" subHeaderName="Location"/>
                : null }
              </Box>
              <Box p={0}  bgcolor="background.paper">
                { (data && data?.providers?.length > 0) ?
                    <ProviderDetails
                      validate={validate}
                      setValidate={setValidate}
                      showEditDelete={showProviderEditDelete}
                      setEditDelete={setProviderEditDelete}
                      collapseProvider={collapseProvider}
                      setCollapseProvider={setCollapseProvider} 
                      data={data.providers} 
                      completeData={data}
                      setData={setData} 
                      onboardingCode={onboardingCode} 
                      isSmartBook={isSmartBook}
                      headerName ="Provider Details"/>
                : null }
              </Box> 
            
          
          </div>
          <div className="clsButton">
          <Box p={2} bgcolor="background.paper">
            <Button fontSize={16} 
                      variant="contained" 
                      color="primary" 
                      size="large"
                      onClick={(e) => { props.itemUISubmit(data, onboardingCode) }}
                      endIcon={<Icon>send</Icon>}>
                      SAVE & SUBMIT
              </Button>
          </Box>
          </div>
        </Box>
  );
}


function Alert (props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}; 
//#endregion