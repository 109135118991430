import React, {useEffect, useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import './DialogBoxes.scss';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

export const DialogType = Object.freeze({
    information: 0,
    error: 1
});

const DialogOptions = Object.freeze({
    defaultTitle: "",
    title: "",
    type: DialogType.information,
    useChildren: false,    
    fullScreen: false,
    open: false,
    onClose: null
});

export const MessageBoxOptions = Object.freeze({
    ...DialogOptions,
    defaultTitle: "Application Message",
    buttonText: "Ok"
});

export const ConfirmBoxOptions = Object.freeze({
    ...DialogOptions,
    defaultTitle: "Please confirm...",
    confirmText: "Yes",
    cancelText: "No"
});

export function _MessageBox(props) {     
    const [open, setOpen] = useState(props.open);

    const closeDialog = (param) => {
        setOpen(false);
        props.onClose && props.onClose(param); 
    };

    return (
        <Dialog
            className={`uicontext-dialog ${props.type == DialogType.information ? "message-box" : "error-box"}`}
            fullScreen={props.fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {props.type == DialogType.information ? <InfoIcon /> : <WarningIcon />}                    
                    {props.title ? props.title :
                        props.type == DialogType.error ? "Application Error" : props.defaultTitle}
                    <CancelPresentationIcon onClick={() => closeDialog(-1)} className="dialogCloseIcon" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.useChildren ? props.children : props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog(0)} color="primary" variant="outlined" autoFocus>
                        {props.buttonText}
                    </Button>
                </DialogActions>
        </Dialog>      
    );
}

export function _ConfirmBox(props) {
    const [open, setOpen] = useState(props.open);

    const closeDialog = (param) => {
        setOpen(false);
        props.onClose && props.onClose(param); 
    };

    return (
        <Dialog
            className="uicontext-dialog confirm-box"
            fullScreen={props.fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
            >

                <DialogTitle id="responsive-dialog-title">                    
                    <HelpIcon />
                    {props.title ? props.title : props.defaultTitle}
                    <CancelPresentationIcon onClick={() => closeDialog(-1)} className="dialogCloseIcon" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.useChildren ? props.children : props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog(1)} color="primary" variant="outlined" autoFocus>
                        {props.confirmText}
                    </Button>
                    <Button onClick={() => closeDialog(0)} color="default" variant="outlined">
                        {props.cancelText}
                    </Button>
                </DialogActions>
        </Dialog>      
    );
}