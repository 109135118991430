import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';

/**
 * Represents the authentication service.
 */
class AuthService {
  /**
   * Authenticates the current user.
   */
  static async authenticateUser() {
    return new Promise(async (resolve, reject) => {
      let isAuthenticated = false;
      const user = Storage.getItem(Constants.currUserKey);
      const jwt = user?.jwt;
      
      if (jwt) {
        try {
          const url = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(url);

          isAuthenticated = true;

          resolve(isAuthenticated);
        }
        catch {
          reject(isAuthenticated);
        }
      }
      else {
        resolve(isAuthenticated);
      }
    });
  }

  /**
   * Logs the current user out.
   */
  static logoutUser() {
    Storage.removeItem(Constants.currUserKey);
    window.location = '/';
  }

  /**
   * Redirects the user to the login page.
   */
  static redirectToLogin() {
    window.location = `/?returnUrl=${window.location.href}`;
  }
}

export default AuthService;
