import React, { Component, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import TextField from '@material-ui/core/TextField';
import Enums from '../../enums';
import Utils from '../../utils';
import Storage from '../../storage';
import Constants from '../../constants';
import Overlay from '../../Components/Overlay/Overlay';
import { Link } from 'react-router-dom';
import './Forgot.scss';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import MainLogo from '../../Assets/Images/4patient_logo-trans_New.png';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';



export default function EmailLink() {

    const params = useParams();
    const [userName, setUserName] = useState();
    const [link, setLink] = useState();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [SnackbarMessage, setSnackbarMessage] = useState('');
    const [errorSeverity, setErrorSeverity] = useState('success');

    const userId = params.id;
    const _onSubmit = async () => {
        const systemDate = moment().format('MM/DD/YYYY HH:mm:ss');
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const takData = { UserName: userName, Email: email, EmailLink: link, UserId: userId,LinkDate:new Date(systemDate) };
        const url = `${process.env.REACT_APP_ADMIN_API}/sendForgotPasswordLink?secretCode=${apiKey}`;
        const res = await axios.post(url, takData)
            .then(response => {
                let strJwt = response.data
                if (strJwt.toString() !== "") {
                    setEmailError("");
                    setErrorSeverity("success");
                    setOpenSnackBar(true);
                }
            })
    };

    const EmailInput = async (email) => {
        setEmail(email);
    }

    return (
        <>
            <ValidatorForm onSubmit={_onSubmit} onError={errors => console.log(errors)}>
                <div className="login">
                    <section className="login__header">

                        <div className="login-header">
                            <div className="login-header__logo-cont">
                                <img
                                    alt="Site Logo"
                                    src={MainLogo} />
                            </div>
                        </div>

                    </section>
                    <section className="login__content">
                        <div className="login-norm">

                            <h1 className="login-norm__title">{'4PC Admin Page'}</h1>
                            <h3 className="login-norm__subtitle">{'Email verification link expired'}</h3>
                            <h6 className="login-norm__subtitle">{'Looks like the verifcation has expired. Not to worry,we can send the link again.'}</h6>
                            <form className="login-norm__form">
                                <span className={`login-norm__error login-norm__error--no-margin ${emailError ? 'login-norm__error--visible' : emailError}`}>{emailError}</span>
                                <button
                                    className="login-norm__email_link"
                                    disabled={false}
                                    type="submit"
                                >
                                    {'Resend verification link'}
                                </button>
                            </form>
                            <div className="login-norm__subtitle">You can enter your email <Link to="/forgot">here </Link> if the one you entered was incorrect</div>
                            <Overlay show={false}>
                                <i className="spinner-eclipse"></i>
                            </Overlay>
                        </div>
                    </section>
                </div>
            </ValidatorForm>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={10000} onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="info">
                    Link has been sent to you email account.Please click the link to change your password.
                </Alert>
            </Snackbar>
        </>
    );
}
