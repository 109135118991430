import React from "react";
import UIContextProvider from "./UIContextProvider";

export default function ContextProviders(props) {  
    
    return (
            <UIContextProvider>
                {props.children}           
            </UIContextProvider>
    );
}