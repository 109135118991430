// Dependencies
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Components
import Login from "../Pages/Login/Login.js";
import MainForm from '../Pages/MainForm/MainForm';
import Forgot from '../Pages/ForgotPassword/Forgot';
import Reset from '../Pages/ForgotPassword/Reset';
import EmailLink from '../Pages/ForgotPassword/EmailLink';


export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={Login} />
        <Route
          path="/main"
          component={MainForm} />
        <Route
          exact
          path="/forgot"
          component={Forgot} />
        <Route
          path="/reset/:id/:urlId"
          component={Reset} />
        <Route
          exact
          path="/emaillink/:id"
          component={EmailLink} />
      </Switch>
    </Router>
  );
}
