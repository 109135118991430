import { ComponentType, createGuid } from './coreLib';

export const DefaultManagedProps = Object.freeze({
    parentManagedComponentId: "",
    componentId: "",
    componentType: ComponentType.UnManaged,
    guid: "",
    managedComponentId: ""
});

export const createManagedProps = (mProps) => {
    let sep = mProps.parentManagedComponentId ? "_" : "";
    let guid = createGuid();

    return {
        ...DefaultManagedProps,
        ...mProps,
        guid: guid,
        managedComponentId: `${mProps.parentManagedComponentId}${sep}${mProps.componentId}_${guid}`
    };
}

export const createMProps = (componentId) => {
    return createManagedProps({
        ...DefaultManagedProps,
        componentId: componentId
    });
};

export class ClUIManager {
    _clients = {};
    _data = [];

    get Components() {
        return this._data.map(x => {
            return {
                string: JSON.stringify(x),
                object: x
            };
        });
    }

    _UpdateClients() {
        Object.values(this._clients).forEach(x => {
            x(this.Components);
        });
    };

    Add(obj) {
        this._data.push(obj);
        this._UpdateClients();
    }

    Remove(id) {
        this._data = this._data.filter(x => x.managedComponentId != id);
        this._UpdateClients();
    }

    Subscribe(componentId, delegate) {
        this._clients[componentId] = delegate;
    }

    UnSubscribe(componentId) {
        if (this._clients[componentId]) {
            delete this._clients[componentId];
        }
    }
}