import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';

export default function RowDetailsNARS(props) {
  const data = props.row
  const row = props.otherData
  
    return (
      <React.Fragment>
          <Box margin={1}>
          {(row.recallMethod === "Trigger - Recall Date") && 
            <>
              <Typography variant="h6" gutterBottom component="div">
                Recall Details
              </Typography>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Recall Date</TableCell>                  
                    <TableCell>Visit Date</TableCell>
                    <TableCell>Visit Type</TableCell>
                    <TableCell>Visit Reason</TableCell>    
                    <TableCell>Last Visit/Exam Date</TableCell>                                      
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell>{data.recallDate ? moment(data.recallDate).format('MM/DD/YYYY - hh:mm A') : ""}</TableCell>                    
                      <TableCell>{data.visitDate ? moment(data.visitDate).format('MM/DD/YYYY - hh:mm A') : ""}</TableCell>
                      <TableCell>{data.visitType}</TableCell>    
                      <TableCell>{data.visitReason}</TableCell>  
                      <TableCell>{data.lastVisitOrExamDate  ? moment(data.lastVisitOrExamDate).format('MM/DD/YYYY - hh:mm A') : "" }</TableCell>                                                            
                    </TableRow>              
                </TableBody>
              </Table>  
              </>
          }
          {(row.recallMethod !== "Trigger - Recall Date") && 
            <>
            <Typography variant="h6" gutterBottom component="div">
              Appointment Details
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>Recall Period</TableCell>  
                  <TableCell>Appt Status</TableCell>                  
                  <TableCell>Appt Reason</TableCell>
                  <TableCell>Appt Date</TableCell>
                  <TableCell>Appt Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{row.recallPeriod}</TableCell>                      
                    <TableCell>{data.apptStatus}</TableCell>                    
                    <TableCell>{data.apptReason}</TableCell>
                    <TableCell>{data.startDate ? moment(data.startDate).format('MM/DD/YYYY - hh:mm A') : ""}</TableCell>
                    <TableCell>{data.apptType}</TableCell>                  
                  </TableRow>              
              </TableBody>
            </Table>          
            </>
          }            
            <Typography variant="h6" gutterBottom component="div">
              Patient Details
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Primary Phone</TableCell>
                  <TableCell>Cellphone</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.firstName}</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                    <TableCell>{data.primaryPhone}</TableCell>     
                    <TableCell>{data.cellPhone}</TableCell> 
                    <TableCell>{data.email}</TableCell>                                                  
                  </TableRow>                
              </TableBody>
            </Table>
  
            <Typography variant="h6" gutterBottom component="div">
              Communication Preferences
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>SMS</TableCell>
                  <TableCell>Voice</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell>{data.reminderSMSPreference === "1" ? "Yes" : "No"}</TableCell>
                    <TableCell>{data.reminderVoicePreference === "1" ? "Yes" : "No"}</TableCell>
                    <TableCell>{data.reminderEmailPreference === "1" ? "Yes" : "No"}</TableCell>                                                      
                  </TableRow>                
              </TableBody>
            </Table>     
  
            <Typography variant="h6" gutterBottom component="div">
              Provider Details
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>LocKey</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Provider Lastname</TableCell>
                  <TableCell>Provider Firstname</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell>{data.locKey}</TableCell>
                  <TableCell>{data.locationName}</TableCell>
                  <TableCell>{data.providerLastName}</TableCell>
                  <TableCell>{data.providerFirstName}</TableCell>                                                      
                  </TableRow>                
              </TableBody>
            </Table>         
            <br/>
  
          </Box>      
       </React.Fragment>
    );   

}