import React from 'react';
import axios from "axios";

const reqResult = {
    data: null,
    success: true,
    statusCode: null,
    cancelled: false,
    error: "",
}

export default class ClDataCore {
    async _Request(method, url, data, cancelToken) {
        try {
            let res = null;
    
            if (method == "get") {
                let p = "";
                
                if (data) {
                    p = Object.keys(data).map(k => {
                        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                    }).join('&');
    
                    if (p) url += (url.indexOf("?") >= 0 ? "&" : "?") + p;
                }
    
                res = await axios.get(url, {
                    cancelToken: cancelToken
                });
    
            } else if (method == "post") {
                res = await axios.post(url, data, {
                    cancelToken: cancelToken
                });
            }
            
            if (res && (res?.status === 200 || res?.status === 201 || res?.status === 204)) {
                return {...reqResult, data: res.data, statusCode: res.status};
            } else {
                return {...reqResult, data: res?.data, success: false, statusCode: res?.status, 
                    error: "An error occured while processing the request." + (res?.status ? " (" + res?.status + ")" : "")};
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                return {...reqResult, success: false, cancelled: true, error: "The request was cancelled. " + e.message}; 
            } else {
                return {...reqResult, success: false, error: "An error occured while processing the request. " + e.message};
            }
        }
    }
}


