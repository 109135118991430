import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import Utils from '../../utils';
import './PracticeDetails.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function PracticeDetails(props) {
  const classes = useStyles();
  const [collapsePractice, setCollapsePractice] = React.useState(false);
  
  const _handleCollapsePractice = () => {
    setCollapsePractice(!collapsePractice);
  };

  const minDate = "01/01/0001";
  let installDate = moment(props.data.installationInfo.scheduledInstallation).format('MM/DD/YYYY');
  let installTime = moment(props.data.installationInfo.scheduledInstallation).format('LT');
  let installTimeZone = props.data.installationInfo.localTimeZone
  if (minDate === installDate) {
    installDate = '';
    installTime = '';
    installTimeZone = '';
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      p={0}
    >
      <ListItem >
      <ListItemText onClick={_handleCollapsePractice}>
        <span className="listHeader"><b>{props.headerName}</b></span>
      </ListItemText>
        {collapsePractice ? <ExpandLess onClick={_handleCollapsePractice} /> : <ExpandMore onClick={_handleCollapsePractice} />}
      </ListItem>
      <Divider/>
      <Collapse in={collapsePractice} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem  button className={classes.nested}>
              <ListItemText >
                <div className="row">
                  <div className="column">
                    <div><b>Practice Name: </b> {props.data.practiceName}</div>
                    <div><b>PracticeWebsite: </b> {props.data.practiceWebsite}</div>
                    <div><b>Primary Contact: First Name </b> {props.data.primaryContact.firstName}</div>
                    <div><b>Primary Contact: Last Name </b> {props.data.primaryContact.lastName}</div>
                    <div><b>Primary Contact: Phone </b> {Utils.maskPhone(props.data.primaryContact.phone)}</div>
                  </div>
                  <div className="column">
                    <div><b>Primary Contact: Email </b> {props.data.primaryContact.email}</div>
                    <div><b>Existing CRM: </b> {props.data.primaryContact.HasExistingCRM ? 'Yes' : 'None'}</div>
                    <div><b>Installation Date: </b> {installDate}</div>
                    <div><b>Installation Time: </b> {installTime} {installTimeZone === '' ? '' : `(${installTimeZone})`}</div>
                  </div>
                </div>
                </ListItemText>
          </ListItem>
        </List>
        {/* <div className="clsButton">
          <Box p={2} bgcolor="background.paper">
            <Button fontSize={16} variant="outlined">VIEW PRACTICE DETAILS</Button>
          </Box>
        </div> */}
    </Collapse>
    <Divider/>
    </List>
  );
}