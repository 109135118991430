import React from "react";
import Box from '@material-ui/core/Box';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    let active = value === index;
  
    if (active) {
      return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
    } else {
      return null;
    }
  }