import React, { memo } from 'react';
import './LoginHeader.scss';
import MainLogo from '../../Assets/Images/4patient_logo-trans_New.png';

/**
 * Represents the header for the login pages.
 */
const LoginHeader = memo(() => {

  return (
    <div className="login-header">
      <div className="login-header__logo-cont">
        <img
          alt="Site Logo"
          src={MainLogo} />
      </div>
    </div> 
  );
});

export default LoginHeader;
