import React, { useContext, useState } from 'react';
import PatientInfo from './PatientInfo';
import InsuranceTypes from './InsuranceTypes';
import AppointmentTypes from './AppointmentTypes';
import Schedule from './Calendar/Schedule';
import General from "./General";
import Filters from '../../ContextLib/CoreConsumer/Components/Filters';
import "./WSMaster.scss";
import { Box, Divider, Typography } from '@material-ui/core';
import { useFiltersContext } from '../../ContextLib/contextHooks';

const SELECT_COVER_MESSAGE = "Please select a Cover";

export default function WSMaster(props) {
    const componentId = "wsmaster";
    const filtersContext = useFiltersContext(componentId, (context) => {
        //On filters changed
        let m = "";
        let lacking = false;

        if (context.coverKey == 0) {
            m = SELECT_COVER_MESSAGE;
        } else if (context.locations.length == 0) {
            m = "The selected Cover has no Location";
            lacking=true;
        } else if (context.providers.length == 0) {
            m = "The selected Cover has no Provider";
            lacking=true;
        }

        setMsg(m);
        setLackingParameter(lacking);
        setCoverKey(context.coverKey);
    });

    const [coverKey, setCoverKey] = useState(filtersContext.Values.coverKey);
    const [msg, setMsg] = useState(SELECT_COVER_MESSAGE);
    const [lackingParameter, setLackingParameter] = useState(false);

    return (
        <div className="ws-master">
                <div className="ws-header">
                    <Filters />
                </div>
                <div className="ws-body">
                    {coverKey==0 || lackingParameter? 
                        <>
                            <div className="reporting-box">
                                <Box p={2} bgcolor="background.paper" className="page-header"> 
                                    <Typography variant="h5">
                                        {props.menu == 11 && "Appointment Types"}
                                        {props.menu == 12 && "Insurance Types"}
                                        {props.menu == 13 && "Patient Information"}
                                        {props.menu == 16 && "Schedule"}
                                        {props.menu == 14 && "General"}
                                    </Typography>
                                </Box>
                            </div>
                            <Divider />
                            <div className="select-cover-message">
                                <h2>{msg}</h2>
                            </div>                    
                            <hr/>
                        </>
                    :
                    <>
                        {props.menu === 11 && <AppointmentTypes />}
                        {props.menu === 12 && <InsuranceTypes />}
                        {props.menu === 13 && <PatientInfo />}
                        {props.menu === 16 && <Schedule />}
                        {props.menu === 14 && <General />}
                    </>
                    }                    
                </div>
        </div>
    );
}