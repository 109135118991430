import axios from 'axios';
import Storage from './storage';
import Constants from './constants';

(() => {
  // Setup axios to pass the jwt for each request.
  axios.interceptors.request.use((config) => {
    const currUser = Storage.getItem(Constants.currUserKey);

    if (currUser && currUser.jwt) {
      config.headers['Authorization'] = `Bearer ${currUser.jwt}`;
    }

    return config;
  });
})();
