import React, { useState, useEffect, useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import './Schedule.scss';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useFiltersContext, useRequest, useUIContext } from '../../../ContextLib/contextHooks';
import ValidatedDatePicker from '../../../Components/DatePicker/ValidatedDatePicker';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Severity } from '../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { RepeatType } from './process';
import InfoIcon from '@material-ui/icons/Info';

import { arraysAreEqual, arraySort } from '../../../ContextLib/Core/coreLib';

const AllTypes = "(All Types)";
const AllTypesObj = { taskKey: -1, appointmentType: AllTypes, length: 0 };

const defaultRepetitionData = {
    days: [false, true, true, true, true, true, false],
    repeats: "weekly",
    ends: "never",
    endsDate: new Date().toLocaleDateString()
}

const defaultData = {
    startDate: new Date().toLocaleDateString(),
    startTime: "09:00",
    endTime: "18:00",
    breakPeriodList: [{startTime: '12:00', endTime: '13:00'}],
    repetition: defaultRepetitionData,
    anyAppointment: true,
    exemptions: [],
    simulataneous: 1
}
    
const isValidDays = (_days) => {
    return _days.find((x)=>x) ? true : false;
}

const timeStr = (s) => {
    s = s.trim().toLowerCase();
    if (s.indexOf("am") >= 0 || s.indexOf("pm") >= 0) {
        let t = s.split(" ")[0];
        let tt = s.split(" ")[1];
        let h = t.split(":")[0];
        let m = t.split(":")[1];
        
        let _h = parseInt(h);
        if (tt == "pm") _h += 12;
        h = (_h < 10 ? "0" : "") + _h;

        return `${h}:${m}`;
    } else {
        return s;
    }
}

const sortList = (src) => {
    return arraySort(src, "appointmentType");
}

const getAppointmentTypeOptions = (src) => {
    let a = [];
    if (src.length > 0) {
        const b = src.filter((x) => x != AllTypesObj);
        if (b.length > 0) a = [AllTypesObj, ...sortList(b)];
    }
    return a;
}

const BlocksForm=({appointmentTypesData, onSaved, isAvailability, loadData})=>{
    let componentId = "blocksForm";
    const ui = useUIContext(componentId);
    const Request = useRequest();

    const filtersContext = useFiltersContext(componentId);  
      
    const [startDate, setStartDate] = useState(defaultData.startDate);
    const [startTime, setStartTime] = useState(defaultData.startTime);
    const [endTime, setEndTime] = useState(defaultData.endTime);
    const [breakPeriodList, setBreakPeriodList] = useState(defaultData.breakPeriodList);
    const [exemptions, setExemptions] = useState(defaultData.exemptions);

    const [anyAppointment, setAnyAppointment] = useState(defaultData.anyAppointment);

    const appointmentTypeOptionsInitial = getAppointmentTypeOptions(appointmentTypesData);

    const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState(sortList(appointmentTypesData));

    const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([]);
    const [showValidationMsgAT, setShowValidationMsgAT] = useState(false);
    
    const [timesValidationErrors, setTimesValidationErrors] = useState([]);
    const [bpValidationErrors, setBpValidationErrors] = useState([]);
            
    const [repetition, setRepetition] = useState(defaultRepetitionData);
    const [_clearRepetition, setClearRepetition]=useState(new Date());
    const [_validateRepetition, setValidateRepetition]=useState(new Date());
    
    const [loadConfigData, setLoadConfigData] = useState(loadData);
    const [loadRepetitionData, setLoadRepetitionData] = useState();


    const [endsDt, setEndsDt] = useState(defaultRepetitionData.endsDate);

    const [editSingleEvent, setEditSingleEvent] = useState(null);
    const [simultaneous, setSimultaneous] = useState(defaultData.simulataneous);
            
    const onCreateBlocks = async () => {
        let validated = validateTime() && validateBreakPeriodList() && validateAppointmentTypeList() && validateRepetition();

        if (!validated) return;

        ui.ShowOverlay();

        const data = getFormData();

        if (loadConfigData) {
            const res = await Request.updateWSAvailability(data);
            if (res.success) {
                ui.ShowSnackbar(`${isAvailability ? "Availability" : "Blackout"} was successfully updated`);
                onSaved();
            } else {
                ui.ShowSnackbar(res.error, Severity.error);
            }
        } else {
            const res = await Request.createWSAvailability(data);
            if (res.success) {
                ui.ShowSnackbar(`${isAvailability ? "Availability" : "Blackout"} was successfully created`);
                onSaved();
            } else {
                ui.ShowSnackbar(res.error, Severity.error);
            }
        }
        
        ui.HideOverlay();
    }

    const getFormData = ()=> {
        return {
            coverKey: filtersContext.Values.coverKey,
            locKey: filtersContext.Values.locKey,
            acctKey: filtersContext.Values.acctKey,
            groupId: loadConfigData?.groupId,
            data: JSON.stringify({
                startDate: startDate,
                startTime: startTime,
                endTime: endTime,
                anyAppointment: isAvailability ? anyAppointment : true,
                appointmentTypeList: isAvailability ? selectedAppointmentTypes.map((x)=> { return { name: x.appointmentType, taskKey: x.taskKey, length: x.length }; }) : [],
                breakPeriodList: isAvailability ? breakPeriodList : [],
                repetition: repetition,
                isBlackout: !isAvailability,
                exemptions: exemptions,
                editSingleEvent: editSingleEvent?.toLocaleDateString(),
                simultaneous: simultaneous
            })
        }
    }

    const onAddBreakPeriod = () => {
        setBreakPeriodList([...breakPeriodList, {startTime: '', endTime: ''}]);
    }

    const onDeleteBreakPeriod = index => {
        const list = [...breakPeriodList];
        list.splice(index, 1);
        setBreakPeriodList(list);
    }

    const handBreakPeriodChange = (e, index) => {
        const { name, value } = e.target;

        const list = [...breakPeriodList];
        list[index][name] = value;

        setBreakPeriodList(list);
    }

    const validateRepetition = () => {
        setValidateRepetition(new Date());
        return isValidDays(repetition.days ?? repetition.Days);
    }
    
    const validateTime = () => {
        let errors = [];
        
        if (startTime === '' || endTime === '') errors.push(`Start/End time not specified`);
        if (getDateTime(startTime) > getDateTime(endTime)) errors.push(`Start time cannot be later than End time`);
        if (checkAvailabilityTime(startTime, endTime)) errors.push(`Please select availability between 6am and 11 pm only.`);
                
        setTimesValidationErrors(errors);
        return errors.length == 0;        
    }
    const checkAvailabilityTime = (startTime, endTime) => {
        let returnResult = false;
        let checkerStartTime = parseInt(startTime);
        let checkerEndTime = parseInt(endTime);
        if (checkerEndTime == 23) {
            if (endTime != "23:00") {
                returnResult = true;
            }
        }
        for (let i = 1;i <= 5;i++) {
            if (checkerStartTime == i) {
                returnResult = true;
            }
        }
        if (checkerEndTime == 0) {
            returnResult = true;
        }

        let result = checkerStartTime
        return returnResult;
    }

    const validateBreakPeriodList = () => {
        if (!isAvailability) return true;

        let errors = [];
        
        breakPeriodList.forEach((item, _i) => {
            let i = _i + 1;
            if (item.startTime === '' || item.endTime === '') errors.push(`Break #${i}: Start/End time not specified`);
            if (getDateTime(item.startTime) > getDateTime(item.endTime)) errors.push(`Break #${i}: Start time cannot be later than End time`);

            if (
                !(getDateTime(item.startTime) >= getDateTime(startTime, 30) && getDateTime(item.endTime) <= getDateTime(endTime, -30))
            ) errors.push(`Break #${i}: Breaks must be within availability times`);
        })
        
        setBpValidationErrors(errors);
        return errors.length == 0;        
    }

    const getDateTime = (stringTime, addSubtractMinutes) => {
        if (addSubtractMinutes == null) addSubtractMinutes = 0;

        let dt = new Date(startDate);
        dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), stringTime.substring(0,2), stringTime.substring(3,5), 0);
        return new Date(dt.getTime() + (addSubtractMinutes*60000));
    }

    const onAddAppointmentType = (selectedValue, reason) => {
        
        if (reason === 'select-option') {
            let a = [];
            let b = [];

            if (selectedValue.appointmentType == AllTypes) {
                a = sortList(appointmentTypesData);
                b = [];
            } else {
                a = sortList([...selectedAppointmentTypes, selectedValue]);
                b = getAppointmentTypeOptions(appointmentTypeOptions.filter((x) => x.appointmentType != selectedValue.appointmentType));
            }    

            setSelectedAppointmentTypes(a);
            setAppointmentTypeOptions(b);

            setShowValidationMsgAT(false);
        }
    }

    const onDeleteAppointmentType = (item, all) => {
        let a = [];
        let b = [];

        if (all) {
            b = [...appointmentTypeOptionsInitial];
        } else {
            a = selectedAppointmentTypes.filter((x) => x.appointmentType != item.appointmentType);
            b = getAppointmentTypeOptions([...appointmentTypeOptions, item]);    
        }        

        setSelectedAppointmentTypes(a);
        setAppointmentTypeOptions(b);
    }

    const validateAppointmentTypeList = () => {
        if (!isAvailability) return true;

        let returnFlag = true;
        if (selectedAppointmentTypes.length === 0) returnFlag = false;

        if (!returnFlag) setShowValidationMsgAT(true);
        if (returnFlag) setShowValidationMsgAT(false);
        
        return returnFlag;
    }

    const clearForm = () => {
        setLoadConfigData(null);

        setStartDate(defaultData.startDate)
        setStartTime(defaultData.startTime);
        setEndTime(defaultData.endTime);
        setBreakPeriodList(isAvailability ? defaultData.breakPeriodList : []);
        
        setClearRepetition(new Date());
        
        setSelectedAppointmentTypes(sortList(appointmentTypesData));
        setAppointmentTypeOptions([]);
        setShowValidationMsgAT(false);

        setExemptions(defaultData.exemptions);
        setSimultaneous(defaultData.simulataneous);
    }

    const loadForm = (data, editSingleEvent) => {
        const brk = isAvailability ? data.BreakPeriodList.map((x)=> { return { startTime: x.StartTime, endTime: x.EndTime }; }) : [];
        const appt = isAvailability ? sortList(data.AppointmentTypeList.map((x) => { return { appointmentType: x.Name, taskKey: x.TaskKey, length: x.Length }; })) : [];
        const opts = getAppointmentTypeOptions(appointmentTypesData.filter((x) => !appt.find((y)=> y.taskKey == x.taskKey)));

        setStartDate(data.StartDate)
        setStartTime(timeStr(data.StartTime));
        setEndTime(timeStr(data.EndTime));
        setBreakPeriodList(brk);
        setSelectedAppointmentTypes(appt);                
        setAppointmentTypeOptions(opts);
        setShowValidationMsgAT(false);
        setLoadRepetitionData(data.Repetition);
        setRepetition(data.Repetition);

        setExemptions(editSingleEvent ? [] : data.Exemptions);
        setEditSingleEvent(editSingleEvent);

        setSimultaneous(data.Simultaneous);
    }

    const handleRepetitionChange = (data) => {
        setRepetition(data);
    }

    const removeExemption = (dispValue) => {
        const dt = new Date(dispValue.split("-")[0]);
        const newEx = exemptions.filter((x) => new Date(x.StartDate).toLocaleDateString() != dt.toLocaleDateString());
        setExemptions(newEx);
    }

    useEffect(()=>{        
        if (loadConfigData) loadForm(JSON.parse(loadConfigData.config.data), loadConfigData.editSingleEvent);
    },[loadConfigData]);

    useEffect(()=> {
        const a = appointmentTypesData.map((x) => { return { taskKey: x.taskKey }; });
        const b = selectedAppointmentTypes.map((x) => { return { taskKey: x.taskKey }; });
        const c = arraysAreEqual(a, b, "taskKey");
        setAnyAppointment(c);
    });
    
    return (
        <>
            <Typography variant="h6">
                {`${loadConfigData ? "Edit" : "Create"} ${isAvailability ? "Availability" : "Blackout"}`}
            </Typography>
            <Divider />
            <ValidatorForm onSubmit={onCreateBlocks} onError={errors => console.log(errors)} > 
                <Grid container direction="row" justify="flex-start"  alignItems="center" spacing={3} className="grid-drawer">                
                    <Grid item xs={12}>
                        <InputLabel className="pageLabel" shrink>Select Start Date</InputLabel>
                        {editSingleEvent ? (<>
                            <TextField 
                                value={new Date(startDate).toLocaleDateString()} 
                                fullWidth 
                                variant="filled"
                                InputProps={{
                                    readOnly: true,
                                }}
                             />
                        </>) : (<>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ValidatedDatePicker
                                    autoComplete="off"
                                    margin="none"
                                    id="from-date-dialog"
                                    format="MM/dd/yyyy"
                                    validators={["required"]}
                                    errorMessages={["Start date is required"]}
                                    fullWidth
                                    minDate={new Date()}
                                    value={startDate}
                                    onChange={(value) => {
                                        const v = value.toLocaleDateString();
                                        setStartDate(v);
                                        if (value > new Date(repetition.endsDate)) {
                                            repetition.endsDate = v;
                                            setRepetition(repetition);
                                            setEndsDt(v);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment>
                                            <DateRangeIcon/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </>)}                        
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>Select Start Time</InputLabel>
                        <TextField
                            id="startTime"
                            type="time"
                            value={startTime}
                            onChange={e => {setStartTime(e.target.value)}}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>Select End Time</InputLabel>
                        <TextField
                            id="endTime"
                            type="time"
                            value={endTime}
                            onChange={e => {setEndTime(e.target.value)}}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                        />
                    </Grid>
                    {timesValidationErrors.map((x) => <div className="validator-message">{x}</div>)}

                    {isAvailability && (<>
                        { breakPeriodList.length > 0 && (<>
                            {breakPeriodList.map((item, i) => {
                                return(<>
                                    { i === 0 &&  <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Add Break Period(s)</InputLabel>}
                                    <Grid key={i} container item style={{paddingTop: 0}}>
                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="startTime"
                                            value={item.startTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                        />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="endTime"
                                            value={item.endTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                            />
                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton  style={{padding: 4}} type="submit" component="span" onClick={() => onDeleteBreakPeriod(i)}>
                                                <HighlightOffIcon fontSize="small"  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>                                
                                </>)                    
                            })}
                            
                            {bpValidationErrors.map((x) => <div className="validator-message">{x}</div>)}
                        </>)
                        }                    

                        <Grid item xs={12}>
                            { breakPeriodList.length === 0 && <InputLabel className="pageLabel" shrink>Add Break Period(s)</InputLabel> }
                            <TextField
                                disabled
                                placeholder="Add Break Period"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                    <IconButton onClick={onAddBreakPeriod}>
                                        <InputAdornment>
                                            <AddCircleOutlineIcon/>
                                        </InputAdornment>
                                    </IconButton>
                                    )}}
                            />
                        </Grid>
                    
                        {isAvailability && (
                         <>
                            <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Add Appointment Type(s)</InputLabel>
                            { selectedAppointmentTypes.length > 0 && (<>
                                {anyAppointment ? (<>
                                    <Grid key={0} container item style={{paddingTop: 0}}>
                                        <Grid item xs={10}>
                                            <TextField
                                            fullWidth
                                            disabled
                                            value={AllTypes}
                                        />
                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton  style={{padding: 4}} type="submit" component="span" onClick={() => onDeleteAppointmentType(null, true)}>
                                                <HighlightOffIcon fontSize="small"  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </>) : (<>
                                    {selectedAppointmentTypes.map((item, i) => {
                                        return(
                                        <>
                                        <Grid key={i} container item style={{paddingTop: 0}}>
                                            <Grid item xs={10}>
                                                <TextField
                                                fullWidth
                                                disabled
                                                value={item.appointmentType}
                                            />
                                            </Grid>

                                            <Grid item xs={1}>
                                                <IconButton  style={{padding: 4}} type="submit" component="span" onClick={() => onDeleteAppointmentType(item)}>
                                                    <HighlightOffIcon fontSize="small"  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        </>
                                        )
                                    })}
                                </>)}                            
                            </>)}
                        
                            {!anyAppointment && (<>
                                <Grid item xs={12} className="grid-autocomplete">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                                disableClearable
                                                clearOnBlur
                                                clearOnEscape
                                                id="autoAppointmentType"
                                                inputValue=''
                                                value={null}
                                                options={appointmentTypeOptions}
                                                getOptionLabel={(x) => x.appointmentType}
                                                onChange={(event, newValue, reason) => {
                                                    onAddAppointmentType(newValue, reason);
                                                }}
                                                renderInput={(params) => <TextField {...params} placeholder="Add Appointment Type"/>}
                                            />
                                    </FormControl>
                                    {showValidationMsgAT && <div className="validator-message">Appointment Type is required</div> }
                                </Grid>
                            </>)}
                        </>)}
                    </>)}

                    {isAvailability && (<>
                        <Grid item xs={12}>
                            <InputLabel className="pageLabel" shrink>Allow Simultaneous Appointments</InputLabel>
                            <TextField
                                id="simultaneous"
                                type="number"
                                value={simultaneous}
                                onChange={e => {setSimultaneous(e.target.value)}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                    required: true
                                }}
                            />
                        </Grid>                    
                    </>)}

                    <Grid item xs={12}>
                        <Repetition endsDt={endsDt} clear={_clearRepetition} validate={_validateRepetition} onChange={handleRepetitionChange} 
                            loadData={loadRepetitionData} readOnly={editSingleEvent} />
                    </Grid>

                    {exemptions.length > 0 && (<>
                        <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Exemptions</InputLabel>
                        <Grid item xs={12}>
                            <ul>
                                {exemptions.map((x)=> { return (<li>{x.DisplayValue}  [<a href="#" onClick={(e)=>removeExemption(x.DisplayValue)}>remove</a>]</li>)})}
                            </ul>
                        </Grid>
                    </>)}

                    {editSingleEvent && 
                        <Grid item xs={12}>
                            <div className="update-single-event-notification"><InfoIcon /> Updating Single Event ({editSingleEvent.toLocaleDateString()})</div>
                        </Grid>
                    }

                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                {loadConfigData ? "UPDATE" : "CREATE"}
                        </Button>  
                    </Grid>

                    {!loadConfigData && (<>
                        <Grid item xs={6}>
                            <Button variant="contained" type="reset" fullWidth onClick={clearForm}>
                                    RESET FORM
                            </Button>  
                        </Grid>
                    </>)}
                </Grid>  
            </ValidatorForm>
        </>
    )
}

export default BlocksForm;


const Repetition =({endsDt, clear, validate, onChange, loadData, readOnly})=> {

    const [days, setDays] = useState(defaultRepetitionData.days);
    const [repeats, setRepeats] = useState(defaultRepetitionData.repeats)
    const [ends, setEnds] = useState(defaultRepetitionData.ends);
    const [endsDate, setEndsDate] = useState(defaultRepetitionData.endsDate);

    const [daysValidationMessage, setDaysValidationMessage] = useState("");

    const validateDays = (_days) => {
        const msg = isValidDays(_days) ? "" : "Select at least one (1) day";
        setDaysValidationMessage(msg);
    }

    const daysChanged = (index, checked)=> {
        let d = [...days];
        d[index] = checked;

        validateDays(d);

        setDays(d);
        handleChange({days: d});
    }

    const clearForm=()=> {
        setDays(defaultRepetitionData.days);
        setRepeats(defaultRepetitionData.repeats);
        setEnds(defaultRepetitionData.ends);
        setEndsDate(endsDt);
    }

    const loadForm=()=> {
        setDays(loadData.Days);
        setRepeats(loadData.Repeats);
        setEnds(loadData.Ends);
        setEndsDate(loadData.EndsDate);
    }

    const handleChange = (value) => {
        onChange({
            ...{
                days: days,
                repeats: repeats,
                ends: ends,
                endsDate: endsDate
            },
            ...value
        });
    }

    useEffect(()=>{
        setEndsDate(endsDt);
        handleChange({endsDate: endsDt});
    }, [endsDt]);

    useEffect(()=>{
        handleChange({endsDate: endsDate});
    }, [endsDate]);

    useEffect(()=>{
        clearForm();
        validateDays(defaultRepetitionData.days);
    }, [clear]);  
    
    useEffect(()=>{
        validateDays(days);
    }, [validate]);  

    useEffect(()=>{
        if (loadData) loadForm();
    }, [loadData]);  

    return (<div className="repetition">
        <h4>Repetition</h4>
        <Grid container>
            <Grid item sm={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Days</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[0]} onChange={(event, checked) => daysChanged(0, checked)} name="days" color="primary" />}
                            label="Sun"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[1]} onChange={(event, checked) => daysChanged(1, checked)} name="days" color="primary" />}
                            label="Mon"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[2]} onChange={(event, checked) => daysChanged(2, checked)} name="days" color="primary" />}
                            label="Tue"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[3]} onChange={(event, checked) => daysChanged(3, checked)} name="days" color="primary" />}
                            label="Wed"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[4]} onChange={(event, checked) => daysChanged(4, checked)} name="days" color="primary" />}
                            label="Thu"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[5]} onChange={(event, checked) => daysChanged(5, checked)} name="days" color="primary" />}
                            label="Fri"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[6]} onChange={(event, checked) => daysChanged(6, checked)} name="days" color="primary" />}
                            label="Sat"
                        />
                    </FormGroup>
                    {daysValidationMessage && <div className="validator-message">{daysValidationMessage}</div> }
                </FormControl>
            </Grid>            
        </Grid>
        <Grid container>
            <Grid item sm={12}>
                <FormControl>
                    <InputLabel>Repeats</InputLabel>
                    <Select
                        disabled={readOnly}
                        value={repeats}
                        onChange={(e)=> {
                            setRepeats(e.target.value);   
                            handleChange({repeats: e.target.value});                         
                        }}
                    >
                        <MenuItem value={RepeatType.Once} key={RepeatType.Once}>Once</MenuItem>
                        <MenuItem value={RepeatType.Weekly} key={RepeatType.Weekly}>Weekly</MenuItem>
                        <MenuItem value={RepeatType.BiWeekly} key={RepeatType.BiWeekly}>Bi-Weekly</MenuItem>
                        <MenuItem value={RepeatType.Monthly} key={RepeatType.Monthly}>Monthly</MenuItem>
                    </Select>
                </FormControl>
            </Grid>            
        </Grid>
        <Grid container>
            <Grid item sm={12}>
                <FormControl>
                    <InputLabel>Ends</InputLabel>
                    <Select
                        disabled={readOnly}
                        value={ends}
                        onChange={(e)=> {
                            setEnds(e.target.value);
                            handleChange({
                                ends: e.target.value,
                                endsDate: endsDt
                            });
                        }}
                        inputProps={{style:{width: "100%"}}}
                    >
                        <MenuItem value={"never"} key={"never"}>Never</MenuItem>
                        <MenuItem value={"date"} key={"date"}>Specific Date</MenuItem>
                    </Select>
                    <br />
                    {ends == "date" && 
                        (<>
                            <InputLabel className="pageLabel" shrink>Ends On</InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ValidatedDatePicker
                                    disabled={readOnly}
                                    autoComplete="off"
                                    margin="none"
                                    format="MM/dd/yyyy"
                                    validators={["required"]}
                                    errorMessages={["Ends On date is required"]}
                                    fullWidth
                                    minDate={new Date(endsDate)}
                                    value={new Date(endsDate)}
                                    onChange={(value) => {
                                        const v = value.toLocaleDateString();
                                        setEndsDate(v);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment>
                                            <DateRangeIcon/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </>)
                    }                    
                </FormControl>
                
            </Grid>
        </Grid>
    </div>)
}