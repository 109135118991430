import { useContext, useEffect } from 'react';
import FiltersContext from './CoreConsumer/filtersContext';
import UIContext, {ClUIClient} from './CoreConsumer/uiContext';
import {ClDataRequestObject} from './CoreConsumer/dataRequest';
import axios from 'axios';

export function useUIContext(componentId) {
    const uiContext = useContext(UIContext);

    return new ClUIClient(uiContext, componentId);
}

export function useFiltersContext(componentId, filtersChangedDelegate, valuesUpdatedDelegate) {
    const context = useContext(FiltersContext);

    context.Subscribe_FiltersChanged(componentId, filtersChangedDelegate);
    context.Subscribe_ValuesUpdated(componentId, valuesUpdatedDelegate);

    useEffect(()=> {
        filtersChangedDelegate && filtersChangedDelegate(context.Values);

        return ()=> {
            context.UnSubscribe_FiltersChanged(componentId);   
            context.UnSubscribe_ValuesUpdated(componentId);     
        }
    }, []);

    return context;
}

export function useRequest() {
    const source = axios.CancelToken.source();

    const dro = new ClDataRequestObject(source.token);

    useEffect(()=> {        
        return ()=> {
            source.cancel();    
        }
    }, []);

    return dro.Request;
}