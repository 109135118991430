import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import TextField from '@material-ui/core/TextField';
import Enums from '../../enums';
import Utils from '../../utils';
import Storage from '../../storage';
import Constants from '../../constants';
import Overlay from '../../Components/Overlay/Overlay';
import { Link } from 'react-router-dom';
import './Forgot.scss';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { string } from 'yargs';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import MainLogo from '../../Assets/Images/4patient_logo-trans_New.png';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function Forgot() {
    const classes = useStyles();
    const [userName, setUserName] = useState();
    const [link, setLink] = useState();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [SnackbarMessage, setSnackbarMessage] = useState('');
    const [errorSeverity, setErrorSeverity] = useState('success');


    const _onSubmit = async () => {
        if (email === "") {
            setEmailError("Email is required");
        }
        else {
        
            const systemDate = moment().format('MM/DD/YYYY HH:mm:ss');
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            const takData = { UserName: userName, Email: email, EmailLink: link,LinkDate:new Date(systemDate) };
            const url = `${process.env.REACT_APP_ADMIN_API}/sendForgotPasswordLink?secretCode=${apiKey}`;
            const res = await axios.post(url, takData)
                .then(response => {
                    let strJwt = response.data

                    if (strJwt.toString() === "") {

                        setEmailError("Email Id is not registered for an account");
                    }
                    else {
                        setEmailError("");
                        setErrorSeverity("success");
                        setOpenSnackBar(true);
                        setEmail("");
                    }
                })

        }




    };
    const EmailInput = async (email) => {
        setEmail(email);
    }

    return (
        <>
            <ValidatorForm onSubmit={_onSubmit} onError={errors => console.log(errors)}>
                <div className="login">
                    <section className="login__header">

                        <div className="login-header">
                            <div className="login-header__logo-cont">
                                <img
                                    alt="Site Logo"
                                    src={MainLogo} />
                            </div>
                        </div>

                    </section>
                    <section className="login__content">
                        <div className="login-norm">

                            <h1 className="login-norm__title">{'4PC Admin Page'}</h1>
                            <h3 className="login-norm__subtitle">{'Forgot Password'}</h3>
                            <form className="login-norm__form">
                                <TextField
                                    className="login-norm__input"
                                    inputProps={{
                                        style: {
                                            padding: '12px 14px'
                                        }
                                    }}
                                    id="outlined-password-input"
                                    value={email}
                                    label="Email"
                                    type="email"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    onInput={(e) => EmailInput(e.target.value)}
                                />

                                <span className={`login-norm__error login-norm__error--no-margin ${emailError ? 'login-norm__error--visible' : emailError}`}>{emailError}</span>
                                <p />
                                <p />
                                <button
                                    className="login-norm__forgot"
                                    disabled={false}
                                    type="submit"
                                >
                                    {'Submit'}
                                </button>
                            </form>

                            <Overlay show={false}>
                                <i className="spinner-eclipse"></i>
                            </Overlay>
                        </div>
                    </section>
                </div>
            </ValidatorForm>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={10000} onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="info">
                    Link has been sent to you email account.Please click the link to change your password.
                </Alert>
            </Snackbar>
        </>
    );
}
// export default Forgot