import React, { useEffect, useState } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider } from "@material-ui/core";
import { useRequest, useUIContext } from '../../../ContextLib/contextHooks';
import { Severity } from '../../../ContextLib/CoreConsumer/Components/SnackbarMessage';

const initialState = {
    mouseX: null,
    mouseY: null,
  };

const EventMenu = ({jsEvent, event, selectEvents, onOpCompleted, editBlockGroup, isSingleEvent})=> {
    let componentId = "eventMenu";
    const ui = useUIContext(componentId);
    const Request = useRequest();

    const [state, setState] = useState(initialState);
    const [isOnboarding, setIsOnboarding] = useState(false);

    const handleOpen = (e) => {        
        setState({
            mouseX: e.clientX - 2,
            mouseY: e.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
        selectEvents(event.groupId, false);
    };

    const itemSelected = (op) => {
        handleClose();
        selectEvents(event.groupId, true);
        
        // if (event.extendedProps.fromOnboarding) {
        //     ui.MessageBox("Cannot delete/update onboarding blocks", "Delete/update block group");
        //     return;
        // }

        switch(op) {
            case "delete": {
                deleteBlock();
                break;
            }
            case "delete-all": {
                deleteBlockGroup();
                break;
            }            
            case "edit": {
                let config = event.extendedProps.config;
                let configData = JSON.parse(config.data);

                const dt = event.extendedProps.editSingleEvent;
                configData.StartDate = dt.toLocaleDateString();
                configData.Repetition.Days = [false, false, false, false, false, false, false];
                configData.Repetition.Days[dt.getDay()] = true;
                configData.Repetition.Repeats = "once";
                
                config.data = JSON.stringify(configData);

                editBlockGroup({
                    groupId: event.groupId,
                    config: config, 
                    blkTableType: event.extendedProps.blkTableType,
                    editSingleEvent: event.extendedProps.editSingleEvent
                });
                break;
            }
            case "edit-all": {
                editBlockGroup({
                    groupId: event.groupId,
                    config: event.extendedProps.config, 
                    blkTableType: event.extendedProps.blkTableType,
                    editSingleEvent: null
                });
                break;
            }
        }
    }

    const deleteBlock = async () => {
        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this single block?");
        if (confirmed == 1) {   
            ui.ShowOverlay();
            const res = await Request.deleteBlock({
                groupId: event.groupId,
                dt: event.extendedProps.editSingleEvent.toLocaleDateString(),
            });
    
            if (res.success) {
                onOpCompleted();
            } else {
                ui.ShowSnackbar(res.error, Severity.error);
            }
            
            ui.HideOverlay();
        }
        selectEvents(event.groupId, false);
    }

    const deleteBlockGroup = async () => {
        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this block group?");
        if (confirmed == 1) {   
            ui.ShowOverlay();
            const res = await Request.deleteBlockGroup({
                groupId: event.groupId
            });
    
            if (res.success) {
                onOpCompleted();
            } else {
                ui.ShowSnackbar(res.error, Severity.error);
            }
            
            ui.HideOverlay();
        }
        selectEvents(event.groupId, false);
    }

    useEffect(()=> {
        if (jsEvent) handleOpen(jsEvent);
    }, [jsEvent]);

    useEffect(() => {
        setIsOnboarding(event?.extendedProps?.fromOnboarding ?? false);
    });

    return (<>
        <Menu
            keepMounted
            open={state.mouseY !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            state.mouseY !== null && state.mouseX !== null
                ? { top: state.mouseY, left: state.mouseX }
                : undefined
            }
        >

            {isOnboarding ? (
                <MenuItem onClick={(e)=> itemSelected("delete-all")}>Delete Event Group</MenuItem>
            ) : 
                isSingleEvent(event?.groupId ?? "") ? (<>
                    <MenuItem onClick={(e)=> itemSelected("edit-all")}>Edit Event</MenuItem>
                    <MenuItem onClick={(e)=> itemSelected("delete-all")}>Delete Event</MenuItem>
                </>) : (<>
                    <MenuItem onClick={(e)=> itemSelected("edit")}>Edit Single Event</MenuItem>
                    <MenuItem onClick={(e)=> itemSelected("delete")}>Delete Single Event</MenuItem>
                    <Divider />
                    <MenuItem onClick={(e)=> itemSelected("edit-all")}>Edit Event Group</MenuItem>
                    <MenuItem onClick={(e)=> itemSelected("delete-all")}>Delete Event Group</MenuItem>
                </>)
            }
        </Menu>
    </>)
}

export default EventMenu;
