import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import Storage from '../../storage';
import UserManagementRoles from '../../Components/UserManagementForm/UserManagementRoles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Overlay from '../../Components/Overlay/Overlay';

class UserManagement extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        redirectToLogin: false,
        columns: [],
        showLoadingOverlay: false,
        openSnackBar : false,
        SnackbarMessage : '',
        errorSeverity : 'success'
    };
    this.logoUrl = Utils.getLogoUrl();
  };
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
      try {
        this.setState(() => ({ showLoadingOverlay: true }));  
        const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);
        this.setState(() => ({ showLoadingOverlay: false }));  
      }
      catch {
          this.setState(() => ({
              redirectToLogin: true
          }));
      }
      await this._loadRolesData();
    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }

  _handleChecboxGrid = (e) =>
  {
    console.log(e);
  }

  _loadRolesData = async () =>
  {
    try {  
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_ADMIN_API}/getRoles?secretCode=${apiKey}`;
      const res = await axios.get(url);
      var gridColumns = [
        { field: 'userName', title: 'User Name', editable: 'never'},
        { field: 'email', title: 'Email', editable: 'never'},
        { field: 'roles', title: 'User Role',lookup: {1: 'Admin', 0: 'Customer Support'}},
      ];
/*       if(res?.data){
        res.data.forEach((item, index) => {
          gridColumns.push({
            field: item,
            title: item,
            lookup: {true: 'Yes', false: 'No'}
          });
        });
      } */
      this.setState(() => ({ columns:gridColumns }));
    }catch{
      console.log('error');
    }
  }
   /**
   * Renders the component.
   */
  render() {
    const {
      showLoadingOverlay,
      redirectToLogin
    } = this.state;

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }
    return (
        <div>
          <Box p={2} bgcolor="background.paper">
            <Typography>
              Modify roles of each user
            </Typography>
          </Box>
          <Divider/>
          <Box p={1} bgcolor="background.paper">
            <UserManagementRoles columns={this.state.columns} data={this.state.data} />
          </Box>
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>   
        </div>
    ); 
  }
        
}

export default UserManagement;