import React from 'react';
import Router from "./Router/router";
import './App.css';

const App = () => {
  return (
    <div className="App">
      <React.Fragment>
        <Router />
      </React.Fragment>
    </div>
  );
}

export default App;
