import React, {useState} from "react";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export const Severity = Object.freeze({
    success: "success",
    error: "error",
    warning: "warning",
    info: "info"
});

export const SnackbarOptions = Object.freeze({
    message: "",
    severity: Severity.success,
    autoHideDuration: 3000, 
    open: false,
    onClose: null
});

export function _SnackbarMessage(props) {     
    const [errorSeverity, setErrorSeverity] = useState(props.severity);
    const [openSnackBar, setOpenSnackBar] = useState(props.open);
    const [snackbarMessage] = useState(props.message);
    const [autoHideDuration] = useState(props.autoHideDuration);

    const closeMessage = () => {
        setOpenSnackBar(false);
        props.onClose && props.onClose(); 
    };

    return (
        <Snackbar 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
            open={openSnackBar} 
            autoHideDuration={autoHideDuration} 
            onClose={closeMessage}>
            <MuiAlert elevation={6} variant="filled" onClose={closeMessage} severity={errorSeverity}>
                {snackbarMessage} 
            </MuiAlert>
        </Snackbar>       
    );
}