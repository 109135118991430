import axios from 'axios';
import React, { Component }  from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import './EblastThreshold.scss';
import Constants from '../../constants';
import Overlay from '../../Components/Overlay/Overlay';
import Storage from '../../storage';
import Utils from '../../utils';


import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from "@material-ui/icons/Search";
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};  

class EblastSearch extends Component {

  constructor(props) {
        super(props);    

    this.state = {
        data : [],
        redirectToLogin: false,
        showLoadingOverlay: false,

        coverKeySearch : '',

        openSnackBar : false,
        SnackbarMessage : '',
        errorSeverity : 'success',
        showEblastForm: false,

      eBlastInfo : {
        coverKey : '',
        frequencyTypeID: 1,
        threshold: 0,
        frequencyType: [
          {
            frequencyTypeID: 1,
            frequencyDescription: 'Monthly',
            selected: true
          },
        ]
      }
      
    };  
      
    this.logoUrl = Utils.getLogoUrl();
  };

  
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
        try {
          this.setState(() => ({ showLoadingOverlay: true }));  
          const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          this.setState(() => ({ showLoadingOverlay: false }));  
        }
        catch {
            this.setState(() => ({
                redirectToLogin: true
            }));
        }

    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }

  

   _handleToggle  =  async () =>
   {
    if (this.state.coverKeySearch !== "")
    {     
        try {  
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            this.setState(() => ({ showLoadingOverlay: true }));

          //  const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKey/${this.state.coverKeySearch}?secretCode=${apiKey}`;
          //  const res =  await axios.get(url);

            const {
              eBlastInfo,
            } = this.state;
            const url1 = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKeyEblast/${this.state.coverKeySearch}?secretCode=${apiKey}`;

          //  if (res?.data) {
          //    let d = res.data;
             
          //    this.setState(() => ({ data: d}));

           //   if (d.length === 0) {
           //     this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info',openSnackBar: true }));
           //   } else {
                  let res1 =  await axios.get(url1);
                  let d1 = res1.data;
                  
                  if (res1?.data) {
                    this.setState(() => ({eBlastInfo: d1}))
                  } else {
                    const threshold =`${process.env.REACT_APP_THRESHOLD_EBLAST}`;                  
                    eBlastInfo.coverKey = this.state.coverKeySearch;
                    eBlastInfo.threshold = threshold;
                    this.setState(() => ({eBlastInfo: eBlastInfo }))
                  }
                  this.setState(() => ({  showEblastForm: true }));
           //   }
          //  }     
          //  else   
          //    this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));

            this.setState(() => ({ showLoadingOverlay: false }));   
        }
        catch {
            this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the coverkey.'})); 
        } 
    }
    else
    {
         this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
    }       
   }

  _searchCoverKey = async (coverKeySearch) => {
    if (this.state.coverKeySearch !== "")
    {     
      try {      
          const apiKey = `${process.env.REACT_APP_API_KEY}`;
          this.setState(() => ({ showLoadingOverlay: true }));
         // const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKey/${Number(coverKeySearch)}?secretCode=${apiKey}`;
         // const res =  await axios.get(url);

          const {
            eBlastInfo,
          } = this.state;
          const url1 = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKeyEblast/${Number(coverKeySearch)}?secretCode=${apiKey}`;

         // if (res?.data) {
         //     let d = res.data;
         //     this.setState(() => ({ data: d}));
              
        //      if (d.length === 0) {
        //        this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info',openSnackBar: true }));
        //      } else {
                this.setState(() => ({  showEblastForm: true }));

                let res1 =  await axios.get(url1);
                let d1 = res1.data;
                if (res1?.data) {
                  this.setState(() => ({eBlastInfo: d1 }))
                } else {
                  const threshold =`${process.env.REACT_APP_THRESHOLD_EBLAST}`;  
                  eBlastInfo.coverKey = this.state.coverKeySearch;
                  eBlastInfo.threshold = threshold;
                  this.setState(() => ({eBlastInfo: eBlastInfo }))
                }
        //      }
        //  }     
        //  else   
        //     this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));

          this.setState(() => ({ showLoadingOverlay: false }));
      }
      catch {
          this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the coverkey.'})); 
      }  
    }
    else
    {
         this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
    }            
  }

  _handleSubmit = async () => {
    try {
      
      this.setState(() => ({ showLoadingOverlay: true }));
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const {
        eBlastInfo,
      } = this.state;
      const dto = {'coverKey' : Number(eBlastInfo.coverKey), 'frequencyTypeID' : Number(eBlastInfo.frequencyTypeID), 'threshold': Number(eBlastInfo.threshold)};
      const url = `${process.env.REACT_APP_CAMPAIGN_API}/SetEblastThreshold?secretCode=${apiKey}`;
      const res = await axios.post(url, dto);
      
      if (res?.data) {
        this.setState(() => ({ errorSeverity: 'success', showLoadingOverlay: false,openSnackBar: true, SnackbarMessage: 'Successfully saved the threshold.'})); 
      }
      else {
        this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 
      }
  
    } 
    catch(error) {
      this.setState(() => ({  errorSeverity: 'error', showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in saving threshold.'})); 
    }
  
   }

  _handleTextFieldChange = (ck) => {
    this.setState(() => ({ coverKeySearch: ck }));   
    };

  _handleThresholdChange = (th) => {
    const {
      eBlastInfo,
    } = this.state

    eBlastInfo.threshold = th;

    this.setState(() => ({ eBlastInfo: eBlastInfo }));   
    };
    /**
   * Renders the component.
   */
  render() {
    const {
      redirectToLogin,
      eBlastInfo,
      showLoadingOverlay,
      openSnackBar,
      SnackbarMessage,
      errorSeverity,
      coverKeySearch,
      showEblastForm
    } = this.state;

    const {
      coverKey,
      frequencyTypeID,
      threshold,
    } = eBlastInfo

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }

    return (
        <div>
          <Box p={3} bgcolor="background.paper">
             <Typography>
                Search a coverkey and set it's eBlast threshold
             </Typography>  
          </Box>
          <Divider/>          
          <Box p={3} bgcolor="background.paper">
          <ValidatorForm onSubmit={this._handleToggle} onError={errors => console.log(errors)}> 
          <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>   
          <Grid item xs={12} sm={3} >        
              <TextValidator
                  validators={['required', 'isPositive']}
                  errorMessages={['this field is required','Must be numeric and greater than zero']}                
                  fullWidth
                  value={coverKeySearch}
                  onInput={ (e)=>this._handleTextFieldChange(e.target.value)}  
                  onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    this._searchCoverKey(ev.target.value);
                    ev.preventDefault();
                  }
              }}            
              label="Search Coverkey"
              InputProps={{
                  endAdornment: (
                  <InputAdornment>
                      <IconButton type="submit">
                      <SearchIcon />
                      </IconButton>                      
                  </InputAdornment>
                  )
              }}
              />   
              </Grid>   
              <Grid item xs={12} sm={3} > 
                <Button variant="contained" color="primary" type="submit">
                        Search
                </Button>                 
              </Grid>   
            </Grid>   
            </ValidatorForm>              
          </Box>     

          {showEblastForm ? 
          <>
          <Divider/>
           <Box p={3} bgcolor="background.paper">
           <Typography variant="h6" gutterBottom>
               Eblast Threshold
            </Typography>        
            <ValidatorForm onSubmit={this._handleSubmit} onError={errors => console.log(errors)}>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={3}>
                    <InputLabel id="coverKey" className="coverKeyLabel">Coverkey</InputLabel>
                    <TextValidator
                        required
                        id="coverKey"                
                        name="coverKey"
                        fullWidth
                        value={coverKey}
                        disabled
                    />      
                  </Grid>                               
                  <Grid item xs={12} sm={3}>
                      <TextValidator
                          required
                          id="threshold"                
                          name="threshold"
                          label="Threshold"
                          fullWidth      
                          value={threshold}
                          onInput={ (e)=>this._handleThresholdChange(e.target.value)}                               
                          validators={['required', 'isPositive']}
                          errorMessages={['this field is required', 'Must be numeric and greater than zero']}
                      />
                  </Grid>
                  <Grid item xs={12}  sm={2}>
                        <InputLabel id="frequencyTypeID" className="coverKeyLabel">Frequency Type</InputLabel>
                        <Select
                          labelId="frequencyTypeID"
                          id="frequencyTypeID"
                          value={frequencyTypeID}
                          //value="1"
                          fullWidth
                          //onChange={ e=>setFrequencyTypeID(e.target.value)}              
                        >
                          <MenuItem value={1} selected>Monthly</MenuItem>
                          {/* <MenuItem value={1}>Daily</MenuItem>
                          <MenuItem value={2}>Monthly</MenuItem>
                          <MenuItem value={3}>Quarterly</MenuItem>
                          <MenuItem value={4}>Yearly</MenuItem> */}
                        </Select>
                    </Grid>  
                      
                  <Grid item xs={12}  sm={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      endIcon={<Icon>save</Icon>}
                    >
                       Save
                    </Button>    
                   </Grid>   
                  </Grid>                 
                </ValidatorForm>
            </Box>
          </>
          : null}
    
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>      
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
            <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
          </Snackbar>                    
        </div>
    ); 
  }
        
}

export default withTranslation()(EblastSearch);