import ClDataCore from '../Core/coreData';
import APIs from "../api.json";

const secretCode = "ditac-1";
const localhost = {
    env: {
        REACT_APP_ADMIN_API: "https://localhost:44331/api/admin",
        REACT_APP_WEBSCHEDULER_API: "https://localhost:44331/api"
    }
}

export class ClDataRequestObject extends ClDataCore {    
    _request = {};
    _cancelToken;

    constructor(cancelToken) {
        super();
        this._cancelToken=cancelToken;
        this._init();
    }
    
    _init() {
        APIs.forEach(x => {
            this._request[x.name] = async (model) => {
                let url = x.url + "?secretCode=" + secretCode;

                let r = /{.*?}/;
                let matches = r.exec(x.url);                
                if (matches) {
                    let str = matches[0].replace("{process.env.", "").replace("}", "");
                    let v = process.env[str];
                    url = url.replace(r, v);
                }

                return await this._Request(x.method, url, model, this._cancelToken);
            };
        });
    }

    get Request() {
        return this._request;
    }
}