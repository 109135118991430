import React from "react";
import { createManagedProps, ClUIManager, createMProps } from "../Core/coreUI";
import { _MessageBox, _ConfirmBox, MessageBoxOptions, ConfirmBoxOptions, DialogType } from './Components/DialogBoxes';
import Overlay from "../../Components/Overlay/Overlay";
import { ComponentType } from "../Core/coreLib";
import { SnackbarOptions, _SnackbarMessage } from "./Components/SnackbarMessage";


class ClUIContextObject extends ClUIManager {    
    _OnClose(id, userOnClose, param) {
        this.Remove(id);
        userOnClose && userOnClose(param);
    };

    async ErrorBox(message, title, _mProps) {
        return this.MessageBox(message, title, _mProps, ComponentType.ErrorBox);
    }

    async MessageBox(message, title, _mProps, type) {               
        return new Promise((resolved) => {
            let clProps =  createManagedProps(_mProps);
        
            let mProps = {
                ...MessageBoxOptions,
                ..._mProps,
                ...clProps, 
                message: message,
                open: true,
                componentType: type ?? ComponentType.MessageBox,
                onClose: (param) => {
                    this._OnClose(clProps.managedComponentId, _mProps.onClose, param);
                    return resolved(param);
                }
            };

            if (title) mProps.title = title;
            if (type) {
                mProps.componentType = type;
                mProps.type = type == ComponentType.ErrorBox ? DialogType.error : DialogType.information;
            } else {
                mProps.componentType = ComponentType.MessageBox;
            }
            
            this.Add(mProps);
        });
    }

    async ConfirmBox(message, title, _mProps) {      
        return new Promise((resolved) => { 
            let clProps = createManagedProps(_mProps);
            
            let mProps = {
                ...ConfirmBoxOptions,
                ..._mProps,
                ...clProps, 
                message: message, 
                open: true,
                componentType: ComponentType.ConfirmBox,
                onClose: (param) => {
                    this._OnClose(clProps.managedComponentId, _mProps.onClose, param);
                    return resolved(param);
                }
            };

            if (title) mProps.title = title;

            this.Add(mProps);
        });
    }

    ShowOverlay(_mProps) {
        let clProps = createManagedProps(_mProps);

        let mProps = {
            ...clProps, 
            open: true,
            componentType: ComponentType.Overlay
        };

        this.Add(mProps);
        
        return mProps.managedComponentId;
    }

    HideOverlay(managedComponentId) {
        this._OnClose(managedComponentId);
    }

    async ShowSnackbar(message, severity, autoHideDuration, _mProps) {
        return new Promise((resolved) => { 
            let clProps = createManagedProps(_mProps);

            let mProps = {
                ...SnackbarOptions,
                ...clProps, 
                open: true,
                message: message,
                onClose: (param) => {
                    this._OnClose(clProps.managedComponentId, _mProps.onClose, param);
                    return resolved(param);
                },
                componentType: ComponentType.Snackbar
            };

            if (severity) mProps.severity=severity;
            if (autoHideDuration) mProps.autoHideDuration=autoHideDuration;

            this.Add(mProps);
        });
    }

    GenerateComponent(mProps) {
        let component = null;

        switch(mProps.componentType) {
            case ComponentType.MessageBox:
                component = (<_MessageBox {...mProps} />);
                break;
            case ComponentType.ErrorBox:
                component = (<_MessageBox {...mProps} />);
                break;
            case ComponentType.ConfirmBox:
                component = (<_ConfirmBox {...mProps} />);
                break;
            case ComponentType.Overlay:
                component = (<Overlay show={mProps.open}><i className="spinner-eclipse"></i></Overlay>);
                break;
            case ComponentType.Snackbar:
                component = (<_SnackbarMessage {...mProps} />);
                break;
        }
        return component;
    }
}

export class ClUIClient {
    _uiContext;
    _overlayIndex = {};
    mProps;
    uiJSX = {
        recordNotFoundJSX: (<div className="static-notify">Record not found</div>),
        makeSelectionJSX: (<div className="static-notify">Please select Cover</div>),
        requestingDataJSX: (<div className="static-notify">Please wait while system is requesting data...</div>),
    };

    constructor(uiContext, componentId) {
        this._uiContext = uiContext;
        this.uiJSX = uiContext.uiJSX;
        this.mProps = createMProps(componentId)
    }
    
    async MessageBox(message, title, options) { 
        return await this._uiContext.MessageBox(message, title, {...this.mProps, ...(options ?? {}) });
    }

    async ErrorBox(message, title, options) { 
        return await this._uiContext.ErrorBox(message, title, {...this.mProps, ...(options ?? {}) });
    }

    async ConfirmBox(message, title, options) { 
        return await this._uiContext.ConfirmBox(message, title, {...this.mProps, ...(options ?? {}) });
    }

    ShowOverlay() {
        let id = this.mProps.managedComponentId;
        let oId = this._uiContext.ShowOverlay(this.mProps);

        if (this._overlayIndex[id]) {
            this._overlayIndex[id].push(oId);
        } else {
            this._overlayIndex[id] = [oId];
        }
    }

    HideOverlay() {       
        let id = this.mProps.managedComponentId; 
        if (this._overlayIndex[id]) {
            let oId = this._overlayIndex[id].pop();
            this._uiContext.HideOverlay(oId);
    
            if (this._overlayIndex[id].length==0) delete this._overlayIndex[id];
        }
    }

    async ShowSnackbar(message, severity, autoHideDuration) {
        return await this._uiContext.ShowSnackbar(message, severity, autoHideDuration, this.mProps);
    }
}

const UIContext = React.createContext(new ClUIContextObject())
export default UIContext;