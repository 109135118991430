import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './LoginFooter.scss';

/**
 * Represents the footer for the login pages.
 */
const LoginFooter = memo(() => {
  const { t } = useTranslation();

  return (
    <div className="login-footer">
      <span className="login-footer__sponsor">{t('Powered by Essilor')}</span>
    </div>
  );
});

export default LoginFooter;
