import axios from 'axios'
import MaterialTable from "material-table"
import React , { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { withStyles } from '@material-ui/core/styles'

import './Coverlookup.scss'
import Constants from '../../constants'
import ShadowReport from './ShadowReport'
import { useUIContext } from '../../ContextLib/contextHooks'
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage'
import Storage from '../../storage'
import { BottomNavigation } from '@material-ui/core'

export default function Coverlookup() {
    let componentId = "Coverlookup"
    const ui = useUIContext(componentId)
    const [searchTypeID, setSearchTypeID] = useState("coverkey")
    const [searchInput, setSearchInput] = useState("")
    const [data, setData] = useState([])
    const [showLoginData, setShowLoginData] = useState(false)
    const [CoverKey, setCoverKey] = useState(0)
    const [phoneIsValid, setPhoneIsValid] = useState(true)
    

    const searchOptions = [
        {key : "covername" , value : "Cover Name"},
        {key : "coverkey" , value : "Cover Key"},
        {key : "coverphone" , value : "Cover Phone"},
        {key : "coverlogin" , value : "Cover Login"},
        {key : "lockey" , value : "Location Key"},
     ]

    const columns = [
        { field: 'coverKey', title: 'Coverkey', width: 100 , editable: 'never', emptyValue: '-'},
        { field: 'orgName', title: 'Practice Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.orgName} placement="top-start"><span>{dataRow.orgName}</span></HtmlTooltip>}},
        { field: 'status', title: 'Status', width: 100 , editable: 'never', emptyValue: '-'},
        { field: 'address1', title: 'Address 1', width: 150  , editable: 'never', emptyValue: '-'},
        { field: 'city', title: 'City', width: 100  , editable: 'never', emptyValue: '-'},
        { field: 'state', title: 'State', width: 100  , editable: 'never' , emptyValue: '-'},
        { field: 'email', title: 'Email', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
        { field: 'phone', title: 'Phone', width: 150  , editable: 'never' , emptyValue: '-'},
    ]

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 250,
          fontSize: theme.typography.pxToRem(16),
          border: '1px solid #dadde9',
          fontFamily:'Noto Sans, sans-serif',
        },
      }))(Tooltip)

    const componentDidMount = async () => {
        const user = Storage.getItem(Constants.currUserKey)
        let redirectToLogin = false
        if (user && user.jwt) {
          try {
            ui.ShowOverlay()
            const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`
            await axios.get(jwtCheckUrl)
            ui.HideOverlay()
          }
          catch (error) {
            ui.ShowSnackbar(error, Severity.error)
            redirectToLogin = true
          }
        } 
        else {
            redirectToLogin = true
        }

        if (redirectToLogin) return <Redirect to={{ pathname: '/' }} />
    }       

    useEffect(() => {        
        componentDidMount()
    }, [])

    const onSubmit = async () => {
        try {
            ui.ShowOverlay()
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  queryType: searchTypeID,
                  queryString: searchInput
                }
              }

              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getCoverLevelData`
              const res = await axios.get(url, request)
              let d = res.data
            
              if (res?.data.length > 0) { setData(d) }
              else { 
                  ui.ShowSnackbar("Cover does not exist in the database.", Severity.warning) 
                  setData([]) 
              }


        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error)
            setData([]) 
        }
        setShowLoginData(false)
        ui.HideOverlay()
    }

    const loadLoginDetails = (coverKey) => {
        ui.ShowOverlay()
        setData([]) 
        setCoverKey(coverKey)
        setShowLoginData(true)
        ui.HideOverlay()
    }

     
 
    const handelPhoneNumberOnChange=(e) => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (e=== '' || re.test(e)) {
            setSearchInput(e)
            setPhoneIsValid(true)
        }
        else
        {
            setSearchInput(e)
            setPhoneIsValid(false)
        }
    }

    const handelsetSearchTypeID=(e) => {  
            setSearchTypeID(e)
            setPhoneIsValid(true)
            setSearchInput('')
    }

    const renderPhoneValidation = () => {
      if (!phoneIsValid ) {
        return <Grid container justify="flex-start" spacing={4}>
        <Grid item>
        <span style={{color: "red", paddingLeft:120}}>Phone number format is incorrect. Please enter 10 digit number without any special characters</span>  
        </Grid>
    </Grid>
    //      return <Div className="">     
    //        <span alignItems={{BottomNavigation}} style={{color: "red", paddingLeft:120, marginTop:300}}>Phone number format is incorrect. Please enter 10 digit number without any special characters</span>       
    //  </Div>
      }
    };
    

    const renderSearchText = () => {
        if (searchTypeID === 'coverphone') {
           return <TextValidator
           style={{width:"170px"}}
           id="searchInput"
           autoComplete="off"
           placeholder="Search Value"
           value={searchInput}
           onInput={e => setSearchInput(e.target.value)} 
           onChange={e => handelPhoneNumberOnChange(e.target.value)}
           maxLength={10}
           validators={['required', 'isPositive']}
           errorMessages={['Search Value is required', null]}
           InputProps={{
               disableUnderline: true,
               startAdornment: (
               <InputAdornment position="start">
                   <IconButton >
                   <SearchIcon />
                   </IconButton>
               </InputAdornment>
               ),
           }}
           inputProps={{
            maxLength: 10
          }}
           />;
        } else {
          return  <TextValidator
          style={{width:"170px"}}
          id="searchInput"
          autoComplete="off"
          placeholder="Search Value"
          value={searchInput}
          onInput={e => setSearchInput(e.target.value)}  
          validators={['required']}
          errorMessages={['Search Value is required']}  
          InputProps={{
              disableUnderline: true,
              startAdornment: (
              <InputAdornment position="start">
                  <IconButton >
                  <SearchIcon />
                  </IconButton>
              </InputAdornment>
              ),
          }}
          />
        }
      };

    return(

         
        
    
    <div className="coverlookup">
      <Box p={2} bgcolor="background.paper">
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
            <Grid item>
                <Typography variant="h5">  
                Cover Data
                </Typography>
            </Grid>

          <Divider orientation="vertical" variant="middle" flexItem/>

            <Grid item>
                <Typography variant="h6">  
                    Review Data for Shadow
                </Typography>
            </Grid>
        </Grid>
      </Box>

      <Divider />
      
      <Box p={4} bgcolor="background.paper" >
           
          <div className="coverlookup-data">
           
          <ValidatorForm onSubmit={onSubmit}  onError={errors => console.log(errors)} > 
          {renderPhoneValidation()}
            <Grid container justify="flex-start" alignItems="center" spacing={2} className="search-option">
                <Grid item>
                    <Typography variant="h6">Search By: </Typography>
                </Grid>

                <div className="search-fields">
                <Grid item>
                    <FormControl>
                    <Select 
                        id="searchType"
                        style ={{width:"150px"}}
                        value={searchTypeID}
                        onChange={ e=>handelsetSearchTypeID(e.target.value)}     
                        defaultValue='none'
                        disableUnderline       
                        native
                    >
                        {
                        searchOptions.map((item, index) => {
                        return <option key={index} value={item.key}>{item.value}</option>
                        })
                        }
                    </Select>
                    </FormControl>
                </Grid>

                
                    <Divider orientation="vertical" variant="middle" flexItem/>
                

                <Grid item>
                    {renderSearchText()}
                </Grid>
                </div>

                <Grid item>
                    <Button style={{maxWidth: '100px' , minWidth: '100px'}} variant="contained" color="primary" type="submit">
                    SEARCH
                    </Button>
                </Grid>
   

            </Grid>
          </ValidatorForm>
          
          <Divider />

            {showLoginData ? 
            <ShadowReport CoverKey={CoverKey}/>
            :
            <MaterialTable
                    height="200"
                    columns={columns}
                    data={data}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 15, 20],
                        toolbar: false,
                        paging: true,
                        draggable: false,
                        sorting: false,
                        tableLayout: 'fixed',
                    }}       
                    actions={[
                        {
                        icon: () => <VisibilityIcon fontSize="small" />,
                        tooltip: 'View Detail',
                        onClick: async (event, rowData) => loadLoginDetails(rowData.coverKey)
                        }
                    ]}
                    /> 
                }
                
     
          </div>

      </Box>
    </div >
    )
}