import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';
export default function RowDetailsDIPS(props) {
  const data = props.row
  
    return (
      <React.Fragment>
          <Box margin={1}>
  
          <Typography variant="h6" gutterBottom component="div">
              Appointment Details
            </Typography>
            <Table size="small" aria-label="details">
            <TableHead>
                <TableRow>
                  <TableCell>Appt Status</TableCell>                  
                  <TableCell>Appt Reason</TableCell>
                  <TableCell>Appt Date</TableCell>
                  <TableCell>Appt Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.apptStatus}</TableCell>                    
                    <TableCell>{data.apptReason}</TableCell>
                    <TableCell>{data.startTime ?  moment(data.startTime).format('MM/DD/YYYY - hh:mm A') : ""}</TableCell>
                    <TableCell>{data.apptType}</TableCell>                  
                  </TableRow>              
              </TableBody>
            </Table>          
            <Typography variant="h6" gutterBottom component="div">
              Patient Details
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Primary Phone</TableCell>
                  <TableCell>Cellphone</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.firstName}</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                    <TableCell>{data.primaryPhone}</TableCell>     
                    <TableCell>{data.cellPhone}</TableCell> 
                    <TableCell>{data.email}</TableCell>                                                  
                  </TableRow>                
              </TableBody>
            </Table>
  
            <Typography variant="h6" gutterBottom component="div">
              Communication Preferences
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>SMS</TableCell>
                  <TableCell>Voice</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell>{data.reminderSMSPreference === "1" ? "Yes" : "No"}</TableCell>
                    <TableCell>{data.reminderVoicePreference === "1" ? "Yes" : "No"}</TableCell>
                    <TableCell>{data.reminderEmailPreference === "1" ? "Yes" : "No"}</TableCell>                                                      
                  </TableRow>                
              </TableBody>
            </Table>     
  
            <Typography variant="h6" gutterBottom component="div">
              Provider Details
            </Typography>
            <Table size="small" aria-label="details">
              <TableHead>
                <TableRow>
                  <TableCell>LocKey</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Provider Lastname</TableCell>
                  <TableCell>Provider Firstname</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell>{data.locKey}</TableCell>
                  <TableCell>{data.locationName}</TableCell>
                  <TableCell>{data.providerLastName}</TableCell>
                  <TableCell>{data.providerFirstName}</TableCell>                                                      
                  </TableRow>                
              </TableBody>
            </Table>         
            <br/>
  
          </Box>      
       </React.Fragment>
    );   

}