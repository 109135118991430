/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

let onboardingData = {};
let provider = {};

export default function ProviderEditDeleteForm(props) {
    const [title, setTitle] = useState(props.data.title ?? '');
    const [firstName, setFirstName] = useState(props.data.firstName ?? '');
    const [lastName, setLastName] = useState(props.data.lastName ?? '');
    const [role, setRole] = useState(props.data.role ?? '');
    const [phone, setPhone] = useState(props.data.phone ?? '');
    const [email, setEmail] = useState(props.data.email ?? '');
    const [deleteProvider, setDeleteProvider] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [withError, setWithError] = useState(false);
    const {validate,setValidate,setOnboardingData} = props;
    const [onBoardingID,setBoardingID]=useState(props.data.onboardingProviderId ?? '')

    const btnRef = useRef();
    const formRef = useRef();

    onboardingData = props.completeData;

    provider = onboardingData.providers.find((prov) => {
        return (prov.onboardingProviderId === props.data.onboardingProviderId);
    });

    const _handleSubmit = (event) => {
        try{event.preventDefault();}catch{}
        if(!withError)
        {
            for (var i=0; i < onboardingData.providers.length; i++) {
                if (onboardingData.providers[i].onboardingProviderId === props.data.onboardingProviderId || onboardingData.providers[i].profileIdentifier === props.data.profileIdentifier) {
                    onboardingData.providers[i] = {...onboardingData.providers[i], title: title, firstName: firstName, lastName: lastName,
                        role: role, phone: phone.slice(2, 16).replaceAll('-', ''), email: email}
                }
            }
            props.parentCallback(onboardingData, "update");
        }
        setWithError(false);
    }

    const _handleProviderDelete = (event) => {
        try{event.preventDefault();}catch{}
        
        setOpenDialog(false);

        onboardingData.providers = onboardingData.providers.filter((prov) => {
            return prov.profileIdentifier !== props.data.profileIdentifier;
        });


        setDeleteProvider(true);
        props.parentCallback(onboardingData, "delete");
    }
    
    const _handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const _handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => 
      ValidatorForm.addValidationRule('invalidPhone', (value) => {
        if (value.includes('_')) {
            return false;
        } else {
            return true;
        }
      }),
      ValidatorForm.addValidationRule('requiredPhone', (value) => {
        if (value === '_-___-___-____' || value === '' || typeof value === 'undefined') {
            return false;
        } else {
            return true;
        }
      }),
      ValidatorForm.addValidationRule('validTitle', (value) => {
        if (0 < value.length && value.length < 10) {
            return true;
        } else {
            return false;
        }
      }),
    []);

    // useEffect(() => {
    //   return () => {
    //     ValidatorForm.removeValidationRule('invalidPhone');
    //     ValidatorForm.removeValidationRule('requiredPhone');
    //   }
    // }, []);

    const loadProviderLocations = () =>{
        const locations = [];
        const locationsToDisplay = [];
        const providerLocations = [];
        const providerLocationsToDisplay = [];
        var i;

        for (i=0; i < props.completeData.locations.length; i++) {
            locations.push(Object.assign({'practiceId': props.completeData.locations[i].practiceId,
            'practiceName': props.completeData.locations[i].name + ' - ' + props.completeData.locations[i].city + ', ' + props.completeData.locations[i].state}));
        };

        for (i=0; i < props.completeData.providerSchedules.length; i++) {
            if (props.completeData.providerSchedules[i].onboardingProviderId === props.data.onboardingProviderId) {
                providerLocations.push(props.completeData.providerSchedules[i])
            }
        };

        providerLocations.forEach((data, i) => { 
                var provLoc = false;
                if (data.schedule["Sunday"][0].startTimeBlock !== -1 || data.schedule["Sunday"][0].endTimeBlock !== -1 || 
                    data.schedule["Sunday"][1].startTimeBlock !== -1 || data.schedule["Sunday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                } else if (data.schedule["Monday"][0].startTimeBlock !== -1 || data.schedule["Monday"][0].endTimeBlock !== -1 || 
                data.schedule["Monday"][1].startTimeBlock !== -1 || data.schedule["Monday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                } else if (data.schedule["Tuesday"][0].startTimeBlock !== -1 || data.schedule["Tuesday"][0].endTimeBlock !== -1 || 
                data.schedule["Tuesday"][1].startTimeBlock !== -1 || data.schedule["Tuesday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                } else if (data.schedule["Wednesday"][0].startTimeBlock !== -1 || data.schedule["Wednesday"][0].endTimeBlock !== -1 || 
                data.schedule["Wednesday"][1].startTimeBlock !== -1 || data.schedule["Wednesday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                } else if (data.schedule["Thursday"][0].startTimeBlock !== -1 || data.schedule["Thursday"][0].endTimeBlock !== -1 || 
                data.schedule["Thursday"][1].startTimeBlock !== -1 || data.schedule["Thursday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                } else if (data.schedule["Friday"][0].startTimeBlock !== -1 || data.schedule["Friday"][0].endTimeBlock !== -1 || 
                data.schedule["Friday"][1].startTimeBlock !== -1 || data.schedule["Friday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                } else if (data.schedule["Saturday"][0].startTimeBlock !== -1 || data.schedule["Saturday"][0].endTimeBlock !== -1 || 
                data.schedule["Saturday"][1].startTimeBlock !== -1 || data.schedule["Saturday"][1].endTimeBlock !== -1) {
                    provLoc = true;
                }

                if (provLoc) {
                    providerLocationsToDisplay.push(providerLocations[i]);
                }                
        });

        providerLocationsToDisplay.forEach((prov) => {
            for (var j=0; j < locations.length; j++) {
                if (prov.practiceId === locations[j].practiceId) {
                    locationsToDisplay.push(locations[j].practiceName);
                }
            }
        });

        return locationsToDisplay;
      };

    const handleValidate = () =>  {
        
        setWithError(true);
        formRef.current.submit();
        setValidate(false);
      }
    
    useEffect((event) => {
        if (validate) {
           handleValidate();
        }    
      },);

    const _updateProvider = (onBoardingID) => {

        provider = {
            ...provider, 
            title: title, 
            firstName: firstName, 
            lastName: lastName,
            role: role, 
            phone: phone.slice(2,17).replaceAll('-',''),
            email: email,
            onboardingProviderId :onBoardingID
        }
        _updateOnboardingData(provider);
        console.log(onboardingData);
    }

    const _updateOnboardingData = (provider) =>
    {
        for (var i=0; i < onboardingData.providers.length; i++) {
            if (onboardingData.providers[i].onboardingProviderId === provider.onboardingProviderId) {
                onboardingData.providers[i] = {...onboardingData.providers[i], title: provider.title, firstName: provider.firstName, lastName: provider.lastName,
                    role: provider.role, phone: provider.phone.replaceAll('-',''), email: provider.email}
            }
        }

        setOnboardingData(onboardingData);
    }
    
    return (
        <>
        {
            deleteProvider ? null : 
            <>
                <Box bgcolor="background.paper" m={2} className="provider-edit-delete" key={props.key}>
                <ValidatorForm ref={formRef} onSubmit={_handleSubmit} onError={errors => console.log(errors)}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <TextValidator
                            autoComplete="off"
                            id="title"                
                            name="title"             
                            value={title}
                            validators={['required','validTitle']}
                            errorMessages={['Title is required', 'Title cannot exceed 10 characters']}  
                            onInput={ (e)=>setTitle(e.target.value)}
                            onBlur={()=>_updateProvider(onBoardingID)}            
                            label="Title *"
                        />  
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextValidator
                            autoComplete="off"
                            id="firstName"                
                            name="firstName"
                            fullWidth          
                            value={firstName}
                            validators={['required']}
                            errorMessages={['First Name is required']}  
                            onInput={ (e)=>setFirstName(e.target.value)}
                            onBlur={()=>_updateProvider(onBoardingID)}               
                            label="First Name *"
                        />  
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextValidator
                            autoComplete="off"
                            id="lastName"                
                            name="lastName"
                            fullWidth          
                            value={lastName}
                            validators={['required']}
                            errorMessages={['Last Name is required']}  
                            onInput={ (e)=>setLastName(e.target.value)}
                            onBlur={()=>_updateProvider(onBoardingID)}               
                            label="Last Name *"
                        />  
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <InputLabel id="roleLabel">Role</InputLabel>
                        <Select
                        labelId="role"
                        id="role"
                        value={role}
                        onChange={ e=>setRole(e.target.value)}
                        onBlur={()=>_updateProvider(onBoardingID)}   
                        fullWidth              
                        >
                        <MenuItem value=''>-- Select Role --</MenuItem>
                        <MenuItem value='Optician'>Optician</MenuItem>
                        <MenuItem value='Optometrist'>Optometrist</MenuItem>
                        <MenuItem value='Ophthalmologist'>Ophthalmologist</MenuItem>
                        <MenuItem value='Other'>Other</MenuItem>                      
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <InputMask
                         mask="+1(999) 999-9999"
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                        onBlur={()=>_updateProvider(onBoardingID)}    
                        >
                        {() => <TextValidator
                            // required
                            autoComplete="off"
                            id="phone"                
                            name="phone"
                            fullWidth
                            label="Phone Number"
                            // validators={['requiredPhone','invalidPhone']}
                            // errorMessages={['Phone Number is required', 'Invalid Phone']}           
                            />}
                        </InputMask>      
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextValidator
                            autoComplete="off"
                            id="email"                
                            name="email"
                            fullWidth      
                            validators={['required','isEmail']}
                            errorMessages={['Email is required','email is not valid']}         
                            value={email}
                            onInput={ (e)=>setEmail(e.target.value)}
                            onBlur={()=>_updateProvider(onBoardingID)}               
                            label="Email *"
                        />  
                    </Grid>

                    {
                        loadProviderLocations().map((loc, i) => {
                            return (
                                <div>
                                    {i === 0 && <InputLabel id="locationLabel">Location(s)</InputLabel>}
                                    <Button
                                        disabled
                                        id="providerLocation"
                                        type="submit"
                                        variant="contained"
                                        size="small">
                                        {loc}
                                    </Button>  
                                </div>
                            )
                        })
                    }
                
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2} id="grid-buttons"> 
                        <Grid item>
                            <Button
                            id="btnDeleteProvider"
                            type="reset"
                            variant="contained"
                            size="small"
                            onClick={ (e) => { _handleOpenDialog(e); } }
                            endIcon={<Icon>delete</Icon>}>
                            DEL
                            </Button>
                        </Grid>
        
                        <Grid item>
                            <Button
                            ref={btnRef}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>save</Icon>}>
                            Save
                            </Button>    
                        </Grid>
                    </Grid>  
                </Grid>
                </ValidatorForm>

                <Divider/>
                </Box>

                <Dialog
                maxWidth="md"
                open={openDialog}
                onClose={_handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">{"This action cannot be undone."}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this entry?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={_handleProviderDelete} color="primary">
                    Yes
                </Button>
                <Button onClick={_handleCloseDialog} color="primary" autoFocus>
                    No
                </Button>
                </DialogActions>
                </Dialog>   
            </>
        }
        </>
    );
}
