/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

let onboardingData = {};
let loc = {};

export default function LocationEditDeleteForm(props) {
    const [name, setName] = useState(props.data.name ?? '');
    const [email, setEmail] = useState(props.data.email ?? '');
    const [phoneNumber, setPhoneNumber] = useState(props.data.phoneNumber ?? '');
    const [websiteUrl, setWebsiteUrl] = useState(props.data.websiteUrl ?? '');
    const [addressLine1, setAddressLine1] = useState(props.data.addressLine1 ?? '');
    const [addressLine2, setAddressLine2] = useState(props.data.addressLine2) ?? '';
    const [city, setCity] = useState(props.data.city ?? '');
    const [state, setLocationState] = useState(props.data.state ?? '');
    const [zip, setZip] = useState(props.data.zip ?? '');
    const [withError, setWithError] = useState(false);
    const {validate,setValidate,setOnboardingData} = props;
    const [deleteLocation, setDeleteLocation] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [onpracticeId,setOnPracticeId]=useState(props.data.practiceId ?? '')
    const btnRef = useRef();
    const formRef = useRef();

    onboardingData = props.completeData;
    //reference to individual location we are looking at
    loc = props.completeData.locations.find((location) => {
        return (location.practiceId === props.data.practiceId);
    });

    useEffect(() => 
      ValidatorForm.addValidationRule('invalidPhone', (value) => {
        if (value.includes('_')) {
            return false;
        } else {
            return true;
        }
      }), 
    []);

    useEffect(() => {
      return () => {
        ValidatorForm.removeValidationRule('invalidPhone');
      }
    }, []);

    useEffect(() => 
      ValidatorForm.addValidationRule('phoneRequired', (value) => {
      if (value === '(___) ___-____' || value === '') {
        return false;
      } else {
        return true;
      }
      
      }), 
    []);

    useEffect(() => {
      return () => {
        ValidatorForm.removeValidationRule('phoneRequired');
      }
    }, []);
   

    const _handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const _handleCloseWarningDialog = () => {
        setOpenWarningDialog(false);
    };

    const _handleSubmit = (event) => {
        try{event.preventDefault();}catch{}
        if(!withError)
        {
            for (var i=0; i < onboardingData.locations.length; i++) {
                if (onboardingData.locations[i].practiceId === props.data.practiceId || onboardingData.locations[i].name === props.data.name) {
                    onboardingData.locations[i] = {...onboardingData.locations[i], name: name, email: email, phoneNumber: phoneNumber.slice(2, 16),
                        websiteUrl: websiteUrl, addressLine1: addressLine1, addressLine2: addressLine2,
                        city: city, state: state, zip: zip}
                }
            }
        
            props.parentCallback(onboardingData, "update");
        }
        setWithError(false);
    }

    const _handleLocationDeleteCheck = async (event) => {
        try{event.preventDefault();}catch{}

        if (onboardingData.locations.length <= 1) {
            setOpenWarningDialog(true);
            return;
        }

        setOpenConfirmDialog(true);
    }

    const _handleLocationDelete = async () => {
        setOpenConfirmDialog(false);

        onboardingData.locations = onboardingData.locations.filter((loc) => {
            return loc.practiceId !== props.data.practiceId;
        });

        setDeleteLocation(true);
        await props.parentCallback(onboardingData, "delete");
    }

    const handleValidate = () =>  {
        //var form = event.currentTarget.form;

        setWithError(true);
        formRef.current.submit();
        setValidate(false);
      }
    
    useEffect((event) => {
        if (validate) {
           handleValidate();
        }    
      },);

    const _updateLoc = (onpracticeId) => {

        loc = {...loc, 
            name: name, 
            email: email, 
            phoneNumber: phoneNumber,
            websiteUrl: websiteUrl, 
            addressLine1: addressLine1, 
            addressLine2: addressLine2,
            city: city, 
            state: state, 
            zip: zip,
            practiceId:onpracticeId
        };
        
        _updateOnboardingData(loc);
        console.log(onboardingData);
    }

    const _updateOnboardingData = (locData) =>
    {
        for (var i=0; i < onboardingData.locations.length; i++) {
            if (onboardingData.locations[i].practiceId === locData.practiceId || onboardingData.locations[i].name === locData.name) {
                onboardingData.locations[i] = {...onboardingData.locations[i], name: locData.name, email: locData.email, phoneNumber: locData.phoneNumber,
                    websiteUrl: locData.websiteUrl, addressLine1: locData.addressLine1, addressLine2: locData.addressLine2,
                    city: locData.city, state: locData.state, zip: locData.zip}
            }
        }

        setOnboardingData(onboardingData);
    }

    return(
        <>
        { deleteLocation ? null : 
         <>
            <Box bgcolor="background.paper" m={2} className="location-edit-delete" key={props.data.practiceId}>
                <ValidatorForm ref={formRef} onSubmit={_handleSubmit} onError={errors => console.log(errors)}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={3}>
                        <TextValidator
                            autoComplete="off"
                            id="name"                
                            name="name"             
                            value={name}
                            fullWidth
                            validators={['required']}
                            errorMessages={['Location Name is required']}  
                            onInput={(e)=>setName(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="Location Name *"
                        />  
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextValidator
                            autoComplete="off"
                            id="email"                
                            name="email"             
                            value={email}
                            fullWidth
                            validators={['required', 'isEmail']}
                            errorMessages={['Location Email is required', 'Email is not valid']}  
                            onInput={ (e)=>setEmail(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="Location Email *"
                        /> 
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <InputMask
                         mask="+1(999) 999-9999"
                        value={phoneNumber}
                        onChange={(e)=>setPhoneNumber(e.target.value)}
                        onBlur={()=>_updateLoc(onpracticeId)}
                        >
                        {() => <TextValidator
                        autoComplete="off"               
                        name="phoneNumber"
                        validators={['phoneRequired', 'invalidPhone']}
                        errorMessages={['Phone number is required', 'Invalid Phone number']}    
                        label="Phone Number *"             
                        fullWidth
                        
                        />} 
                        </InputMask>  
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextValidator
                            autoComplete="off"
                            id="websiteUrl"                
                            name="websiteUrl"             
                            value={websiteUrl}
                            fullWidth
                            validators={['required']}
                            errorMessages={['Location Website is required']}  
                            onInput={ (e)=>setWebsiteUrl(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="Location Website *"
                        /> 
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextValidator
                            autoComplete="off"
                            id="addressLine1"                
                            name="addressLine1"             
                            value={addressLine1}
                            fullWidth
                            validators={['required']}
                            errorMessages={['Address Line 1 is required']}  
                            onInput={ (e)=>setAddressLine1(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="Address Line 1 *"
                        /> 
                    </Grid>

                    <Grid item xs={12} sm={3}>
                    <TextValidator
                            autoComplete="off"
                            id="addressLine2"                
                            name="addressLine2"             
                            value={addressLine2}
                            fullWidth
                            onInput={ (e)=>setAddressLine2(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="Address Line 2"
                        /> 
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextValidator
                            autoComplete="off"
                            id="city"                
                            name="city"             
                            value={city}
                            validators={['required']}
                            errorMessages={['City is required']}  
                            onInput={ (e)=>setCity(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="City *"
                        /> 
                    </Grid>
                    
                    <Grid item xs={12} sm={2}>
                        <InputLabel id="stateLabel">State *</InputLabel>
                        <Select
                        labelId="state"
                        id="state"
                        value={state}
                        onChange={ e=>setLocationState(e.target.value)}
                        onBlur={()=>_updateLoc(onpracticeId)}
                        fullWidth              
                        >   
                        <MenuItem value="AL">Alabama</MenuItem>
                        <MenuItem value="AK">Alaska</MenuItem>
                        <MenuItem value="AZ">Arizona</MenuItem>
                        <MenuItem value="AR">Arkansas</MenuItem>
                        <MenuItem value="CA">California</MenuItem>
                        <MenuItem value="CO">Colorado</MenuItem>
                        <MenuItem value="CT">Connecticut</MenuItem>
                        <MenuItem value="DE">Delaware</MenuItem>
                        <MenuItem value="FL">Florida</MenuItem>
                        <MenuItem value="GA">Georgia</MenuItem>
                        <MenuItem value="HI">Hawaii</MenuItem>
                        <MenuItem value="ID">Idaho</MenuItem>
                        <MenuItem value="IL">Illinois</MenuItem>
                        <MenuItem value="IN">Indiana</MenuItem>
                        <MenuItem value="IA">Iowa</MenuItem>
                        <MenuItem value="KS">Kansas</MenuItem>
                        <MenuItem value="KY">Kentucky</MenuItem>
                        <MenuItem value="LA">Louisiana</MenuItem>
                        <MenuItem value="ME">Maine</MenuItem>
                        <MenuItem value="MD">Maryland</MenuItem>
                        <MenuItem value="MA">Massachusetts</MenuItem>
                        <MenuItem value="MI">Michigan</MenuItem>
                        <MenuItem value="MN">Minnesota</MenuItem>
                        <MenuItem value="MS">Mississippi</MenuItem>
                        <MenuItem value="MO">Missouri</MenuItem>
                        <MenuItem value="MT">Montana</MenuItem>
                        <MenuItem value="NE">Nebraska</MenuItem>
                        <MenuItem value="NV">Nevada</MenuItem>
                        <MenuItem value="NH">New Hampshire</MenuItem>
                        <MenuItem value="NJ">New Jersey</MenuItem>
                        <MenuItem value="NM">New Mexico</MenuItem>
                        <MenuItem value="NY">New York</MenuItem>
                        <MenuItem value="NC">North Carolina</MenuItem>
                        <MenuItem value="ND">North Dakota</MenuItem>
                        <MenuItem value="OH">Ohio</MenuItem>
                        <MenuItem value="OK">Oklahoma</MenuItem>
                        <MenuItem value="OR">Oregon</MenuItem>
                        <MenuItem value="PA">Pennsylvania</MenuItem>
                        <MenuItem value="RI">Rhode Island</MenuItem>
                        <MenuItem value="SC">South Carolina</MenuItem>
                        <MenuItem value="SD">South Dakota</MenuItem>
                        <MenuItem value="TN">Tennessee</MenuItem>
                        <MenuItem value="TX">Texas</MenuItem>
                        <MenuItem value="UT">Utah</MenuItem>
                        <MenuItem value="VT">Vermont</MenuItem>
                        <MenuItem value="VA">Virgina</MenuItem>
                        <MenuItem value="WA">Washington</MenuItem>
                        <MenuItem value="WV">West Virginia</MenuItem>
                        <MenuItem value="WI">Wisconsin</MenuItem>
                        <MenuItem value="WY">Wyoming</MenuItem>                
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={1}>
                        <TextValidator
                            autoComplete="off"
                            id="zip"                
                            name="zip"             
                            value={zip}
                            validators={['required']}
                            errorMessages={['Zip is required']}  
                            onInput={ (e)=>setZip(e.target.value)}
                            onBlur={()=>_updateLoc(onpracticeId)}            
                            label="Zip *"
                        /> 
                    </Grid>               

                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2} id="grid-buttons"> 
                        <Grid item>
                            <Button
                            id="btnDeleteLocation"
                            type="reset"
                            variant="contained"
                            size="small"
                            onClick={ (e) => { _handleLocationDeleteCheck(e); } }
                            endIcon={<Icon>delete</Icon>}>
                            DEL
                            </Button>
                        </Grid>
                        
                        <Grid item>
                            <Button
                            ref={btnRef}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>save</Icon>}>
                            Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                </ValidatorForm>

            <Divider style={{border: "solid 1px #979797"}}/>
            </Box>

            <Dialog
                maxWidth="md"
                open={openWarningDialog}
                onClose={_handleCloseWarningDialog}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">Delete Location Warning</DialogTitle>
                <DialogContent>
                <DialogContentText>
                This is the only associated location for this practice and cannot be deleted
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={_handleCloseWarningDialog} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>   

            <Dialog
                maxWidth="md"
                open={openConfirmDialog}
                onClose={_handleCloseConfirmDialog}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">{"This action cannot be undone."}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this entry?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={_handleLocationDelete} color="primary">
                    Yes
                </Button>
                <Button onClick={_handleCloseConfirmDialog} color="primary" autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>   
         </>
        }
        </>
    )
}