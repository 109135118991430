import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import { withTranslation } from 'react-i18next';
import Storage from '../../storage';
import DataGrid from '../../Components/DataGrid/DataGrid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Overlay from '../../Components/Overlay/Overlay';
import Box from '@material-ui/core/Box';
import './CoverSearch.scss';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};  

class CoverSearch extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        data : [],
        redirectToLogin: false,
        showLoadingOverlay: false,
        coverKey : '',
        openSnackBar : false,
        SnackbarMessage : '',
        errorSeverity : 'success'
        
    };    
    this.logoUrl = Utils.getLogoUrl();
  };

  
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
        try {
          this.setState(() => ({ showLoadingOverlay: true }));  
          const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          this.setState(() => ({ showLoadingOverlay: false }));  
        }
        catch {
            this.setState(() => ({
                redirectToLogin: true
            }));
        }

    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }

  

   _handleToggle  =  async () =>
   {
    if (this.state.coverKey !== "")
    {     
        try {  
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            this.setState(() => ({ showLoadingOverlay: true }));
            const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKey/${this.state.coverKey}?secretCode=${apiKey}`;
            const res =  await axios.get(url);

            if (res?.data) {
              let d = res.data;
              const newState= d.map((x)=> { return {...x,
                 arts: x.arts === "Enable" ? "On" : "Off",
                 cars: x.cars === "Enable" ? "On" : "Off",
                 dips: x.dips === "Enable" ? "On" : "Off",
                 nars: x.nars === "Enable" ? "On" : "Off",
                 noShow: x.noShow === "Enable" ? "On" : "Off",
                 recall: x.recall === "Enable" ? "On" : "Off",
                 welcomeEmail: x.welcomeEmail === "Enable" ? "On" : "Off",
                }
                 ; });
              this.setState(() => ({ data: newState}));
              if (d.length === 0)
                this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info',openSnackBar: true }));

            }     
            else   
              this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));

            this.setState(() => ({ showLoadingOverlay: false }));   
        }
        catch {
            this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the coverkey.'})); 
        } 
    }
    else
    {
         this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
    }       
   }

  _searchCoverKey = async (coverkey) => {
    if (this.state.coverKey !== "")
    {     
      try {      
          const apiKey = `${process.env.REACT_APP_API_KEY}`;
          this.setState(() => ({ showLoadingOverlay: true }));
          //let ck = Storage.getItem("CoverKeyItem");
          const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKey/${Number(coverkey)}?secretCode=${apiKey}`;
          const res =  await axios.get(url);
          if (res?.data) {
              let d = res.data;
              const newState= d.map((x)=> { return {...x,
                arts: x.arts === "Enable" ? "On" : "Off",
                cars: x.cars === "Enable" ? "On" : "Off",
                dips: x.dips === "Enable" ? "On" : "Off",
                nars: x.nars === "Enable" ? "On" : "Off",
                noShow: x.noShow === "Enable" ? "On" : "Off",
                recall: x.recall === "Enable" ? "On" : "Off",
                welcomeEmail: x.welcomeEmail === "Enable" ? "On" : "Off",
               }
                ; });
              this.setState(() => ({ data: newState}));
              if (d.length === 0)
                this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info',openSnackBar: true }));

          }     
          else   
             this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));

          this.setState(() => ({ showLoadingOverlay: false }));
      }
      catch {
          this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the coverkey.'})); 
      }  
    }
    else
    {
         this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
    }            
  }

  _handleTextFieldChange = (ck) => {
    this.setState(() => ({ coverKey: ck }));   
    };
    /**
   * Renders the component.
   */
  render() {
    const {
      redirectToLogin,
      data,
      showLoadingOverlay,
      openSnackBar,
      SnackbarMessage,
      errorSeverity,
      coverKey     
    } = this.state;

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }

    return (
        <div>
          <Box p={3} bgcolor="background.paper">
             <Typography>
                Search a cover key and all it's location.
             </Typography>  
          </Box>
          <Divider/>          
          <Box p={3} bgcolor="background.paper">
          <ValidatorForm onSubmit={this._handleToggle} onError={errors => console.log(errors)}> 
          <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>   
          <Grid item xs={12} sm={3} >        
              <TextValidator
                  validators={['required', 'isPositive']}
                  errorMessages={['this field is required','Must be numeric and greater than zero']}                
                  fullWidth
                  value={coverKey}
                  onInput={ (e)=>this._handleTextFieldChange(e.target.value)}  
                  onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    this._searchCoverKey(ev.target.value);
                    ev.preventDefault();
                  }
              }}            
              label="Search Coverkey"
              InputProps={{
                  endAdornment: (
                  <InputAdornment>
                      <IconButton type="submit">
                      <SearchIcon />
                      </IconButton>                      
                  </InputAdornment>
                  )
              }}
              />   
              </Grid>   
              <Grid item xs={12} sm={3} > 
                <Button variant="contained" color="primary" type="submit">
                        Search
                </Button>                 
              </Grid>   
            </Grid>   
            </ValidatorForm>              
          </Box>     
          <Box p={1} bgcolor="background.paper">
            <DataGrid show={true} data={data}/>
          </Box> 
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>      
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
            <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
          </Snackbar>                    
        </div>
    ); 
  }
        
}

export default withTranslation()(CoverSearch);