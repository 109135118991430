import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import Storage from '../../storage';
import SendEmailForm from '../../Components/SendEmailForm/SendEmailForm';
import Box from '@material-ui/core/Box';
import Overlay from '../../Components/Overlay/Overlay';

class CoverSearch extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        showLoadingOverlay: false,
        redirectToLogin: false
        
    };    
    this.logoUrl = Utils.getLogoUrl();
  };
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
        try {
          this.setState(() => ({ showLoadingOverlay: true }));  
          const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          this.setState(() => ({ showLoadingOverlay: false }));  
        }
        catch {
            this.setState(() => ({
                redirectToLogin: true
            }));
        }

    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }
  
  _searchCoverKey = async (coverkey) => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    console.log('value', coverkey);
    let ck = coverkey;
    const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKey/${ck}?secretCode=${apiKey}`;
    const res =  await axios.get(url);

    if (res?.data) {
      let d = res.data;
        this.setState(() => ({ data: d}));
      console.log('value', coverkey);
    }     

  }
    /**
   * Renders the component.
   */
  render() {
    const {
      showLoadingOverlay,
      redirectToLogin
    } = this.state;

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }

    return (
        <div>
          <Box p={1} bgcolor="background.paper">
            <SendEmailForm/>
          </Box> 
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay> 
        </div>
    ); 
  }
        
}

export default CoverSearch;