export const ComponentType = Object.freeze({
   UnManaged: 0,
   MessageBox: 1,
   ConfirmBox: 2,
   ErrorBox: 3,
   Overlay: 4,
   Snackbar: 5
});

export const arrayCopy = (arr) => {
   return JSON.parse(JSON.stringify(arr));
}

export const arraySort = (arr, fieldName, desc) => {
   arr.sort((a,b) => {
      let s1 = (a[fieldName] == null ? '' : a[fieldName]).toString().toLowerCase();
      let s2 = (b[fieldName] == null ? '' : b[fieldName]).toString().toLowerCase();
      
      return s1 > s2 ? 1 : (s2 > s1 ? -1 : 0);
   });
   
   if (desc) arr.reverse();

   return arr;
};

export const arrayContains = (arr, value) => {
   return arr.some(x => x === value);
};

export const objectsAreEqual = (obj1, obj2) => {
   let eq1 = JSON.stringify(obj1);
   let eq2 = JSON.stringify(obj2);
   return eq1 === eq2;
 }

 export const arraysAreEqual = (arr1, arr2, fieldName) => {
   const a = arraySort(arr1, fieldName);
   const b = arraySort(arr2, fieldName);
   return objectsAreEqual(a, b);
}

export const createGuid = () => {  
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
       var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
       return v.toString(16);  
    });  
 } 

export const formatBytes = (bytes, decimals = 2) => {
   if (bytes === 0) return '0 Bytes';

   const k = 1024;
   const dm = decimals < 0 ? 0 : decimals;
   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

   const i = Math.floor(Math.log(bytes) / Math.log(k));

   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}