import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import '../PatientInfo.scss';

export default function PatientInfo_Validation(props) {
 
  const [model, setModel] = useState(props.model);

  const onChange = (fieldName, value) => {
    let obj = {};
    obj[fieldName] = value;
    setModel({...model, ...obj});

    props.onChange(fieldName, value);
  };
  
  const item = (fieldName, fieldValue, fieldLabel) => {
    let locked = props.isLocked(fieldName);

    return (
            <Grid item xs={12} sm={6} className={`lockable ${locked ? "locked" : ""}`}>
              <FormControl fullWidth>
                <InputLabel id={fieldName}>{fieldLabel}</InputLabel>
                <Select
                  labelId={fieldName}
                  id={fieldName}
                  value={fieldValue} 
                  disabled={locked}
                  onChange={e => {
                    onChange(fieldName, e.target.value);
                  }}
                >
                <MenuItem value={0}>Don't Ask {locked && <Icon>lock</Icon>}</MenuItem>
                <MenuItem value={1}>Required {locked && <Icon>lock</Icon>}</MenuItem>
                 
                </Select>
              </FormControl>
            </Grid>
    );
  };

  useEffect(() => {
    setModel(props.model);
  }, [props.model]);

  return (
      <div>
        <h3>Patient Validation</h3>

        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {item("fname", 1, "First Name")}
          {item("lname", 1, "Last Name")}
        </Grid>   
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {item("vPhone", model.vPhone, "Phone Number")}
          {item("vdob", model.vdob, "Date of Birth")}
        </Grid> 
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {item("vL4DSSN", model.vL4DSSN, "Last 4 of SS#")}
          {item("vEmail", model.vEmail, "Email")}
        </Grid> 
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {item("vWholeAddress", model.vWholeAddress, "Whole Address")}
        </Grid> 
      </div>
      );
};

