import React, { useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import Overlay from '../Overlay/Overlay';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 200,
  },  
}));

export default function UserManagementRoles() {

  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');

  const [userNameHasChange, setUserNameHasChange] = useState(false);
  const [userNameExists, setUserNameExists] = useState(false);
  const [emailHasChange, setEmailHasChange] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClearText = (event) => {
    try{event.preventDefault();}catch{}
    setUserName("");
    setPassword("");
    setEmail("");
  };

  const UserNameOnInput = (userName) => {
    setUserNameHasChange(true);
    setUserName(userName);
    setUserNameExists(true);
    setPassword("");
  };

  const EmailOnInput = (email) => {
    setEmailHasChange(true);
    setEmail(email);
    setEmailExists(true);
  };

  const PasswordOnInput = (password) => {
    setPassword(password);
  }

  const DisableSubmitButton = () => {
    return (userNameExists
      || emailExists
      || ((userName ?? '').length === 0)
      || ((email ?? '').length === 0)
      || ((password ?? '').length === 0)
      || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
  }
  
  const _handleSubmit = async () => {
    //event.preventDefault();
    setshowLoadingOverlay(true);
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const data = {'UserName':userName , 'Password': password, 'Email' : email};
    const url = `${process.env.REACT_APP_ADMIN_API}/addUser?secretCode=${apiKey}`;
    const res = await axios.post(url, data);

    if (res?.data) {
      let data = res.data;
      let message = 'User added successfully.';
      setErrorSeverity("success");
      if (data.errors && (data.errors.length > 0))
      {
        message = 'Unable add this user.';
        setErrorSeverity("error");
      }
      setOpenSnackBar(true);
      setSnackbarMessage(message);
      setUserName('');
      setPassword('');
      setEmail('');
    }
    setshowLoadingOverlay(false);
  }

  const UserNameChange = async  () => {   
    if (userNameHasChange && ((userName ?? '').length > 0))
    {
      setshowLoadingOverlay(true);
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_ADMIN_API}/userNameCheck/${userName}?secretCode=${apiKey}`;
      try
      {
        const res =  await axios.get(url);
        if (res?.data) {
          setUserNameExists(false);
        }
        else   
        {
          let message = 'User already exists!';
          setErrorSeverity('error');
          setOpenSnackBar(true);
          setSnackbarMessage(message);
          setUserNameExists(true);
        }
      }catch{
        let message = 'Invalid user name!';
        setErrorSeverity('error');
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
      setshowLoadingOverlay(false);
    }
  }

  const EmailChange = async  () => {   
    if (emailHasChange && ((email ?? '').length > 0))
    {
      setshowLoadingOverlay(true);
      try
      {
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const url = `${process.env.REACT_APP_ADMIN_API}/emailCheck/${email}?secretCode=${apiKey}`;
        const res =  await axios.get(url);
        if (res?.data) {
          setEmailExists(false);
        }
        else   
        {
          let message = 'Email already exists!';
          setErrorSeverity('error');
          setOpenSnackBar(true);
          setSnackbarMessage(message);
          setEmailExists(true);
        }
      }catch{
        let message = 'Invalid email!';
        setErrorSeverity('error');
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
    setshowLoadingOverlay(false);
   }

 }

   function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
   };

  return (
    <React.Fragment>
      <Box p={3} bgcolor="background.paper">
          <Typography variant="h6" gutterBottom>
            Add Users
          </Typography>        
        <ValidatorForm onSubmit={_handleSubmit} onError={errors => console.log(errors)}>
          <Grid container justify="space-evenly"
                      alignItems="center" spacing={1} >
            <Grid item xs={6} sm={2}>
              <TextValidator
                  required
                  validators={['required']}
                  errorMessages={['this field is required']}
                  fullWidth
                  value={userName}
                  onInput={ (e)=>UserNameOnInput(e.target.value)}
                  onBlur={ () => { UserNameChange(); }}
                  label="User Name"
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
              />
            </Grid>                       
            <Grid item xs={6} sm={2}>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="passwordText">Password</InputLabel>
                <Input
                  id="passwordText"
                  type={values.showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={ e=>PasswordOnInput(e.target.value) }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </FormControl>
            </Grid>                        
            <Grid item xs={6} sm={2}>
              <TextValidator
                  required
                  label="Email Address"
                  fullWidth      
                  value={email}
                  onInput={ e=>EmailOnInput(e.target.value)}
                  onBlur={ () => { EmailChange(); } }
                  validators={['required', 'isEmail']}
                  errorMessages={['this field is required', 'email is not valid']}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
              />
            </Grid>
            <Grid item xs={6} sm={3} direction="row">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Icon>send</Icon>}
                disabled={ DisableSubmitButton() }
              >
                  Submit
              </Button>    
              <Button
                type="cancel"
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={ (e) => { handleClearText(e); } }
                endIcon={<Icon>close</Icon>}
              >
                  Clear
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>      
      <Divider/>
      <Overlay show={showLoadingOverlay}>
         <i className="spinner-eclipse"></i>
       </Overlay>    
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
            <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
      </Snackbar>             
    </React.Fragment>
  );
}