import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import './ProviderDetails.scss';
import ProviderEditDeleteForm from './ProviderEditDeleteForm';
import Overlay from '../Overlay/Overlay';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Utils from '../../utils';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  plainText: {
    border: 'none',
    background: 'none',
    width: '140px',
    paddingTop: '5px',
    fontWeight: 'normal',

  },
  divPhone: {
    display: 'block'
  }
}));

export default function ProviderDetails(props) {
  const classes = useStyles();
  const {collapseProvider, setCollapseProvider} = props;
  const [searchProvider, setProviderSearch] = useState('');
  const {showEditDelete, setEditDelete, validate, setValidate, setData, onboardingCode, isSmartBook} = props;
  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');

  const [fixedDataSource, setFixedDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const _handleCollapseProvider = () => {
    setCollapseProvider(!collapseProvider);
  };

  const _handleEditProvider = () => {
    setEditDelete(true);
  }

  const _handleViewProvider = () => {
    setEditDelete(false);
  }

  const _setOnboardingData = (obData) =>
  {
    setData({
      data:obData,
      onboardingCode: onboardingCode});
  }

  const setProvidersData = (ds) =>{
    let data;
    if (searchProvider !== '') { 
      data = ds.filter(d => d.firstName.toLowerCase().indexOf(searchProvider.toLocaleLowerCase()) >= 0);
    } else {
      data = ds;
    }

    setFixedDataSource(ds);
    setFilteredDataSource(data);
  };

  const filterProviders = (searchString) => {
    setProviderSearch(searchString);
  };

  const _handleCallBack = async (childData, action) => {
    if (childData) {
      setshowLoadingOverlay(true);
      try {
        const url = props.isSmartBook ? `${process.env.REACT_APP_ONBOARDINGDATA_API}/save/${props.onboardingCode}` :  `${process.env.REACT_APP_NSBONBOARDINGDATA_API}/save/${props.onboardingCode}` ;
        const res = await axios.post(url, childData);
  
        if (res && res?.status === 200) {      
          setProvidersData(childData.providers);

          let message = (action === 'update' ? 'Provider details successfully saved.' : 'Provider details successfully deleted');
          setErrorSeverity("success");
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        } 
        else {
          let message = (action === 'update' ? 'Error in saving provider details.' : 'Error in deleting provider details.');
          setErrorSeverity("error");
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        }
      } catch {
        let message = 'Unexpected error in saving provider details.';
        setErrorSeverity("error");
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
      setshowLoadingOverlay(false);
    } else {
      let message = 'Invalid onboarding data.';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
  }
    
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
   };  

   useEffect(() => {
    setProvidersData(props.data);
  }, [props.data, searchProvider]);
  
  return (
    <>
     <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      p={0}
      >
        <ListItem>
          <ListItemText onClick={_handleCollapseProvider}>
            <span className="listHeader"><b>{props.headerName}</b></span>
          </ListItemText>

          {//***** Show Search UI if on view mode *************       
            !showEditDelete &&  
             <div className="search" style={{display: collapseProvider ? 'block' : 'none' }}>
              <FormControl variant="outlined">
                 <TextField
                   value={searchProvider}
                   onChange={e => filterProviders(e.target.value)}
                   placeholder="Search by First Name"
                   className="form-input"
                   InputProps={{
                     startAdornment: (
                     <InputAdornment>
                       <IconButton type="submit">
                         <SearchIcon />
                       </IconButton>                      
                     </InputAdornment>
                     ),
                   }}
                 size="small"
                 />
               </FormControl>
             </div>
          }

          {collapseProvider ? <ExpandLess onClick={_handleCollapseProvider} /> : <ExpandMore onClick={_handleCollapseProvider}/>}
        </ListItem>
      </List>
      <Divider />
      <Collapse in={collapseProvider} timeout="auto">
        { showEditDelete && 
            <>
              <Box bgcolor="background.paper" p={2}>
                <Typography variant="h5"> 
                The following information is for the Provider contacts of one location:
                </Typography>
              </Box>
              <Divider/>
            </>
        }

        <List component="div" disablePadding>
          { 
            showEditDelete ? 
              fixedDataSource.map((prov, i) => 
                <ProviderEditDeleteForm
                  setOnboardingData={_setOnboardingData}
                  validate={validate}
                  setValidate={setValidate} 
                  data={prov} 
                  parentCallback={_handleCallBack} 
                  completeData={props.completeData} 
                  key={prov.profileIdentifier}/>
              )
            :
              filteredDataSource.map(prov => 
                <ListItem key={prov.profileIdentifier} button className={classes.nested}>
                  <ListItemText >
                    <div className="row">
                      <div className="column">
                        <div><b>Title: </b> {prov.title}</div>
                        <div><b>Provider: First Name </b> {prov.firstName}</div>
                        <div><b>Provider: Last Name </b> {prov.lastName}</div>
                      </div>
                      <div className="column">
                        <div><b>Role: </b> {prov.role}</div>
                        <div><b>Email: </b> {prov.email}</div>
                        {/* <div><b>Phone Number: </b> {'+1' + Utils.maskPhone(prov.phone)}</div> */}

                        <div className={classes.divPhone}><b>Phone Number:</b>{<InputMask className={classes.plainText} mask="+1(999) 999-9999" value={prov.phone} readOnly={true}></InputMask>}</div>
                        {/* <div><b>Phone Number: </b> {'+1' + prov.phone}</div> */}

                        
                        {/* <div><b>NPI: </b> {prov.npiNumber}</div> */}
                      </div>
                    </div>
                  </ListItemText>
                </ListItem>
              )
          }
        </List>

        {
          showEditDelete ?  
            <div className="clsButton">
              <Box p={2} bgcolor="background.paper" onClick={_handleViewProvider}>
                <Button fontSize={16} variant="outlined">VIEW PROVIDER DETAILS</Button>
              </Box>
            </div>
          :
            <div className="clsButton">
              <Box p={2} bgcolor="background.paper" onClick={_handleEditProvider}>
                <Button fontSize={16} variant="outlined">EDIT PROVIDER DETAILS</Button>
              </Box>
            </div>
        }
      </Collapse>

      <Overlay show={showLoadingOverlay}><i className="spinner-eclipse"></i></Overlay>    
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
        {SnackbarMessage} 
        </Alert>
      </Snackbar>  
    </>
  );
}