import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { arrayCopy, arraySort } from '../../Core/coreLib';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./Filters.scss";
import { useFiltersContext, useRequest, useUIContext } from '../../contextHooks';

export default function Filters() {
  const componentId = "filters";
  const ui = useUIContext(componentId);
  const Request = useRequest();
  
  const filtersContext = useFiltersContext(componentId, null, (newContext) => {   
    //On filter values updated
      let noCover = newContext.coverKey==0;
      setCoverKey(newContext.coverKey);
      setLocKey(noCover ? -1 : newContext.locKey);
      setAcctKey(noCover ? -1 : newContext.acctKey);
      setCoverLabel(noCover ? "Select Cover" : "Cover");

      setCovers(newContext.covers);
      
      let l = initLocations(newContext.locations);
      let p = initProviders(newContext.providers);
      setLocations(l);
      setProviders(p);
  });  

  const initLocations = (arr) => {
    if (filtersContext.Values.coverKey<=0) return [];

    let l = arrayCopy(arr);
    if (!arr.some(x => x.locKey == 0)) {
      l.splice(0,0,{
        locKey: 0,
        orgName: "All Locations"
      });
    }
    return l;
  };

  const initProviders = (arr) => {
    if (filtersContext.Values.coverKey<=0) return [];

    let p = arrayCopy(arr);
    if (!arr.some(x => x.acctKey == 0)) {
      p.splice(0,0,{
        acctKey: 0,
        firstName: "All Providers",
        lastName: ""
      });
    }
    return p;
  };

  const [coverKey, setCoverKey] = useState(-1);
  const [locKey, setLocKey] = useState(-1);
  const [acctKey, setAcctKey] = useState(-1);
  const [covers, setCovers] = useState(filtersContext.Values.covers);
  const [locations, setLocations] = useState(initLocations(filtersContext.Values.locations));
  const [providers, setProviders] = useState(initProviders(filtersContext.Values.providers));

  const [coverLabel, setCoverLabel] = useState("");
  const [locDisable, setLocDisable] = useState(true);
  const [acctDisable, setAcctDisable] = useState(true);

  const setFilterValues = (v, raiseFiltersChangedEvent, source) => {
    filtersContext.SetValues(v);
    if (raiseFiltersChangedEvent) filtersContext.UpdateClients_FiltersChanged(source);
  };

  const loadCoversData = async () => {
    ui.ShowOverlay();

    let res = await Request.getCovers();

    if (res.success) {
      setFilterValues({
        coverKey: 0,
        covers: arraySort(res.data, "orgName"),
        locations: [],
        providers: []
      }, true);
    } else if (!res.cancelled) {
      ui.ErrorBox(res.error, "Getting Covers");
    }    

    ui.HideOverlay();
  };

  const loadSubData = async () => {
    let keys = {
      locKey: 0,
      acctKey: 0
    };

    if (filtersContext.Values.coverKey != 0) {
      ui.ShowOverlay();

      let res = await Promise.all([
        Request.getLocationsByCoverKey({coverKey: filtersContext.Values.coverKey}),
        Request.getProvidersByCoverKey({coverKey: filtersContext.Values.coverKey})
      ]);
  
      if (!res.some(x => !x.success)) {
        setFilterValues({
          ...keys,
          locations: arraySort(res[0].data, "orgName"),
          providers: arraySort(res[1].data, "lastName")
        }, true);
      } else {
        let _err = res.filter(x => !x.success).map(x => x.error);
        let err = <>{_err.map(x => <div>{x}</div>)}</>

        ui.ErrorBox(err, "Getting Locations and Providers");
      }
      
      ui.HideOverlay();
    } else {
      setFilterValues({
        ...keys,
        locations: [],
        providers: []
      }, true);
    } 
  };

  const coverChanged = (ckey) => {
    setFilterValues({coverKey: ckey});
    loadSubData();
  }; 

  const locationChanged = (lkey) => {
    setFilterValues({locKey: lkey}, true, "location");
  }; 

  const providerChanged = (akey) => {
    setFilterValues({acctKey: akey}, true, "provider");
  };

  const getlabel = (d, action) => {
    let ret = "";
    if (action == "cover" && covers.length > 0) {
      let x = covers.filter(x => x.coverKey == d)[0];
      ret = `${x.coverKey}: ${x.orgName ?? "[No OrgName]"}`;  
    } else if (action == "location" && locations.length > 0) {
      let x = locations.filter(x => x.locKey == d)[0];      
      if (x) {
        let n = x.orgName ?? "[No OrgName]";        
        ret = d==0 ? n : `${x.locKey}: ${n}`;  
      }
    } else if (action == "provider" && providers.length > 0) {
      let x = providers.filter(x => x.acctKey == d)[0];
      if (x) {
        let _n = (`${x.firstName ?? ""} ${x.lastName ?? ""}`).trim();
        let n = _n ? _n : "[No Name]";
        ret = d==0 ? n : `${x.acctKey}: ${n}`;
      }
    }
    return ret;
  };
  
  useEffect(() => {
    loadCoversData();
  }, []);

  useEffect(() => {
    let noCover = coverKey == 0;
    
    if (noCover) {
      setLocKey(p=>-1);
      setAcctKey(p=>
        {
          return -1;
        });  
    }

    setLocDisable(noCover);
    setAcctDisable(noCover);
  }, [coverKey]);
  
  if (covers) {
    return (
      <div className="filters">
        <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
          
            <Grid item xs={12} sm={4}>
               <FormControl fullWidth>
                <Autocomplete
                    id="Cover"
                    options={covers.map(x => x.coverKey)}
                    getOptionLabel={(x) => getlabel(x, "cover")}
                    onChange={(event, newValue) => {
                      coverChanged(newValue ?? 0);
                  }}
                    renderInput={(params) => <TextField {...params} label={coverLabel} variant="outlined" />}
                  />
                </FormControl>
            </Grid>
            <Grid item xs={12}  sm={4}>
            <FormControl fullWidth>
              <Autocomplete
                    id="Location"
                    disabled={locDisable}
                    options={locations.map(x => x.locKey)}
                    getOptionLabel={(x) => getlabel(x, "location")}
                    value={locKey}
                    onChange={(event, newValue) => {
                      locationChanged(newValue ?? 0);
                    }}
                    renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
                  />
              </FormControl>
            </Grid> 
            <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Autocomplete
                    id="Provider"
                    disabled={acctDisable}
                    options={providers.map(x => x.acctKey)}
                    getOptionLabel={(x) => getlabel(x, "provider")}
                    value={acctKey}
                    onChange={(event, newValue) => {
                      providerChanged(newValue ?? 0);
                    }}
                    renderInput={(params) => <TextField {...params} label="Provider" variant="outlined" />}
                  />
              </FormControl>
            </Grid>                  
          </Grid>
        </div>
    
    );
  } else {
      return (
        <>
          {ui.uiJSX.requestingDataJSX}
        </>);
  }
}
