import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Overlay from '../../Components/Overlay/Overlay';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';

export default function UserManagementRoles(props) {
  const{
    columns
  } = props;
  const [rows, setRows] = useState([]);
  
  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');
  
  const handlePurge = async (oldData) => {
    setshowLoadingOverlay(true);
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const userIds = [];
    userIds.push(oldData.userID);
    const url = `${process.env.REACT_APP_ADMIN_API}/deleteUser?secretCode=${apiKey}`;
    const res = await axios.post(url, userIds);
    if(res?.data){
      _loadUsersData();
      setErrorSeverity("success");
      setSnackbarMessage('User(s) deleted successfully.');
      setOpenSnackBar(true);
    }else{
      setErrorSeverity("error");
      setSnackbarMessage('An error occurred while attempting to delete user(s).');
      setOpenSnackBar(true);
    }
    setshowLoadingOverlay(false);
  }

  const handleUpdate = async (newData, oldData) => {
    setshowLoadingOverlay(true);
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const isAdmin = (newData['roles'] === "1" );
    const postData = {userId: oldData.userID, email : newData.email , roles:[]};
    postData.roles.push(isAdmin ? "Admin" : "Customer Support");
/*    const isAdmin = (newData['roles'] === 1 );
    postData.roles.push({Admin : isAdmin});
     columns.forEach((colItem, colIndex) => {
      if(colItem.field !== 'userName')
      {
        if(newData[colItem.field] === true || newData[colItem.field] === 'true' || isAdmin)
        {
          postData.roles.push(colItem.field);
        }
      }
    })
 */
    const url = `${process.env.REACT_APP_ADMIN_API}/updateUserRoles?secretCode=${apiKey}`;
    const res = await axios.post(url, postData);
    if(res?.data){
      _loadUsersData();
      setErrorSeverity("success");
      setSnackbarMessage('User updated successfully.');
      setOpenSnackBar(true);
    }else{
      setErrorSeverity("error");
      setSnackbarMessage('An error occurred while attempting to update user.');
      setOpenSnackBar(true);
    }
    setshowLoadingOverlay(false);
  }

  const _loadUsersData = async () =>
  {
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_ADMIN_API}/getUsersAndRoles?secretCode=${apiKey}`;
      const res = await axios.get(url);
      if(res?.data){
        var finalData = [];
        var rowData = JSON.parse(JSON.stringify(res?.data));
        rowData.forEach((item, index) => {
          var roleItem = item.roles.includes("Admin") ? 1 : 0;
          var rowItem = {userID : item.userID , userName : item.userName , email: item.email, roles : roleItem };
          finalData.push(rowItem);
        });
/*         rowData.forEach((item, index) =>
          columns.forEach((colItem, colIndex) => {
            if(colItem.field !== 'userName')
            {
              if(item.roles.includes(colItem.field))
              {
                item[colItem.field] = true;
              }
              else
              {
                item[colItem.field] = false;
              }
            }
          })
        ); */
        setRows(finalData);
      }
    }catch{
      console.log('error');
    }
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  useEffect(() => {
    const _setRowsOnLoad = async () => {
      await _loadUsersData();
    };
    _setRowsOnLoad()
  // eslint-disable-next-line
  }, [columns]);
  
  return (
    <React.Fragment>
      <Box p={1} bgcolor="background.paper">
        <div style={{ height: 400, width: '100%' }}>
          <MaterialTable
            height="200"
            columns={columns}
            data={rows}
            title='Change User Roles'
            options={{
              pageSize: 10,
              pageSizeOptions: [],
              toolbar: true,
              paging: true
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    handleUpdate(newData, oldData);
                    resolve();
                }),
              onRowDelete: oldData =>
                handlePurge(oldData)
            }}            
          />
        </div>
      </Box>
      <Overlay show={showLoadingOverlay}>
        <i className="spinner-eclipse"></i>
      </Overlay>    
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
        {SnackbarMessage} 
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}