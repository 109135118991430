
export const RepeatType = {
    Once: "once",
    Weekly: "weekly",
    BiWeekly: "bi-weekly",
    Monthly: "monthly"
};

// const convertUTCDateToLocalDate = (date) => {
//     var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

//     var offset = date.getTimezoneOffset() / 60;
//     var hours = date.getHours();

//     newDate.setHours(hours - offset);

//     return newDate;   
// }

export const CreateEventsFromBlocks = (blocks) => {
    const events = blocks.map((x) => {
        const configData = JSON.parse(x.config?.data);

        const blockStart = new Date(x.blockStart);
        const blockEnd = new Date(x.blockEnd);
        
        const title = x.isBlackout ? "Blackout" :
                x.isBreak ? "Break" :
                configData?.AnyAppointment ? "Any Appointment" : "Selected Appointments";
        
        const textColor = x.isBreak ? "black" : "white";
        const bgColor = x.isBlackout ? "dimgray" : 
                        x.isBreak ? "lightgray" : 
                        x.config?.fromOnboarding ? "royal blue" : "rgb(63,81,181)";

        let ret = {
            title:  title,
            backgroundColor: bgColor,
            textColor: textColor,
            extendedProps: { 
                selected: false, 
                isBreak: x.isBreak,
                fromOnboarding: x.config?.fromOnboarding,
                anyAppointment: configData?.AnyAppointment,
                blkTableType: x.event_BlkTableType, 
                apptTypes: (configData?.AppointmentTypeList ?? []).map((x) => { return { name: x.Name, length: x.Length }; }),
                config: x.config,
                editSingleEvent: new Date(blockStart.getFullYear(), blockStart.getMonth(), blockStart.getDate())
             },
            groupId: x.config.groupId,
            start: blockStart,
            end: blockEnd
        };

        return ret;
    });


    return events;
}