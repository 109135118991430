import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import TextField from '@material-ui/core/TextField';
import Enums from '../../enums';
import Utils from '../../utils';
import Storage from '../../storage';
import Constants from '../../constants';
import Overlay from '../../Components/Overlay/Overlay';
import './NormalLogin.scss';
import { Link } from 'react-router-dom';


/**
 * Represents the normal admin dashboard login component.
 */
class NormalLogin extends Component {
  constructor(props) {
    super(props);

    this._formErrorSetClean = {
      isValid: true,
      username: '',
      password: '',
      serverError: '',
      unauthorized: ''
    };
    this.state = {
      disableLoginBtn: false,
      formErrors: cloneDeep(this._formErrorSetClean),
      isVisible: false,
      username: '',
      password: '',
      redirectToCoverSearch: false,
      showLoadingOverlay: false
    };

  }

  _isFormValid = () => {
    let isValid = true;
    const {
      username,
      password
    } = this.state;
    const formErrors = cloneDeep(this._formErrorSetClean);

    if (!username) {
      isValid = false;
      formErrors.username = 'Username is required';
    }

    if (!password) {
      isValid = false;
      formErrors.password = 'Password is required';
    }

    this.setState(() => ({ formErrors: formErrors }));

    return isValid;
  }

  _onInputChange = (path, value) => {
    const state = {};
    Utils.update(state, path, value);

    this.setState(() => (state));
  }

  _onKeyPress = (e) => {
    const {
      key
    } = e;

    if (key === Enums.KeyboardKeys.enterKey) {
      this._onLogin();
    }
  }

  _onLogin = async () => {
    if (this._isFormValid()) {
      try {
        this.setState(() => ({ showLoadingOverlay: true }));

        this.setState(() => ({ disableLoginBtn: true }));

        const url = `${process.env.REACT_APP_ADMIN_API}/login`;
        const {
          username,
          password
        } = this.state;
        const data = {
          username: username,
          password: password
        };
        const res = await axios.post(url, data);
        const jwt = res.data;
        const jwtData = Utils.decodeJwt(jwt);


        let user = Storage.getItem(Constants.currUserKey);
        Storage.setItem("userRole", jwtData.role ?? []);
        Storage.setItem("userId", jwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'] ?? '')
        if (!user) {
          user = {
            jwt: ''
          };
        }

        user.jwt = jwt;
        Storage.setItem(Constants.currUserKey, user);
        this.setState(() => ({ redirectToCoverSearch: true }));
      }
      catch (error) {
        if ((error && !error.response) || (error && error.response.status === Enums.HttpStatusCodes.httpStatusUnauthorizedError)) {
          this.setState(() => ({
            disableLoginBtn: false,
            showLoadingOverlay: false,
            formErrors: {
              serverError: 'Invalid username or password'
            }
          }));
        }
        else if ((error && !error.response) || (error && error.response.status === Enums.HttpStatusCodes.httpStatusInternalServerError)) {
          this.setState(() => ({
            disableLoginBtn: false,
            showLoadingOverlay: false,
            formErrors: { serverError: 'Unable to login at this time' }
          }));
        }
      }
    }
  };

  async componentDidMount() {
    // this.setState(() => ({ showLoadingOverlay: false }));
  }
  /**
   * Renders the component.
   */
  render() {
    const {
      disableLoginBtn,
      formErrors,
      username,
      password,
      redirectToCoverSearch,
      showLoadingOverlay
    } = this.state;

    if (redirectToCoverSearch) {
      return <Redirect to={{ pathname: `/main` }} />;
    }

    //const { t } = this.props;

    return (
      <div className="login-norm">
        <h1 className="login-norm__title">{'4PC Admin Page'}</h1>
        <h3 className="login-norm__subtitle">{'Login to your account'}</h3>
        <form className="login-norm__form">
          <TextField
            className="login-norm__input"
            inputProps={{
              style: {
                padding: '12px 14px'
              }
            }}
            label={'Username'}
            value={username}
            variant="outlined"
            onChange={(e) => this._onInputChange(['username'], e.target.value)}
            onKeyPress={this._onKeyPress} />
          <span className={`login-norm__error ${formErrors.username ? 'login-norm__error--visible' : ''}`}>{formErrors.username}</span>
          <TextField
            className="login-norm__input"
            inputProps={{
              style: {
                padding: '12px 14px'
              }
            }}
            label={'Password'}
            type="password"
            value={password}
            variant="outlined"
            onChange={(e) => this._onInputChange(['password'], e.target.value)}
            onKeyPress={this._onKeyPress} />
          <span className={`login-norm__error login-norm__error--no-margin ${formErrors.password ? 'login-norm__error--visible' : ''}`}>{formErrors.password}</span>
          <span className={`login-norm__error login-norm__error--no-margin${formErrors.unauthorized ? 'login-norm__error--visible' : ''}`}>{formErrors.unauthorized}</span>
          <span className={`login-norm__error login-norm__error--no-margin ${formErrors.serverError ? 'login-norm__error--visible' : ''}`}>{formErrors.serverError}</span>
          <p />
          <p />
          <button
            className="login-norm__login"
            disabled={disableLoginBtn}
            type="button"
            onClick={this._onLogin}>
            {'Login'}
          </button>
            <Link to='/forgot'>
              <li className="forgot-password">Forgot password?</li>
          </Link>
        </form>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default NormalLogin;
