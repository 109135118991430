import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import { withTranslation } from 'react-i18next';
import Storage from '../../storage';
import Overlay from '../../Components/Overlay/Overlay';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MaterialTable from "material-table";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import './Provider.scss';

  const columns = [
    { field: 'id', title: '#', width: 50 , editable: 'never'},
    {
      title: 'Status',
      field: 'status',
      lookup: { 0: 'Off', 1: 'On' },
      width: 70
    },  
    { field: 'coverKey', title: 'CoverKey', width: 150 , editable: 'never' },
    { field: 'locKey', title: 'LocKey', width: 150  , editable: 'never'},
    { field: 'acctKey', title: 'AccountKey', width: 350 , editable: 'never' },
  ]; 

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};  

class Provider extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        data : [],
        redirectToLogin: false,
        showLoadingOverlay: false,
        acctKey : '',
        coverKey : 1,
        openSnackBar : false,
        SnackbarMessage : '',
        recordsFound : '',
        communicationType : 1,
        errorSeverity : 'success'
        
    };    
    this.logoUrl = Utils.getLogoUrl();
  };


  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
        try {
          this.setState(() => ({ showLoadingOverlay: true, acctKey: '', coverKey : '' }));  
          const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          this.setState(() => ({ showLoadingOverlay: false }));  
        }
        catch {
            this.setState(() => ({
                redirectToLogin: true
            }));
        }

    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }
  
  _handleAcctKeyChange = (ck) => {
    this.setState(() => ({ acctKey: ck }));   
  };

  _handleCoverKeyChange = (ck) => {
    this.setState(() => ({ coverKey: ck }));   
  };

  _handleTypedChange = (commType) => {
    this.setState(() => ({ communicationType: commType }));   
    };  

   _handleToggle  =  async () =>
   {
     if (this.state.communicationType !== 0 && this.state.coverKey !== "")
     {
        const apiKey = `${process.env.REACT_APP_API_KEY}`; 
        this.setState(() => ({ showLoadingOverlay: true }));
        const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchProviderCommunicationXrefByCoverKeyAndCommunicationIdAndAcctKey/${this.state.communicationType}/${this.state.coverKey}?secretCode=${apiKey}&acctKey=${this.state.acctKey}`;
        const res =  await axios.get(url);

        if (res?.data) {
            const d = res.data;
            this.setState(() => ({ data: d}));
        }     
        this.setState(() => ({ showLoadingOverlay: false })); 
     }
     else
     {
          this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
     }      

   }

   _UpdateRecord  =  async (updateData) =>
   {
    try {
      const userId = Storage.getItem('userId');
      const dto = {'AcctKey' : Number(updateData.acctKey) ,'ProviderId' : Number(updateData.providerId),'CoverKey' : updateData.coverKey, 'LocKey' : updateData.locKey, 'CommunicationTypeId': Number(this.state.communicationType), 'Status' : Number(updateData.status), 'UpdatedBy' : userId};
      const apiKey = `${process.env.REACT_APP_API_KEY}`; 
      this.setState(() => ({ showLoadingOverlay: true }));
      const url = `${process.env.REACT_APP_CAMPAIGN_API}/Provider/InsertOrDelete?secretCode=${apiKey}`;
      const res =  await axios.post(url,dto);    
      if (res?.data) {
        this.setState(() => ({ errorSeverity: 'success', showLoadingOverlay: false,openSnackBar: true, SnackbarMessage: 'Successfully updated the record.'})); 
      }
      else
        this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 

    }
    catch {
      this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 
    }        
 }

  _searchCoverKey = async (coverKey) => {
    try {  
      if (this.state.communicationType !== 0 && coverKey !== "")
      {
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            this.setState(() => ({ showLoadingOverlay: true }));
            const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchProviderCommunicationXrefByCoverKeyAndCommunicationIdAndAcctKey/${this.state.communicationType}/${coverKey}?secretCode=${apiKey}&acctKey=${this.state.acctKey}`;
            const res =  await axios.get(url);
            let title = '';
            if (res?.data) {
              let d = res.data;
              if (d.length > 0)
                 title = (d.length) + " record/s found.";
              else   
                 this.setState(() => ({  SnackbarMessage: 'A doesn\'t exist!',errorSeverity: 'info',openSnackBar: true }));

              this.setState(() => ({ data: d}));
              
            }     
            else   
                 this.setState(() => ({  SnackbarMessage: 'Cover Key doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));

            this.setState(() => ({ showLoadingOverlay: false,recordsFound : title }));
      }
      else
      {
           this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
      }      
    }
    catch {

      this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the provider.'})); 
    }     
  }


    /**
   * Renders the component.
   */
  render() {
    const {
      redirectToLogin,
      data,
      showLoadingOverlay,
      openSnackBar,
      SnackbarMessage,
      recordsFound,
      errorSeverity,
      acctKey,
      coverKey,
      communicationType
    } = this.state;

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }

    return (
        <div>
          <Box p={3} bgcolor="background.paper">
             <Typography >
                Enable/Disable communication type of a cover key and account key
             </Typography>  
          </Box>
          <Divider/>
          <Box p={3} bgcolor="background.paper">
            <ValidatorForm onSubmit={this._handleToggle} onError={errors => console.log(errors)}> 
              <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
                <Grid item xs={12}  sm={2}>
                  <FormControl fullWidth>
                    <InputLabel id="Communication_Type">Comm. Type</InputLabel>
                    <Select
                      labelId="Communication_Type"
                      id="communication_type"
                      value={communicationType}
                      onChange={(e)=>this._handleTypedChange(e.target.value)}              
                    >
                      {/* <MenuItem value={1}>Reminders</MenuItem> */}
                      <MenuItem value={2}>Early Recall</MenuItem>
                      <MenuItem value={3}>Cancellation Retrieval</MenuItem>
                      <MenuItem value={4}>Purchase Retrieval</MenuItem>
                      <MenuItem value={5}>Welcome Email</MenuItem>
                      <MenuItem value={7}>Recall</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> 
                <Grid item xs={12} sm={3} >                             
                  <TextValidator
                      validators={['required', 'isPositive']}
                      errorMessages={['this field is required','Must be numeric and greater than zero']}                
                      fullWidth
                      value={coverKey}
                      onInput={ (e)=>this._handleCoverKeyChange(e.target.value)}  
                      onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this._searchCoverKey(ev.target.value);
                        ev.preventDefault();
                      }
                  }}            
                  label="Search Cover Key"
                  InputProps={{
                      endAdornment: (
                      <InputAdornment>
                          <IconButton type="submit">
                          <SearchIcon />
                          </IconButton>                      
                      </InputAdornment>
                      )
                  }}
                  />   
                </Grid>
                <Grid item xs={12} sm={3} >                             
                  <TextValidator
                      validators={['isPositive']}
                      errorMessages={['Must be numeric and greater than zero']}                
                      fullWidth
                      value={acctKey}
                      onInput={ (e)=>this._handleAcctKeyChange(e.target.value)}  
                      onKeyPress={(ev) => {
                      // if (ev.key === 'Enter') {
                      //    this._searchProvider(ev.target.value);
                      //    ev.preventDefault();
                      // }
                  }}            
                  label="Search Account Key"
                  InputProps={{
                      endAdornment: (
                      <InputAdornment>
                          <IconButton type="submit">
                          <SearchIcon />
                          </IconButton>                      
                      </InputAdornment>
                      )
                  }}
                  />   
                </Grid>
                <Grid item xs={12} sm={3} > 
                    <Button variant="contained" color="primary" type="submit">
                            Search
                    </Button>                 
                  </Grid>                   
              </Grid>   
            </ValidatorForm> 
          </Box>    

          <Box p={1} bgcolor="background.paper">
            <MaterialTable
              height="200"
              columns={columns}
              data={data}
              title={recordsFound}
              options={{
                pageSize: 10,
                pageSizeOptions: [],
                toolbar: true,
                paging: true
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                     this._UpdateRecord(newData);
                      const dataUpdate = [...data];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      this.setState(() => ({ data: dataUpdate}));
                      resolve();
                  }),
              }}            
            />            
          </Box> 
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>      
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
            <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
          </Snackbar>                
        </div>
    ); 
  }
        
}

export default withTranslation()(Provider);