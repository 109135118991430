import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import Storage from '../../storage';
import UserManagementUser from '../../Components/UserManagementForm/UserManagementUser';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Overlay from '../../Components/Overlay/Overlay';

class UserManagement extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        showLoadingOverlay: false,
        redirectToLogin: false
        
    };    
    this.logoUrl = Utils.getLogoUrl();
  };
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
        try {
          this.setState(() => ({ showLoadingOverlay: true }));  
          const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          this.setState(() => ({ showLoadingOverlay: false }));  
        }
        catch {
            this.setState(() => ({
                redirectToLogin: true
            }));
        }

    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }
    /**
   * Renders the component.
   */
  render() {
    const {
      showLoadingOverlay,
      redirectToLogin
    } = this.state;

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }

    return (
        <div>
          <Box p={2} bgcolor="background.paper">
            <Typography>
              Add new users to the system
            </Typography>
          </Box>
          <Divider/>
          <Box p={1} bgcolor="background.paper">
            <UserManagementUser/>
          </Box>
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>   
        </div>
    ); 
  }
        
}

export default UserManagement;