import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    fontFamily:'Noto Sans, sans-serif',
  },
}))(Tooltip);

const columns = [
  { field: 'id', headerName: 'id', width: 30 },
  { field: 'coverKey', headerName: 'CoverKey', width: 115 },
  { field: 'locKey', headerName: 'LocKey', width: 105 },
{ field: 'locName',renderHeader: () => (
  <div style={{ lineHeight:1.2 }} >
  {'Location'}<br></br>{'Name'}
  </div>
), width: 120, renderCell: (cellValues) => {
  return (
    <div
      style={{
        whiteSpace: 'pre-line', 
        lineHeight:1.2       
      }}
    >
      {cellValues.value}
    </div>
  );}
},
  // { field: 'arts', headerName: 'Reminders', width: 125 },
  { field: 'nars', renderHeader: () => (
    <div style={{ lineHeight:1.2 }} >
    {'Early'}<br></br>{'Recall'}
    </div>
), width: 120 },
  { field: 'recall', headerName: 'Recall', width: 95 },
  { field: 'cars',renderHeader: () => (
    <div style={{ lineHeight:1.2 }} >
    {'Cancellation'}<br></br>{'Retrieval'}
    </div>
), width: 130 },  
  { field: 'dips',renderHeader: () => (
    <div style={{ lineHeight:1.2 }} >
    {'Purchase'}<br></br>{'Retrieval'}
    </div>
), width: 130}, 
  { field: 'noShow', headerName: 'No Show', width: 120 },    
  { field: 'welcomeEmail',renderHeader: () => (
    <div style={{ lineHeight:1.2 }} >
    {'Welcome'}<br></br>{'Email'}
    </div>
), width: 130}       

];

const Grid = (props) => {
    const {
        data,
        show
      } = props;
    let  rows = [];

    if (data.length > 0)
       rows = data;

    if (show) {
        // Prevent the user from scrolling the body while the overlay is active.
        document.body.classList.add('no-scroll');
      }
      else {
        document.body.classList.remove('no-scroll');
        return null;
      }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={10} />
    </div>
  );
}

export default Grid;