import MaterialTable from "material-table";
import React, { useState, useEffect, useContext } from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';

import './AppointmentTypes.scss';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useFiltersContext, useRequest, useUIContext } from "../../ContextLib/contextHooks";
import { Severity } from "../../ContextLib/CoreConsumer/Components/SnackbarMessage";
import { arraySort } from '../../ContextLib/Core/coreLib';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
      fontFamily:'Noto Sans, sans-serif',
    },
  }))(Tooltip);

export default function AppointmentTypes() {
    let componentId = "appointmentTypes";
    const ui = useUIContext(componentId);
    const Request = useRequest();

    let lastCoverKey = null;

    const filtersContext = useFiltersContext(componentId, async (newContext) => {
        //On filters changed
        _setCoverKey(newContext.coverKey)

        if (lastCoverKey != newContext.coverKey) {
            await initAppointmentsData_Onboarding(newContext.coverKey);
            lastCoverKey = newContext.coverKey;
        }

        let ready = newContext.coverKey != 0 && newContext.locations.length > 0 && newContext.providers.length > 0;
        loadData(ready);
    });  

    const [_coverKey, _setCoverKey] = useState(filtersContext.Values.coverKey);
    const [appointmentType, setAppointmentType] = useState('');
    const [length, setLength] = useState('');
    const [bookingIncrement, setBookingIncrement] = useState('');
    const [simultaneousAppointment, setSimultaneousAppointment] = useState('');
    
    const [hasData, setHasData] = useState(false);
    const [coverSelected, setCoverSelected] = useState(false);
    const [noRecordMsg, setNoRecordMsg] = useState('');
    const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([
        {jiwsTaskKey: 0, appointmentType: "New Patient Eye Exam"},
        {jiwsTaskKey: 0, appointmentType: "New Patient Contact Lens Exam"},
        {jiwsTaskKey: 0, appointmentType: "Returning Patient Eye Exam"},
        {jiwsTaskKey: 0, appointmentType: "Returning Patient Contact Lens Exam"},
    ]);
    const [appointmentData, setAppointmentData] = useState([]);

    const columns = [
        { field: 'appointmentType', title: 'Appointment Type', width: 195, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.appointmentType} placement="top-start"><span>{dataRow.appointmentType}</span></HtmlTooltip>}},
        { field: 'length', title: 'Length', width: 100, lookup: {5: '5', 15: '15', 30: '30', 45: '45', 60: '60', 90: '90'}},
        { field: 'slotSize', title: 'Booking Increment', width: 175, lookup: {5: '5', 15: '15', 30: '30', 45: '45', 60: '60', 90: '90'}},
        { field: 'cost', title: 'Appointment Cost', width: 180, lookup: {1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8:'8', 9:'9', 10:'10'}},
        { field: 'schedule', title: 'Schedule', width: 225, editable: 'never', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.schedule} placement="top-start"><span>{dataRow.schedule}</span></HtmlTooltip>}},
    ]; 

    const initAppointmentsData_Onboarding = async (coverKey) => {
        ui.ShowOverlay();

        let model = {
            coverKey: coverKey
        };

        let res = await Request.modInitAppointments_FromOnboardingAsync(model);

        if (res.success) {
            let d = res.data;
            if (d.Inserted > 0) {
                let msg = `Onboarding settings were successfully added to Appointments data. Inserted ${d.Inserted} record(s)`;                    
                ui.ShowSnackbar(msg, Severity.success, 10000);
            }            
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const _setLength = (selectedVal) => {
        setLength(selectedVal);
        if (selectedVal !== 0) {
            setBookingIncrement(selectedVal);
        }
    }

    const _setAppointmentType = (val) => {
        setAppointmentType(val)
    }

    //#region page functions
    const _loadAppointmentTypeOptions = async () => {
      ui.ShowOverlay();
        try {              
            let res = await Request.getAppointmentTypeOptions();
            
            if (res.success && res.data?.length > 0) {
              let d = arraySort(res.data, "appointmentType");
              d.forEach((data) => {
                appointmentTypeOptions.push({...data, appointmentType: data.appointmentType ?? ""});
              })
            }     
        }
        catch {
            ui.ShowSnackbar("Error in getting Appointment Type Options", Severity.error);
        } 
        ui.HideOverlay();
    }

    const _handleSubmit = async () =>  {
      ui.ShowOverlay();
        const {coverKey, locKey, acctKey} = filtersContext.Values;
        const params = {'taskKey': 0, 'appointmentType':appointmentType, 'length':length, 
                            'slotSize':bookingIncrement, 'cost':simultaneousAppointment, 'locKey':locKey, 'acctKey':acctKey, 'coverKey': coverKey};
        let res = await Request.saveAppointmentType(params);
        
        if (res.success && res.data) {
            loadData(true);

            let d = res.data;
            let summary = "";
            if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing records";
            if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new records"; 
            
            ui.MessageBox("Appointment type(s) were successfully saved." + summary);
            } else {
            ui.ErrorBox(res.error, "Saving Appointment Type");
            }      
        
      ui.HideOverlay();
    }


    const handleUpdate = async (newData) => {
      ui.ShowOverlay();
        const params = {'taskKey': newData.taskKey, 'appointmentType':newData.appointmentType, 'length':Number(newData.length), 
        'slotSize':Number(newData.slotSize), 'cost':Number(newData.cost), 'locKey':newData.locKey, 'acctKey':newData.acctKey};
        let res = await Request.updateAppointmentType(params);

        if (res.success) {
            loadData(true);
            ui.ShowSnackbar("Appointment type updated successfully.");
        } else {
            ui.ShowSnackbar("Error in updating appointment type.", Severity.error);
        }
      ui.HideOverlay();
    }

    const handlePurge = async (oldData) => { 
        if (appointmentData.length > 1) {
          ui.ShowOverlay();
            const params = {'taskKey': oldData.taskKey};
            let res = await Request.deleteAppointmentType(params);
            
            if (res.success) {
                loadData(true);
                ui.ShowSnackbar("Appointment type deleted successfully.");
            } else {
                ui.ShowSnackbar("Error in deleting appointment type.", Severity.error);
            }
          ui.HideOverlay();
        } else {
            ui.ShowSnackbar(
                "An appointment type has to be defined to set up the Web Scheduler. Create a new one before deleting existing ones.", 
                Severity.warning,
                5000);
        }
 
    }

    const loadData = async (ready) => {
        if (ready) {
          let {coverKey, locations, providers, locKey, acctKey} = filtersContext.Values;
          
          locations  = locations.filter(x => x.coverKey == coverKey); 
          providers = providers.filter(x => x.coverKey == coverKey); 
  
          let arrLocKeys = [], arrAcctKeys = [];
  
          if (locKey !== 0) {
              arrLocKeys.push(locKey.toString());
          } else {
              if (locations.length > 0) {
                  locations.map((loc) => {
                      arrLocKeys.push(loc.locKey.toString())
                  });
              }
   
          }
  
          if (acctKey !== 0) {
              arrAcctKeys.push(acctKey.toString());
          } else {
              if (providers.length > 0) {
                  providers.map((prov) => {
                      arrAcctKeys.push(prov.acctKey.toString())
                  });
              }
          }
  
          ui.ShowOverlay();
          const params = { "acctKeys": arrAcctKeys, "locKeys": arrLocKeys};
          if (arrLocKeys.length > 0 && arrAcctKeys.length > 0) {
              let res = await Request.getAllAppointmentTypes(params);
              if (res.success && res.data?.length > 0) { 
                  let d = arraySort(res.data, "appointmentType");
                  setAppointmentData(d); 
                  setHasData(true);
                  setCoverSelected(true);
              } else {
                setAppointmentData([]); 
              }
          }
  
          if (arrLocKeys.length === 0 || arrAcctKeys.length === 0) {
            setAppointmentData([]); 
            setNoRecordMsg('-- No records found. Please select another Cover --');
            setHasData(false);
            setCoverSelected(false);
          }

          if (appointmentData.length === 0 && arrLocKeys.length > 0 && arrAcctKeys.length > 0) {
            setHasData(true);
            setCoverSelected(true);
          }

          ui.HideOverlay();
  
        }
    };
    
    useEffect(() => {        
        _loadAppointmentTypeOptions();
    }, []);

    //#endregion
    return (
        <div className="reporting-box">
          <Box p={2} bgcolor="background.paper" className="page-header"> 
            <Typography variant="h5">
            Appointment Types
            </Typography>
          </Box>
          <Divider />
          <Box p={2} bgcolor="background.paper" className="info">
            <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
              <Grid item xs={12} sm={6}>
                <h3>  
                The following information is Patient Appointments data:
                </h3>
              </Grid>
            </Grid>        
          </Box>        
          <Divider />

            <div className="page-body-outline">
                { coverSelected ? 
                <>
                    <Box p={2}>
                        <ValidatorForm onSubmit={_handleSubmit} onError={errors => console.log(errors)}>
                            <Grid container justify="flex-start" alignItems="center" spacing={1} id="grid-2">
                                <Grid item style={{ width: 250 }}>
                                    <Autocomplete
                                        freeSolo
                                        id="autoAppointmentType"
                                        disableClearable
                                        options={appointmentTypeOptions.map((option) => option.appointmentType)}
                                        onInputChange={(event, newValue) => {
                                            _setAppointmentType(newValue);
                                        }}
                                        renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            placeholder="Appointment Type"
                                            variant="outlined"
                                            validators={['required']}
                                            value={appointmentType}
                                            onInput={(e)=>_setAppointmentType(e.target.value)} 
                                            errorMessages={['Appointment Type is required']}  
                                            InputProps={{ ...params.InputProps, type: 'search'}}
                                            inputProps={{ ...params.inputProps, maxLength: 50 }}
                                        />
                                        )}
                                    />
                                </Grid>

                                <Grid item>
                                    <SelectValidator 
                                    id="ddAppointmentLength"
                                    value={length}
                                    onChange={ e=>_setLength(e.target.value)}        
                                    variant="outlined"
                                    validators={['required']}
                                    errorMessages={['Length is required']}  
                                    inputProps={{
                                        displayEmpty: true
                                    }}  
                                    >
                                    <MenuItem value='' disabled>Length (Mins)</MenuItem>
                                    <MenuItem value={5}>5 Minutes</MenuItem>
                                    <MenuItem value={15}>15 Minutes</MenuItem>
                                    <MenuItem value={30}>30 Minutes</MenuItem>
                                    <MenuItem value={45}>45 Minutes</MenuItem>
                                    <MenuItem value={60}>60 Minutes</MenuItem>
                                    <MenuItem value={90}>90 Minutes</MenuItem>
                                    </SelectValidator>
                                </Grid>

                                <Grid item>
                                    <SelectValidator
                                    id="ddBookingIncrement"
                                    value={bookingIncrement}
                                    onChange={ e=>setBookingIncrement(e.target.value)}        
                                    variant="outlined"  
                                    validators={['required']}
                                    errorMessages={['Booking Increment is required']}  
                                    inputProps={{
                                        displayEmpty: true
                                    }}
                                    >
                                    <MenuItem value='' disabled>Booking Increment (Mins)</MenuItem>
                                    <MenuItem value={5}>5 Minutes</MenuItem>
                                    <MenuItem value={15}>15 Minutes</MenuItem>
                                    <MenuItem value={30}>30 Minutes</MenuItem>
                                    <MenuItem value={45}>45 Minutes</MenuItem>
                                    <MenuItem value={60}>60 Minutes</MenuItem>
                                    <MenuItem value={90}>90 Minutes</MenuItem>
                                    </SelectValidator>
                                </Grid>

                            
                                <Grid item id="grid-2-txt-swimlanes" >
                                    <SelectValidator
                                    id="ddSwimLanes"
                                    value={simultaneousAppointment}
                                    onChange={ e=>setSimultaneousAppointment(e.target.value)}        
                                    variant="outlined"  
                                    validators={['required']}
                                    errorMessages={['Appointment Cost is required']}  
                                    inputProps={{
                                        displayEmpty: true
                                    }}
                                    >
                                    <MenuItem value='' disabled>Appointment Cost</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    </SelectValidator>
                                </Grid>
                

                                <Grid item>
                                    <Button variant="contained" color="primary" type="submit">
                                    ADD
                                    </Button> 
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Box>
                    <br />
                    <Box>                      
                    {appointmentData && hasData ? 
                                    <MaterialTable
                                        height="200"
                                        columns={columns}
                                        data={appointmentData}
                                        options={{
                                            pageSize: 10,
                                            pageSizeOptions: [10, 15, 20],
                                            toolbar: false,
                                            paging: true,
                                            draggable: false,
                                            sorting: false,
                                            tableLayout: 'fixed',
                                        }}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    handleUpdate(newData, oldData);
                                                    resolve();
                                                }),
                                            onRowDelete: oldData =>
                                                handlePurge(oldData)
                                            }}          
                                        /> 
                    : 
                        null
                    }
                    </Box>
                </>
                : 
                <Box>
                    <Typography align="center" color="primary">{noRecordMsg}</Typography>
                    </Box>
                }
                
            </div>  
        </div>
    )
}
  